import React, { Component } from 'react'
import { Mutation } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'

import { RESET_PASSWORD } from '@balance/lib/api/user'

import ResetPassword from '../../components/User/ResetPassword'

class ResetPasswordContainer extends Component {

  state = {
    error: '',
  }

  render () {
    const resetToken = get(this.props, 'location.search').replace('?reset_password_token=', '')

    // console.log('resetToken', resetToken, this.props)

    return (
      <Mutation mutation={RESET_PASSWORD}>
        {(reset, { data, loading, error }, ...rest) => {
          // console.log('signin container props', data)

          const handleReset = async (attributes) => {
            // console.log('resetPassword start', attributes)
            const result = await reset({ variables: { resetToken, ...attributes } })
            // console.log('resetPassword result', result)
            return result
          }

          const onSuccess = () => {
            this.props.history.replace('/')
          }

          return (
            <ResetPassword onSubmit={handleReset} onSuccess={onSuccess}/>
          )
        }}
      </Mutation>
    )
  }
}

export default withRouter(ResetPasswordContainer)
