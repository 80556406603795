"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.DELETE_FOOD = exports.UPDATE_FOOD = exports.CREATE_FOOD = exports.GET_FOOD = exports.GET_FOODS = exports.SEARCH_FOOD_NAMES = void 0;

var _graphqlTag = _interopRequireDefault(require("graphql-tag"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n    mutation deleteFood($id: ID!) {\n        data: deleteFood(id: $id) {\n            id\n        }\n    }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n    mutation updateFood($id: ID!, $name: String!, $categoryId: ID, $category: FoodCategoryInput,\n        $carbsG: Decimal, $fatG: Decimal, $kcalG: Decimal, $proteinG: Decimal,\n        $fiberG: Decimal, $sugarG: Decimal, $sodiumG: Decimal,\n        $brand: String, $apiId: String, $apiRef: String, $apiCat: String,\n        $seasoning: Boolean, $fixed: Boolean,\n        $fixedQty: Decimal, $fixedUnit: String,\n        $pkgG: Decimal, $pkgOz: Decimal, $pkgQty: Int, $pkgSize: String, $pkgUnit: String\n    ) {\n        data: updateFood(id: $id, name: $name, categoryId: $categoryId, category: $category,\n            carbsG: $carbsG, fatG: $fatG, kcalG: $kcalG, proteinG: $proteinG,\n            fiberG: $fiberG, sugarG: $sugarG, sodiumG: $sodiumG,\n            brand: $brand, apiId: $apiId, apiRef: $apiRef, apiCat: $apiCat,\n            seasoning: $seasoning, fixed: $fixed,\n            fixedQty: $fixedQty, fixedUnit: $fixedUnit,\n            pkgG: $pkgG, pkgOz: $pkgOz, pkgQty: $pkgQty, pkgSize: $pkgSize, pkgUnit: $pkgUnit\n        ) {\n            id\n            name\n            category { id name }\n            type\n            seasoning\n            kcalG\n            proteinG\n            fatG\n            carbsG\n            fiberG\n            sugarG\n            sodiumG\n            brand\n            fixed\n            fixedQty\n            fixedUnit\n            apiId\n            apiCat\n            apiRef\n            __typename\n        }\n    }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n    mutation createFood($name: String!, $categoryId: ID, $category: FoodCategoryInput,\n        $carbsG: Decimal, $fatG: Decimal, $kcalG: Decimal, $proteinG: Decimal,\n        $fiberG: Decimal, $sugarG: Decimal, $sodiumG: Decimal,\n        $brand: String, $apiId: String, $apiRef: String, $apiCat: String,\n        $addSnack: Boolean, $seasoning: Boolean, $fixed: Boolean,\n        $fixedQty: Decimal, $fixedUnit: String,\n        $snackQty: Decimal, $snackUnit: String, $snackPublic: Boolean,\n        $pkgG: Decimal, $pkgOz: Decimal, $pkgQty: Int, $pkgSize: String, $pkgUnit: String\n    ) {\n        data: createFood(name: $name, categoryId: $categoryId, category: $category,\n            carbsG: $carbsG, fatG: $fatG, kcalG: $kcalG, proteinG: $proteinG,\n            fiberG: $fiberG, sugarG: $sugarG, sodiumG: $sodiumG,\n            brand: $brand, apiId: $apiId, apiRef: $apiRef, apiCat: $apiCat,\n            addSnack: $addSnack, seasoning: $seasoning, fixed: $fixed,\n            fixedQty: $fixedQty, fixedUnit: $fixedUnit,\n            snackQty: $snackQty, snackUnit: $snackUnit, snackPublic: $snackPublic,\n            pkgG: $pkgG, pkgOz: $pkgOz, pkgQty: $pkgQty, pkgSize: $pkgSize, pkgUnit: $pkgUnit\n        ) {\n            id\n            name\n            category { id name }\n            type\n            seasoning\n            kcalG\n            proteinG\n            fatG\n            carbsG\n            fiberG\n            sugarG\n            sodiumG\n            brand\n            fixed\n            fixedQty\n            fixedUnit\n            apiId\n            apiCat\n            apiRef\n            __typename\n        }\n    }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    query Food($id: ID!) {\n        data: Food(id: $id) {\n            id\n            name\n            categoryId\n            category { id name }\n            type\n            seasoning\n            kcalG\n            proteinG\n            fatG\n            carbsG\n            sodiumG\n            fiberG\n            sugarG\n            brand\n            fixed\n            fixedQty\n            fixedUnit\n            apiId\n            apiCat\n            apiRef\n            isPackaged\n            pkgG\n            pkgQty\n            pkgSize\n            pkgUnit\n            dietLabels {\n                name\n            }\n        }\n    }"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    query allFoods($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: FoodFilter) {\n        items: allFoods(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) {\n            id\n            name\n            brand\n            categoryId\n            category { id name }\n            kcalG\n            proteinG\n            fatG\n            carbsG\n            sodiumG\n            fiberG\n            sugarG\n            brand\n            apiId\n            apiCat\n            apiRef\n            fixed\n            fixedQty\n            fixedUnit\n            __typename\n        }\n        total: _allFoodsMeta(page: $page, perPage: $perPage, filter: $filter) {\n            count\n            __typename\n        }\n    }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n    query allFoods($filter: FoodFilter) {\n        items: allFoods(page: 0, perPage: -1, sortField: \"name\", filter: $filter) {\n            id\n            name\n        }\n    }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

var SEARCH_FOOD_NAMES = (0, _graphqlTag.default)(_templateObject());
exports.SEARCH_FOOD_NAMES = SEARCH_FOOD_NAMES;
var GET_FOODS = (0, _graphqlTag.default)(_templateObject2());
exports.GET_FOODS = GET_FOODS;
var GET_FOOD = (0, _graphqlTag.default)(_templateObject3());
exports.GET_FOOD = GET_FOOD;
var CREATE_FOOD = (0, _graphqlTag.default)(_templateObject4());
exports.CREATE_FOOD = CREATE_FOOD;
var UPDATE_FOOD = (0, _graphqlTag.default)(_templateObject5());
exports.UPDATE_FOOD = UPDATE_FOOD;
var DELETE_FOOD = (0, _graphqlTag.default)(_templateObject6());
exports.DELETE_FOOD = DELETE_FOOD;
var _default = {
  GET_LIST: GET_FOODS,
  GET_ONE: GET_FOOD,
  CREATE: CREATE_FOOD,
  UPDATE: UPDATE_FOOD,
  DELETE: DELETE_FOOD
};
exports.default = _default;