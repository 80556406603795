import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'

import { CurrentUserConsumer } from '@balance/lib/contexts'
import { isComplete } from '@balance/lib/api/profile'
import { GET_MEAL_PREP } from '@balance/lib/api/meal-plan'

import { MealPrep } from '../../components/Meals'
import { ProfileNotComplete } from '../../components/Profile'

class MealPrepContainer extends Component {
  state = {
    error: '',
  }

  render () {
    let week = get(this.props, 'location.search', '').replace('?week=', '')
    // console.log('week', week, this.props)
    if (!['last', 'this', 'next'].includes(week)) { week = '' }

    return (
      <CurrentUserConsumer>
        {({ state: user }) => {
          // console.log('cu', state)

          const profile = get(user, 'profile', {})
          if (!isComplete(profile)) {
            return <ProfileNotComplete profile={profile} />
          }

          return (
            <Query query={GET_MEAL_PREP} variables={{ week }}>
              {({ data, loading, props }) => {
                // console.log('meal dashboard container props', data, props)

                let plan = get(data, 'mealPrep', {})
                // console.log('meal prep container: meal plan', user, plan)

                return (
                  <MealPrep plan={plan} loading={loading} user={user} week={week} />
                )
              }}
            </Query>
          )
        }}
      </CurrentUserConsumer>
    )
  }
}

export default withRouter(MealPrepContainer)
