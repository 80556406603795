// @flow
import React from 'react'
import classnames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'

type Props = {
  label: String,
}

const classes = theme => ({
    chip: {
      marginRight: theme.spacing.unit / 2,
      [theme.breakpoints.down('sm')]: {
        fontSize: 11,
        height: 24,
      },
    },
    spaced: {
      marginRight: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
    },
    fixed: {
      minWidth: 75,

      [theme.breakpoints.up('md')]: {
        minWidth: 100,
      },
    },
    large: {
      minWidth: 125,
      minHeight: 32,

      [theme.breakpoints.up('md')]: {
        minWidth: 150,
      },
    },
  }
)

function TypeChip ({classes, className, fixed, spaced, large, label}: Props) {
  const chipClasses = classnames(
    classes.chip,
    fixed && classes.fixed,
    spaced && classes.spaced,
    large && classes.large,
    className)
  if (label === 'fat free') { return null }
  return (
    <Chip label={label} className={chipClasses} />
  )
}

TypeChip.defaultProps = {
  label: '',
}

export default withStyles(classes)(TypeChip)
