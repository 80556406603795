// @flow
import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core'
import PageTitle from '../PageTitle/PageTitle'
import styles from '../../styles/global'

type Props = {
  user?: Object,
}
type State = {}

class AccessDenied extends PureComponent<Props, State> {
  static defaultProps = {}

  render () {
    const { classes, status } = this.props

    let again = false
    let statusPhrase = ''
    if (status) {
      if (['inactive'].includes(status)) {
        again = true
        statusPhrase = `is currently ${status}`
      } else if (['lapsed'].includes(status)) {
        again = true
        statusPhrase = `has ${status}`
      } else if (['cancelled'].includes(status)) {
        again = true
        statusPhrase = `has been ${status}`
      }
    }

    return (
      <div className={classes.container}>
        <PageTitle noIcon title="Access Denied" />

        <p>
          {statusPhrase ?
            `Your account ${statusPhrase}, and thus you`
            : 'You'} do not have access to this feature. <Link to="/profile/payment">Upgrade now</Link> to
          start using our platform{again ? ' again' : ''}.
        </p>

      </div>
    )
  }
}

export default withStyles(styles)(AccessDenied)
