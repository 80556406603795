// @flow
import React, { PureComponent } from 'react'
import { withStyles, Typography } from '@material-ui/core'
import get from 'lodash/get'
import memoize from 'memoize-one'
import classnames from 'classnames'

import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import Collapse from '@material-ui/core/Collapse'
import TextField from '@material-ui/core/TextField'

import { colors } from '@balance/lib/styles'
import { PageTitle } from '../PageTitle'
import baseStyles from '../../styles/global'
import printStyles from '../../styles/print'

import { ButtonLink } from '../Button'

import { Grocery, Food, Restaurant, List as ListIcon } from '../../styles/icons'
import Button from '../Button/Button'
import WeekToggle from './WeekToggle'
import FullPageSpinner from '../Global/FullPageSpinner'

type MealPrepType = {
  meals: Array<Object>,
}
type Props = {
  plan: MealPrepType,
  user: Object,
  week?: ?string,
}
type State = {
  instrShowingFor: string,
}

const styles = (theme) => {
  return {
    ...baseStyles(theme),
    day: {
      padding: theme.spacing.unit,
      margin: theme.spacing.unit,
    },
    table: {
      width: '100%',
      // minWidth: 500,
    },
    heading: {
      color: colors.darkGreen,
    },
    thead: {
      color: '#3d3d3d',
      fontSize: 10,
      fontWeight: '500',
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
    },
    recipeIntro: {
      fontStyle: 'italic',
    },
    icon: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flexWrap: 'nowrap',
      marginTop: 11,
      color: colors.darkGreen,
    },
    ingrItem: {
      paddingBottom: 0,
    },
    portion: {
      textAlign: 'center',
      fontSize: 24,
      '& span': {
        display: 'block',
        fontSize: 10,
      },
    },
    buttonWrap: {
      width: '100%',
      display: 'flex',

      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-end',
      },
    },
    textarea: {
      width: '100%',
      marginTop: 0,
    },
    recipeItem: {
      // '& span': {
      fontSize: '1rem',
      // },
    },
    ...printStyles(theme),
  }
}

class MealPrep extends PureComponent<Props, State> {
  static defaultProps = {
    plan: {
      days: [],
      meals: [],
    },
    user: { profile: {} },
    week: '',
  }

  state = {
    instrShowingFor: '',
  }

  recipesByType = memoize((groupedRecipeList) => {
    // console.log('groupedRecipeList', groupedRecipeList)
    const groups = { breakfast: [], meal: [], snack: [] }
    groupedRecipeList.forEach(r => groups[r.type].push(r))
    // console.log('groups', groups)
    return groups
  })

  toggleInstructions = (recipeId) => {
    this.setState(prevState => {
      const show = prevState.instrShowingFor === recipeId ? '' : recipeId
      return { instrShowingFor: show }
    })
  }

  renderInstructions = (recipe) => {
    const { classes } = this.props
    const { instrShowingFor } = this.state
    const instructions = get(recipe, 'instructions')
    if (!instructions) { return null }
    const show = instrShowingFor === recipe.id
    // console.log('instr', show, instrShowingFor, recipe.id, instructions)
    return (
      <div style={{ width: '100%' }}>
        <div className={classes.buttonWrap}>
          <Button variant="text" disableRipple size="small"
                  onClick={() => this.toggleInstructions(recipe.id)}>
            <ListIcon />
            Instructions
          </Button>
        </div>
        <Collapse in={show} timeout="auto" unmountOnExit>
          <TextField key={`recipe-${recipe.id}-instr-page`} disabled label={false} variant="outlined" margin="normal"
                     multiline className={classes.textarea}
                     defaultValue={instructions} />
        </Collapse>
        <div className={classes.printOnly}>
          <TextField key={`recipe-${recipe.id}-instr-print`} disabled label={false} variant="outlined" margin="normal"
                     multiline className={classes.textarea}
                     defaultValue={instructions} />
        </div>
      </div>
    )
  }

  formatQty = (ingr) => {
    if (ingr.fixed) {
      return `${ingr.qty} whole`
    }
    return `${ingr.qtyOz} oz`
  }

  renderRecipeGroup = (group) => {
    const { classes } = this.props
    const cnt = group.length
    return (
      <List disablePadding>
        {group.map((recipe, i) => {
          return (
            <ListItem dense divider={i < cnt - 1} alignItems="flex-start"
                      disableGutters key={i}>
              <ListItemIcon className={classes.icon}>
                <React.Fragment>
                  <Restaurant />
                  <span className={classes.portion}>
                      {recipe.count}
                    <span>portion{recipe.count > 1 && 's'}</span>
                    </span>
                </React.Fragment>
              </ListItemIcon>
              <ListItemText>
                {this.renderRecipe(recipe)}
              </ListItemText>
            </ListItem>
          )
        })}
      </List>
    )
  }

  renderRecipe = (recipe) => {
    const { classes } = this.props
    // console.log('recipe', recipe)

    const colSize = Boolean(recipe.instructions) ? 6 : 12
    return (
      <div key={recipe.id}>
        <Grid container>
          <Grid item xs={12} md={colSize}>
            <List disablePadding>
              {recipe.ingredients.map((ingr, i) => {
                // console.log('ingredient', ingr)
                return (
                  <ListItem dense disableGutters key={`${recipe.id}-${ingr.id}-${i}`} className={classes.ingrItem}>
                    <ListItemText primaryTypographyProps={{ className: classes.recipeItem }}>
                      {this.formatQty(ingr)} {ingr.name}
                    </ListItemText>
                  </ListItem>
                )
              })}
            </List>
          </Grid>
          <Grid item xs={12} md={colSize}>
            {this.renderInstructions(recipe)}
          </Grid>
        </Grid>
      </div>
    )
  }

  renderAside = () => {
    const { week } = this.props
    let weekQS = week ? `?week=${week}` : ''
    let aside = []
    aside.push(
      <ButtonLink key="0" size="small" to={`/meal-plan${weekQS}`} secondary variant="text">
        <Food />
        Meal Plan
      </ButtonLink>,
    )
    aside.push(
      <ButtonLink key="1" size="small" to={`/grocery-list${weekQS}`} secondary variant="text">
        <Grocery />
        Grocery List
      </ButtonLink>,
    )
    return aside
  }

  render () {
    // console.log('MealPrep.render', this.props, this.state)
    const { classes, plan, user, loading, week } = this.props
    if (!user || !user.profile) {
      return null
    }

    const recipeGroups = this.recipesByType(get(plan, 'groupedRecipes', []))
    // console.log('recipeGroups', recipeGroups)

    const b = recipeGroups.breakfast
    const m = recipeGroups.meal
    const s = recipeGroups.snack
    return (
      <div className={classes.container}>
        <div className={classnames(classes.printOneLine, classes.printTitle)}>
          <PageTitle title="Meal Prep" noIcon showHelp aside={this.renderAside()} condensed />
          <WeekToggle week={week} weekOfShort={plan.weekOfShort} />
        </div>

        <Typography variant="h5" className={classes.heading}>
          Breakfast{b.length > 1 && 's'}
        </Typography>
        {this.renderRecipeGroup(b)}

        <Typography variant="h5" className={classes.heading}>
          Meal{m.length > 1 && 's'}
        </Typography>
        {this.renderRecipeGroup(m)}

        <Typography variant="h5" className={classes.heading}>
          Snack{s.length > 1 && 's'}
        </Typography>
        {this.renderRecipeGroup(s)}

        <FullPageSpinner open={loading} />
      </div>
    )
  }
}

export default withStyles(styles)(MealPrep)
