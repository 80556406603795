// @flow

import React, { Component } from 'react'
import get from 'lodash/get'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

// import { API_URL, IS_DEV } from '@balance/lib/config/constants'

import { Button } from '../Button'

import styles from '../../styles/forms'
import { colors } from '../../styles'

type Props = {
  resetPassword: Function,
  error?: ?Object,
}

type State = {
  email: String,
}

export class RequestPasswordReset extends Component<Props, State> {
  state = {
    email: '',
    error: this.props.error || '',
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.currentTarget.value.trim(),
      error: '',
    })
  }

  reset = async (event) => {
    event.stopPropagation()
    event.preventDefault()
    const result = await this.props.resetPassword(this.state.email)

    this.setState({
      error: get(result, 'resetPasswordRequest.message'),
    })
  }

  render () {
    // console.log('RequestPasswordReset.render', API_URL, IS_DEV)
    const {classes} = this.props
    const {error} = this.state

    return (
      <div className={classes.wrapper}>
        <form noValidate autoComplete="off" onSubmit={this.reset}>
          <div className='flex flex-column'>

            <TextField
              id="email"
              label="Email"
              type="email"
              className={classes.textField}
              value={this.state.email}
              onChange={this.handleChange('email')}
              margin="normal"
              fullWidth
            />

          </div>
          <div>
            <p style={{color: colors.danger}}>{error}</p>
          </div>
          <div className="">
            <Button variant="contained" color="primary" className={classes.button} type="submit">
              Send reset instructions
            </Button>

          </div>

        </form>
      </div>
    )
  }
}

export default withStyles(styles)(RequestPasswordReset)
