// @flow
import React, { PureComponent } from 'react'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { Typography, withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

import { colors } from '@balance/lib/styles'
import { API_URL, getApiUrl } from '@balance/lib/config/constants'

type Props = {}
type State = {}

const styles = theme => ({
  footer: {
    textTransform: 'lowercase',
    marginTop: 0,
    padding: [[theme.spacing.unit, theme.spacing.unit * 2]],
    backgroundColor: colors.darkGray,
    color: 'white',

    '& a': {
      color: 'white',
    },

    [theme.breakpoints.up('xs')]: {
      marginTop: theme.spacing.unit * 12,
      // marginTop: 0,
    },

    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
    },
  },
  appFooter: {
    backgroundColor: colors.lightGreen,
    color: '#333333',

    '& a': {
      color: '#333333',
    },
  },
  info: {
    textAlign: 'center',

    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
    },
  },
  links: {
    margin: [[theme.spacing.unit * 2, 0, 0]],
    padding: 0,
    display: 'flex',
    listStyle: 'none',
    textAlign: 'center',
    justifyContent: 'center',

    '& li': {
      display: 'inline',
      margin: [[0, 10]],
    },

    '& a': {
      textDecoration: 'none',
    },

    [theme.breakpoints.up('sm')]: {
      margin: 0,
      justifyContent: 'flex-end',
    },

    '@media print': {
      display: 'none',
    },
  },
})

class Footer extends PureComponent<Props, State> {
  static defaultProps = {}

  render () {
    const { classes } = this.props
    return (
      <Typography component="footer" className={classnames(classes.footer, window.cordova && classes.appFooter)} id="app__footer">
        <Grid container>
          <Grid item xs={12} sm={6} className={classes.info}>
            &copy; 2020 Balance on Demand, LLC. All Rights Reserved.
          </Grid>
          <Grid item xs={12} sm={6}>
            <ul className={classes.links}>
              <li><Link to="/pages/terms-of-use">Terms of Use</Link></li>
              <li><Link to="/pages/privacy-policy">Privacy Policy</Link></li>
              <li><Link to="/pages/disclaimer">Disclaimer</Link></li>
            </ul>
          </Grid>
        </Grid>
        {true && window.BOD_ENV && window.BOD_ENV === 'development' && (
          <Grid container><Grid item xs={12} align='center'>
            <p>
              {window.BOD_ENV} | {window.cordova ? <span>ENV: cordova</span> : <span>ENV: web</span>}
              <br />
              {API_URL} / {getApiUrl()}
            </p>
          </Grid></Grid>
        )}
        <br/>
      </Typography>
    )
  }
}

export default withStyles(styles)(Footer)
