import React, { Component } from 'react'
import { ApolloConsumer } from 'react-apollo'
import { withRouter } from 'react-router-dom'
// import get from 'lodash/get'

import { RESET_PASSWORD_REQUEST } from '@balance/lib/api/user'
import { SignInHelp } from '../../components/User'

import { HideErrorBoundary } from '../../components/Error'

class SignInHelpContainer extends Component {
  render () {
    // const action = get(this.props, 'match.params.action')
    // console.log('signin help.render', action, this.props)
    return (
      <HideErrorBoundary>
        <ApolloConsumer>
          {(client, ...rest) => {
            // console.log('SignInHelpContainer.props', client, rest)

            const reset = async (email) => {
              // console.log('resetting password?', email)

              const {data} = await client.mutate({
                mutation: RESET_PASSWORD_REQUEST,
                variables: {email},
              })
              // console.log('reset data', data)
              return data
            }

            return (
              <SignInHelp resetPassword={reset} />
            )
          }}
        </ApolloConsumer>
      </HideErrorBoundary>
    )
  }
}

export default withRouter(SignInHelpContainer)
