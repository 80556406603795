"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCookie = getCookie;
exports.sortByName = exports.sortByKey = exports.asFloat = exports.fmtPct = exports.asPct = exports.trimAll = exports.formatDate = exports.studentsToLinks = exports.merge = void 0;

var _data = require("../data");

var R = _interopRequireWildcard(require("ramda"));

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {};

          if (desc.get || desc.set) {
            Object.defineProperty(newObj, key, desc);
          } else {
            newObj[key] = obj[key];
          }
        }
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
} // const R = require('ramda')


function getCookie(name) {
  if (window.document && window.document.cookie) {
    var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
  }

  return null;
}

var defaults = function defaults(k, l, r) {
  // console.log({k, l, r})
  return R.defaultTo('', r);
};

var merge = function merge() {
  for (var _len = arguments.length, objects = new Array(_len), _key = 0; _key < _len; _key++) {
    objects[_key] = arguments[_key];
  } // console.log('merge', objects)


  return R.mergeDeepWithKey.apply(R, [defaults].concat(objects));
};

exports.merge = merge;

var studentsToLinks = function studentsToLinks(students) {
  return students.map(function (_ref) {
    var id = _ref.id,
        fullName = _ref.fullName,
        firstName = _ref.firstName,
        lastName = _ref.lastName,
        s = _objectWithoutProperties(_ref, ["id", "fullName", "firstName", "lastName"]);

    return {
      studentId: id,
      isLinked: false,
      fullName: fullName,
      firstName: firstName,
      lastName: lastName
    };
  });
};

exports.studentsToLinks = studentsToLinks;

var _formatTheDate = function _formatTheDate(date) {
  var withYear = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var shortMonth = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

  if (!date) {
    return '';
  }

  var day = date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear();
  var month = _data.MONTH_NAMES[monthIndex];

  if (shortMonth) {
    month = month.substr(0, 3);
  }

  return month + ' ' + day + (withYear ? ', ' + year : '');
};

var formatDate = function formatDate(date) {
  var withYear = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var shortMonth = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

  if (typeof date === 'string') {
    var d = new Date(date);
    return _formatTheDate(d, withYear, shortMonth);
  }

  return _formatTheDate(date, withYear, shortMonth);
};

exports.formatDate = formatDate;

var isPrimitive = function isPrimitive(value) {
  return value !== Object(value);
};

var trimAll = function trimAll(object) {
  if (!object || Boolean(object) === object) {
    return object;
  }

  var updated = {};

  for (var k in object) {
    if (object.hasOwnProperty(k)) {
      var v = object[k];
      updated[k] = v;

      if (v) {
        if (isPrimitive(v)) {
          if (v.trim) {
            updated[k] = v.trim();
          } else {
            updated[k] = v;
          }
        } else if (v.trim) {
          updated[k] = v.trim();
        } else if (v.map) {
          updated[k] = v.map(function (el) {
            return el && el.trim ? el.trim() : el;
          });
        } else {
          updated[k] = trimAll(v);
        } // console.log('trimAll', { v, v2: updated[k] })

      }
    }
  }

  return updated;
};

exports.trimAll = trimAll;

var asPct = function asPct(value) {
  var scale = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  return (Math.round(value * 100 * (100 ^ scale)) / (100 ^ scale)).toFixed(scale);
};

exports.asPct = asPct;

var fmtPct = function fmtPct(num, den) {
  var scale = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
  var value = num >= 0 && den > 0 ? num / den : 0; // console.log('fmtPct', num, den, scale, value)

  return "".concat(asPct(value, scale), "%");
};

exports.fmtPct = fmtPct;

var asFloat = function asFloat(value) {
  console.log('asFloat', value, parseFloat(value));

  if (value) {
    var floated = parseFloat(value);
    return isNaN(floated) ? '' : value.replace(/[^\d\.]/g, '');
  }

  return '';
};

exports.asFloat = asFloat;

var sortByKey = function sortByKey(key) {
  return function (a, b) {
    var al = a[key].toLowerCase();
    var bl = b[key].toLowerCase();
    var comparison = 0;

    if (al > bl) {
      comparison = 1;
    } else if (al < bl) {
      comparison = -1;
    }

    return comparison;
  };
};

exports.sortByKey = sortByKey;
var sortByName = sortByKey('name'); // (a, b) => {
//   sortByKey
//   const al = a.name.toLowerCase()
//   const bl = b.name.toLowerCase()
//   let comparison = 0
//   if (al > bl) {
//     comparison = 1
//   } else if (al < bl) {
//     comparison = -1
//   }
//   return comparison
// }

exports.sortByName = sortByName;