// @flow
import React, { PureComponent } from 'react'
import get from 'lodash/get'
import { Redirect } from 'react-router-dom'
import { withStyles } from '@material-ui/core'

import { formatDate } from '@balance/lib/utils'

import { DisplayField, FormGroup } from '../Form'
import PageTitle from '../PageTitle/PageTitle'
import styles from '../../styles/global'
import { Button, ButtonLink } from '../Button'

import { Payment, Person, TouchApp } from '../../styles/icons'
import ActionBar from '../Form/ActionBar'
import Alert from '../Modal/Alert'
import { isAndroid, isIos, MONTHLY_FEE_PRODUCT_ID, nativePaymentLabel } from '../../lib/inAppPurchasing'

type Props = {
  user: Object,
  payment: Object,
  cancelAccount: Function,
}
type State = {
  ready: boolean,
  success: boolean,
  cancelAlertOpen: boolean,
  cancelled: boolean,
  token: string,
  validUntil: Date,
  apiUrl: string,
  userIp: string,
  error: string,
  firstName: string,
  lastName: string,
  cc: {
    num: string,
    mmyy: string,
    cvc: string,
    zip: string,
  },
}

class PaymentDetail extends PureComponent<Props, State> {
  static defaultProps = {
    user: {},
  }
  state = {
    ready: false,
    success: false,
    cancelAlertOpen: false,
    cancelled: false,
  }

  // componentDidMount = async (): void => {
  //   console.log('PaymentDetail.cDM', this.props)
  // }

  formatLast4 = (payment) => {
    if (!payment) { return '' }

    let masked4 = ''
    if (payment.ccDesc === 'AMEX') {
      masked4 = '**** ****** ' + payment.ccLast4
    } else {
      masked4 = '**** **** **** ' + payment.ccLast4
    }
    return masked4
  }

  formatExpDate = (payment) => {
    if (!payment || !payment.ccExpDate) { return '' }

    const d = payment.ccExpDate
    return d.slice(0, 2) + '/' + d.slice(-2)
  }

  handleClose = () => {
    this.setState({ cancelAlertOpen: false })
  }

  handleCancel = () => {
    // console.log('handelCancel')
    this.setState({ cancelAlertOpen: true })
  }

  handleCancelConfirmed = async () => {
    const result = await this.props.cancelAccount()
    this.handleClose()
    if (result) {
      this.setState({ cancelled: true })
    }
  }

  // loadCheckoutJs = () => {
  //   console.log('loadCheckoutJs', this.state)
  //   const jsFile = `${this.state.apiUrl}/hosted-payments/Checkout.js`
  //   const script = document.createElement('script')
  //
  //   script.src = jsFile
  //   script.async = true
  //
  //   document.body.appendChild(script)
  // }

  renderIapDetails = (payment) => {
    const androidSku = MONTHLY_FEE_PRODUCT_ID
    const androidPkg = 'com.balanceondemand.app'
    return (
      <FormGroup icon={<TouchApp />}>
        <DisplayField label="App Store" value={nativePaymentLabel(payment.iapStore)} />

        {isIos(payment.iapStore) && (
          <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/account/subscriptions">Manage via
            iTunes</a>
        )}
        {isAndroid(payment.iapStore) && (
          <a target="_blank" rel="noopener noreferrer"
             href={`https://play.google.com/store/account/subscriptions?sku=${androidSku}&package=${androidPkg}`}>Manage
            via
            Play Store</a>
        )}

        <ButtonLink to={`/profile/payment/edit`}>Edit</ButtonLink>
      </FormGroup>
    )
  }

  renderCcDetails = (payment, status) => {
    const { classes } = this.props
    const last4 = this.formatLast4(payment)
    const mmyy = this.formatExpDate(payment)
    return (
      <FormGroup icon={<Payment />}>
        <DisplayField label="Credit Card Number (Last 4)" value={last4} />
        <DisplayField label="Credit Card MM/YY" value={mmyy} />

        <p className={classes.error}>{this.state.error}</p>

        {status === 'active' && (
          <p>
            Note: to make changes to your credit card information,
            please <a href="mailto:help@balanceondemand.com?subject=Payment Change">email us</a>.
          </p>
        )}
      </FormGroup>
    )
  }

  render () {
    const { classes, user, payment } = this.props

    console.log('PaymentDetail.render', this.props, this.state)

    const status = user.status
    const pendingCancellation = status === 'pending_cancellation'
    const priLabel = status === 'trial' ? 'Upgrade!' :
      status === 'cancelled' || status === 'inactive' || pendingCancellation ? 'Reactivate!' :
        status === 'active' ? 'Update Payment Info' : 'Modify'
    const expiration = formatDate(get(user, 'expiresAt'), false, true)

    const iapCopy = payment.isIap
      ? `Please note that cancelling here does not automatically stop your subscription with the ${nativePaymentLabel(payment.iapStore)} -- You still need to cancel there.`
      : ''
    const expirationCopy = `Are you sure? You will continue to have access to the platform until your current billing period expires at ${expiration}. ${iapCopy}`
    if (this.state.cancelled) {
      return (
        <Redirect to={{ pathname: '/', state: { reload: true } }} />
      )
    }

    return (
      <div className={classes.container}>
        <PageTitle noIcon title="Billing Details" />

        <FormGroup icon={<Person />}>
          <DisplayField label="First Name" value={payment.firstName} />
          <DisplayField label="Last Name" value={payment.lastName} />
        </FormGroup>

        {payment.isIap && this.renderIapDetails(payment)}
        {payment.isCc && this.renderCcDetails(payment, status)}

        {pendingCancellation && <FormGroup><p>Account Pending Cancellation: {expiration}</p></FormGroup>}

        <FormGroup>

          <ActionBar>

            {status !== 'active' && (
              <ButtonLink to="/profile/payment/edit" variant="contained" primary>
                {priLabel}
              </ButtonLink>
            )}
            {(status !== 'cancelled' && !pendingCancellation) && (
              <Button variant="outlined" className={classes.error} onClick={this.handleCancel}>Cancel Account</Button>
            )}
            <ButtonLink variant="text" to="/">Back</ButtonLink>
          </ActionBar>

        </FormGroup>

        <Alert onCancel={this.handleClose}
               onProceed={this.handleCancelConfirmed}
               dangerous
               open={this.state.cancelAlertOpen}
               title="Confirm Cancellation"
               text={expirationCopy} />

      </div>
    )
  }
}

export default withStyles(styles)(PaymentDetail)
