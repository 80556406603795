"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VALIDATE_RECEIPT = exports.CANCEL_ACCOUNT = exports.SAVE_PAYMENT_DETAILS = exports.GET_PAYMENT_DETAILS = exports.START_CHECKOUT = void 0;

var _graphqlTag = _interopRequireDefault(require("graphql-tag"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  mutation validateReceipt($userId: ID!, $receiptData: String!) {\n      validateReceipt(userId: $userId, receiptData: $receiptData) {\n          valid\n      }\n  }\n  "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n    mutation cancelAccount {\n        cancelAccount {\n            success\n        }\n    }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    mutation savePayment($attributes: PaymentInput!) {\n        savePayment(attributes: $attributes) {\n            success\n        }\n    }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    query getPaymentDetails {\n        paymentDetails {\n            firstName\n            lastName\n            isActive\n            isCc\n            isIap\n            ccLast4\n            ccDesc\n            ccExpDate\n            recurringTxnId\n            \n            monthlyPrice\n            iapStore\n            iapOriginalTxnId\n            iapExpiresAtMs\n        }\n    }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n    query startCheckout {\n        startCheckout {\n            success\n            token\n            validUntil\n            apiUrl\n            userIp\n            price\n        }\n    }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

var START_CHECKOUT = (0, _graphqlTag.default)(_templateObject());
exports.START_CHECKOUT = START_CHECKOUT;
var GET_PAYMENT_DETAILS = (0, _graphqlTag.default)(_templateObject2());
exports.GET_PAYMENT_DETAILS = GET_PAYMENT_DETAILS;
var SAVE_PAYMENT_DETAILS = (0, _graphqlTag.default)(_templateObject3());
exports.SAVE_PAYMENT_DETAILS = SAVE_PAYMENT_DETAILS;
var CANCEL_ACCOUNT = (0, _graphqlTag.default)(_templateObject4());
exports.CANCEL_ACCOUNT = CANCEL_ACCOUNT;
var VALIDATE_RECEIPT = (0, _graphqlTag.default)(_templateObject5());
exports.VALIDATE_RECEIPT = VALIDATE_RECEIPT;