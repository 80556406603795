import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Save } from '../../styles/icons'
import Button from './Button'

const styles = {}

export const SaveButton = ({classes, ...rest}) => {
  return (
    <Button {...rest}>
      <Save />
      Save
    </Button>
  )
}

export default withStyles(styles)(SaveButton)
