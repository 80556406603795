import React from 'react'

export const NotificationContext = React.createContext({
  message: {}, // data-only message to be processed silently
  notification: {}, // user-facing message requiring interaction
})

export const NotificationProvider = NotificationContext.Provider
export const NotificationConsumer = NotificationContext.Consumer

export default NotificationContext
