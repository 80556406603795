// @flow
import React, { PureComponent } from 'react'
import get from 'lodash/get'
import TermsOfUse from '../../components/Page/TermsOfUse'

type Props = {}
type State = {}

const iframes = {
  'disclaimer': 'https://app.termly.io/document/disclaimer/33d225e4-d730-4f5a-a331-a0859214d140',
  // 'terms-of-use': 'https://app.termly.io/document/terms-of-use-for-website/a42eb476-721f-46af-9436-dbbcb12acf40',
  // 'refund-policy': 'https://app.termly.io/document/return-policy/2766c163-0a2f-495a-8316-17a9adfcae93',
  'privacy-policy': 'https://app.termly.io/document/privacy-policy/3b8ac89d-c662-42a1-938e-74bded4a55c6',
}
const pageComponents = {
  'terms-of-use': <TermsOfUse />,
}

class PageContainer extends PureComponent<Props, State> {
  static defaultProps = {}

  renderIframe = (slug) => {
    const page = iframes[slug]
    return (
      <div>

        <iframe width="100%" style={{ height: '95vh' }}
                src={page} frameBorder="0" title={slug}
                allowFullScreen>
          <p>Your browser does not support iframes.</p>
        </iframe>


      </div>
    )
  }

  render () {
    // console.log('page render', this.props)

    const slug = get(this.props, 'match.params.page')
    const page = iframes[slug]
    if (page) {
      return this.renderIframe(slug)
    }

    const pc = pageComponents[slug]
    if (pc) {
      return pc
    }

    return 'Nothing to see here....'

  }
}

export default PageContainer
