import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MuiSwitch from '@material-ui/core/Switch'

export const Switch = ({label, value, checked, onChange, color, labelPlacement, ...rest}) => {
  // console.log('Switch?', label, value, checked, onChange)
  return (
    <FormControlLabel
      control={
        <MuiSwitch
          checked={checked}
          onChange={onChange}
          value={value === null || value === undefined ? '' : value}
          color={color || 'secondary'}
          {...rest}
        />
      }
      label={label}
      labelPlacement={labelPlacement}
    />
  )
}

export default Switch
