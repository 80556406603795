import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import globalStyles from '../../styles/global'
import { PageTitle } from '../PageTitle'
import { HelpContent } from '../Global'
import { Button } from '../Button'
import { Email } from '../../styles/icons'

const styles = theme => ({
  ...globalStyles(theme),
  support: {
    marginTop: theme.spacing.unit * 4,
  },
  email: {
    marginRight: theme.spacing.unit,
  },
})

const Help = withStyles(styles)(({ classes }) => {
  const aside = (
    <Button component='a' href="mailto:help@balanceondemand.com" color="secondary">
      <Email className={classes.email} />
      Email support
    </Button>
  )
  return (
    <div className={classes.container}>
      <PageTitle noIcon aside={aside}>Helpful Information</PageTitle>
      <HelpContent />

      <div className={classes.support}>
        {aside}
      </div>
    </div>
  )
})

export default Help
