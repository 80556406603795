"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GOALS = exports.DEMOGRAPHICS = exports.DEFAULT_PROFILE = exports.BLANK_ADDRESS = void 0;
var BLANK_ADDRESS = {
  street1: '',
  street2: '',
  city: '',
  region: '',
  postal: ''
};
exports.BLANK_ADDRESS = BLANK_ADDRESS;
var DEFAULT_PROFILE = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  passwordConfirmation: ''
};
exports.DEFAULT_PROFILE = DEFAULT_PROFILE;
var DEMOGRAPHICS = {
  firstName: '',
  lastName: '',
  email: '',
  gender: '',
  timezone: '',
  age: '',
  weightLb: '',
  heightIn: '',
  resetAll: false
};
exports.DEMOGRAPHICS = DEMOGRAPHICS;
var GOALS = {
  weightGoal: '',
  buildMuscle: '',
  weightChange: '',
  bodyFatPct: '',
  resetAll: false
};
exports.GOALS = GOALS;