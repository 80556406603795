import React from 'react'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
// import Button from '@material-ui/core/Button'
import Button from './Button'
const styles = {}

export const ButtonLink = ({classes, primary, secondary, ...rest}) => {
  const myProps = {
    color: primary ? 'primary' : secondary ? 'secondary' : rest.color,
    variant: primary ? 'contained' : 'outlined',
  }
  return (
    <Button component={Link} {...myProps} {...rest} />
  )
}

export default withStyles(styles)(ButtonLink)
