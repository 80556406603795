import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { withRouter, Link } from 'react-router-dom'

import { GET_MEAL_PLAN } from '@balance/lib/api/meal-plan'

class DashboardContainer extends Component {

  constructor (props, ctx) {
    super(props, ctx)

    this.state = {
      error: '',
    }
  }

  render () {

    return (
      <Query query={GET_MEAL_PLAN}>
        {(props) => {
          // console.log('dashboard container props', props)

          return (
            <div>
              <p>DASHBOARD!</p>
              <Link to="/logout">Logout!</Link><br />
              <Link to="/profile/demo">Edit Profile</Link>
            </div>
          )
        }}
      </Query>
    )
  }
}

export default withRouter(DashboardContainer)
