// @flow

import React, { PureComponent } from 'react'
import classnames from 'classnames'
import get from 'lodash/get'

import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Divider from '@material-ui/core/Divider'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'

import { isImpersonationActive } from '@balance/lib/api/authToken'
import { stopImpersonation } from '@balance/lib/api/user'
import { formatDate } from '@balance/lib/utils'

import { ButtonLink } from '../Button'
import { colors } from '@balance/lib/styles'
import { drawerFooter, drawerHeader, loggedInMenu, loggedOutMenu } from './SidebarMenu'

import { Cancel, MenuIcon, SignOut } from '../../styles/icons'
import logo from '../../assets/bod-logo-circle-sm.png'
import title from '../../assets/bod-title.png'
import { HelpButton } from './index'

type Props = {
  drawerOpen?: boolean,
  loggedIn?: boolean,
  showHelp: boolean,
  currentPath: string,
  orientation: 'portrait' | 'landscape',
  classes: Object,
  history: any,
  onLogin: Function,
  onLogout: Function,
  user?: Object,
};

type State = {
  drawerOpen?: boolean,
  alertOpen: boolean,
  alertMessage: string,
  loggedIn?: boolean,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  appTitle: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1rem',

    [theme.breakpoints.up('sm')]: {
      fontSize: '1.5rem',
    },

  },
  bar: {
    backgroundColor: colors.lightGreen,
    // backgroundColor: colors.hiesCrimson,

    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
    },
    '@media print': {
      display: 'none',
    },
  },
  headerSpacer: {
    // [theme.breakpoints.down('sm')]: {
    //   marginBottom: 'calc(64 + var(--safe-area-top))',
    // },
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  rightButton: {
    backgroundColor: '#4d8402',
  },
  logo: {
    width: 44,
    height: 44,
    marginLeft: '-1rem',
    // marginRight: '0.5rem',
  },
  imgTitle: {
    backgroundImage: `url(${title})`,
    width: '100%',
    maxWidth: 200,
    // height: 50,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    textIndent: '-999em',

    '@media screen and (max-width: 320px)': {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      width: 250,
    },
  },
  sidebarContainer: {
    paddingTop: 'var(--safe-inset-top)', // 'env(safe-area-inset-top)',
    paddingLeft: 'var(--safe-inset-left)', // 'env(safe-area-inset-left)',
  },
  sidebarContainerLandscape: {
    paddingTop: 0,
    paddingLeft: 'var(--safe-inset-top)', //'env(safe-area-inset-top)',
  },
  sidebar: {
    '& a': {
      textDecoration: 'none',
    },
  },
  backdrop: {
    backgroundColor: 'rgba(0,0,0, 0.8)',
  },
  fullSideBar: {
    // paddingLeft: 5,
    // backgroundColor: 'transparent',
  },
  outerHeader: {
    // color: '#fff',
    //
    // '& svg, & span': {
    //   color: '#fff !important',
    // },
  },
  outerFooter: {
    '& span': {
      fontSize: 12,
    },
  },
  menu: {},
  errorBar: {
    backgroundColor: theme.palette.error.dark,
  },
  warningBar: {
    backgroundColor: theme.colors.warning,
  },
  snackMessage: {
    display: 'flex',
    alignItems: 'center',
  },
  snackIcon: {
    marginRight: 10, // theme.spacing(0.5),
  },
})

export class Header extends PureComponent<Props, State> {
  static defaultProps = {
    showHelp: false,
  }

  state = {
    drawerOpen: this.props.drawerOpen,
    alertOpen: false,
    alertMessage: '',
    isImpersonating: false,
  }

  async componentDidMount () {
    window.addEventListener('tour:openDrawer', this.openDrawer)
    window.addEventListener('tour:closeDrawer', this.closeDrawer)
    window.addEventListener('impersonation:status-change', this.updateImpersonationStatus)

    await this.updateImpersonationStatus()
  }

  componentWillUnmount () {
    window.removeEventListener('impersonation:status-change', this.updateImpersonationStatus)
    window.removeEventListener('tour:openDrawer', this.openDrawer)
    window.removeEventListener('tour:closeDrawer', this.closeDrawer)
  }

  async componentDidUpdate (): void {
    const { user } = this.props
    const notice = get(user, 'flash.notice')
    if (Boolean(notice) && notice !== this.state.alertMessage) {
      this.setState({ alertOpen: true, alertMessage: notice })
    }
  }

  updateImpersonationStatus = async () => {
    const isImpersonating = await isImpersonationActive()
    // console.log('header.updateImpersonationStatus: isImpersonating', isImpersonating)
    this.setState({ isImpersonating })
  }
  // componentWillReceiveProps (newProps: Props) {
  //   // console.log('Header.cWRP', newProps)
  //   if (newProps.loggedIn !== this.state.loggedIn) {
  //     this.setState({loggedIn: newProps.loggedIn})
  //   }
  // }

  openDrawer = () => {
    this.setState({ drawerOpen: true })
  }

  closeDrawer = () => {
    this.setState({ drawerOpen: false })
  }

  // NOTE: returns a function
  toggleDrawer = (open: boolean, e?: Event) => () => {
    // console.log('clicked', e && e.target)
    this.setState({
      drawerOpen: open,
    })
  }

  closeAlert = () => {
    this.setState({ alertOpen: false })
  }

  render () {
    // console.log('header render', this.props, this.state)
    const { classes, loggedIn, user, showHelp, orientation } = this.props
    if (!classes) { return null }

    const pendingCancellation = loggedIn && get(user, 'status') === 'pending_cancellation'
    const expiration = formatDate(get(user, 'expiresAt'), false, true)
    const isLandscape = orientation === 'landscape'
    return (
      <React.Fragment>
        <AppBar position='static' className={classnames(classes.bar)} id="app__header">
          <Toolbar>
            <IconButton className={classes.menuButton} color='inherit' aria-label='Menu' data-tour="menu-button"
                        onClick={this.toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Typography variant='h5' color='inherit' className={classes.appTitle}>
              <img alt="" src={logo} className={classes.logo} />
              <div className={classes.imgTitle}>balance on demand.</div>
            </Typography>
            {loggedIn ? (
              <React.Fragment>
                {false && <ButtonLink color='inherit' to="/logout">Logout</ButtonLink>}
                {/*Status: {user && user.status}*/}
                {showHelp && <HelpButton />}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <ButtonLink color='inherit' className={classes.rightButton} to="/login">Login</ButtonLink>
              </React.Fragment>
            )}
          </Toolbar>
          <Drawer open={this.state.drawerOpen} onClose={this.toggleDrawer(false)}
                  elevation={0} PaperProps={{ style: styles.fullSideBar }}
                  className={classes.sidebar}
                  ModalProps={{ BackdropProps: { classes: { root: classes.backdrop } } }}
          >
            <div
              tabIndex={0}
              role='button'
              className={classnames(classes.sidebarContainer, isLandscape && classes.sidebarContainerLandscape)}
              onClick={e => this.toggleDrawer(false, e)()}
              onKeyDown={this.toggleDrawer(false)}
            >
              <div className={classes.outerHeader}>
                {user && drawerHeader(user)}
              </div>
              <Divider />
              <div>
                {loggedIn ? loggedInMenu(user) : loggedOutMenu}
              </div>
              <Divider />
              <div className={classes.outerFooter}>
                {drawerFooter}
              </div>
            </div>
          </Drawer>
          {pendingCancellation && (
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              open={pendingCancellation}
              autoHideDuration={null}>
              <SnackbarContent
                className={classes.warningBar}
                aria-describedby="message-id"
                message={`Account Pending Cancellation: ${expiration}`}
              />
            </Snackbar>
          )}
          {loggedIn && this.state.isImpersonating && (
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              open={this.state.isImpersonating}
              autoHideDuration={null}>
              <SnackbarContent
                className={classes.errorBar}
                aria-describedby="message-id"
                message="Exit Admin View"
                action={[
                  <IconButton
                    key="exitAdminView"
                    aria-label="Exit Admin View"
                    color="inherit"
                    className={classes.close}
                    onClick={stopImpersonation}
                  >
                    <SignOut />
                  </IconButton>,
                ]} />
            </Snackbar>
          )}
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={this.state.alertOpen}
            onClose={this.closeAlert}
            autoHideDuration={5000}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{this.state.alertMessage}</span>}
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.close}
                onClick={this.closeAlert}
              >
                <Cancel />
              </IconButton>,
            ]}
          />
        </AppBar>
        {/*<div className={classes.headerSpacer} />*/}
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(withRouter(Header))
