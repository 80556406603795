// @flow
import React from 'react'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

type Props = {
  classes: Object,
  children: any,
  sub: boolean,
  fixed: boolean,
  right: boolean,
  inline: boolean,
}

const styles = theme => ({
  container: {
    margin: [[theme.spacing.unit * 2, 0]],
    // backgroundColor: colors.actionBar,
    padding: 0,
  },
  actionBar: {
    textAlign: 'left',
  },
  right: {
    textAlign: 'right',
  },
  mainActionBar: {},
  subActionBar: {
    marginTop: theme.spacing.unit,
    //padding: [[0, 10]],
  },
  button: {
    display: 'inline-block',
    marginLeft: theme.spacing.unit / 2,
    marginRight: theme.spacing.unit / 2,
    marginBottom: theme.spacing.unit * 2,
    // marginRight: 0,

    // '&:first-child': {
    //   marginLeft: 0,
    // },
    // '& button': {
    //   marginRight: 0,
    // },
  },
  fixed: {
    borderTop: '1px solid #aaa',
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
  },
  transparent: {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
  },
  inline: {
    display: 'inline-flex',
    width: 'auto',
    marginTop: 0,
  },
})

export const ActionBar = ({ children, classes, className, sub, fixed, right, inline, transparent, ...props }: Props) => {
  const barType = sub ? classes.subActionBar : classes.mainActionBar
  const mainContainerClass = sub ? null : classes.container

  const containerClasses = classNames(
    mainContainerClass,
    fixed && classes.fixed,
    transparent && classes.transparent,
    inline && classes.inline,
  )
  return (
    <Grid container className={containerClasses}>
      <Grid item xs={12} className={classNames(className, classes.actionBar, right && classes.right, barType)}>
        {React.Children.map(children, (child) => {
          // console.log('actionbar child', child, i)
          if (child) {
            return (
              <div className={classes.button}>{child}</div>
            )
          }
        })}
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(ActionBar)

