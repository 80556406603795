// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    // minWidth: '100%',
  },
})

const DataTable = ({classes, items, columnLabels}) => {
  return (
    <Paper className={classes.root} elevation={0}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {columnLabels.map((col, i) => (
              <TableCell key={i} align={i>0 ? 'right' : 'left'}>{col}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, i) => {
            return (
              <TableRow key={i}>
                {item.map((el, j) => (
                  <TableCell key={j} align={i>0 ? 'right' : 'left'}>{el}</TableCell>
                ))}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Paper>
  )
}

export default withStyles(styles)(DataTable)
