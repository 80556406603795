// @flow
import React, { PureComponent, Fragment } from 'react'
import classnames from 'classnames'

import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Typography from '@material-ui/core/Typography'

import { colors } from '@balance/lib/styles'

import { Button, ButtonLink } from '../Button'
import PageTitle from '../PageTitle/PageTitle'
import baseStyles from '../../styles/global'
import { SquareUnchecked, Print, Food } from '../../styles/icons'
import WeekToggle from './WeekToggle'
import FullPageSpinner from '../Global/FullPageSpinner'

type Props = {
  recipes: Array<Object>,
  items: Array<Object>,
  week?: ?string,
}
type State = {}

const styles = theme => {
  const global = baseStyles(theme)
  return {
    ...global,
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    paper: {
      '@media print': {
        boxShadow: 'none',
        border: '1px solid #999',
      },
    },
    list: {
      paddingTop: 0,
      paddingBottom: 0,

      '@media print': {},
    },
    header: {
      backgroundColor: colors.tableHeader,
      '& p, & span': {
        color: '#fff',
      },

      '@media print': {
        backgroundColor: 'transparent',
        '& p, & span': {
          color: 'black',
        },
      },
    },
    item: {
      '@media print': {
        // display: 'inline-flex',
        // alignItems: 'center',
        // justifyContent: 'space-between',
        // padding: 0,
        pageBreakInside: 'avoid',
        boxDecorationBreak: 'clone',
      },
    },
    mainLabel: {
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        alignItems: 'flex-end',
      },
      '@media print': {
        display: 'flex',
        alignItems: 'flex-end',
      },
    },
    itemName: {
      [theme.breakpoints.up('sm')]: {
        fontSize: '1rem',
        lineHeight: 1.4,
        verticalAlign: 'bottom',
      },
      '@media print': {
        fontSize: '1rem',
        lineHeight: 1.4,
        verticalAlign: 'bottom',
      },
    },
    itemPkg: {
      [theme.breakpoints.up('sm')]: {
        fontSize: '0.85rem',
        // lineHeight: 1.4,
        verticalAlign: 'bottom',
      },
      '@media print': {
        color: 'black',
        fontSize: '0.85rem',
        // lineHeight: 1.4,
        verticalAlign: 'bottom',
      },
    },
    itemSep: {
      fontSize: '1rem',
      lineHeight: 1.4,
      margin: [[0, 5]],

      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
      '@media print': {
        display: 'block',
      },
    },
    itemText: {
      [theme.breakpoints.up('xs')]: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 0,
      },

      '@media print': {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 0,
      },
    },
    icon: {
      ...global.icon,

      '@media screen': {
        display: 'none',
      },
    },
    printIcon: {
      '@media print': {
        display: 'none',
      },
    },
    type: {
      width: 75,
    },
    amount: {
      textAlign: 'right',
      '@media print': {
        color: 'black',
        marginLeft: theme.spacing.unit,
      },
    },
  }
}

class GroceryList extends PureComponent<Props, State> {
  static defaultProps = {
    items: [],
    week: '',
  }

  formatQty = (item) => {
    // console.log('formatQty', item, item.name)
    if (item.fixed) {
      return `${item.qty}`
    }

    const inGrams = `${item.qtyG}g`
    if (item.qty && item.unit) {
      return `${item.qty} ${item.unit} (${inGrams})`
    } else {
      return inGrams
    }
  }

  formatPackage = (item) => {
    const { packagedAs } = item
    if (packagedAs && packagedAs.qty) {
      return `${packagedAs.qty} ${packagedAs.unit}`
    }
  }

  formatAmount = (item) => {
    const raw = this.formatQty(item)
    const pkg = this.formatPackage(item)
    if (pkg) {
      return `${raw} | ${pkg}`
    }
    return raw
  }

  handlePrint = () => {
    window.print && window.print()
  }

  renderAside = () => {
    const { week } = this.props
    let weekQS = week ? `?week=${week}` : ''
    let aside = []
    if (window.print) {
      aside.push(
        <Button key="2" size="small" secondary variant="text" className={this.props.classes.printIcon}
                onClick={this.handlePrint}>
          <Print color="secondary" />
          Print
        </Button>,
      )
    }
    aside.push(
      <ButtonLink key="1" size="small" to={`/meal-plan${weekQS}`} secondary variant="text">
        <Food />
        Meal Plan
      </ButtonLink>,
    )
    return aside
  }

  renderItemPrimary = (item) => {
    const pkg = this.formatPackage(item)
    return (
      <Fragment>
        <span className={this.props.classes.itemName}>{item.name}</span>
        {pkg && (<span className={this.props.classes.itemSep}>-</span>)}
        <Typography className={this.props.classes.itemPkg} color="textSecondary" component="span">{pkg}</Typography>
      </Fragment>
    )
  }

  render () {
    const { classes, items, loading, week, weekOfShort } = this.props
    // console.log('GroceryList.render', this.props)
    return (
      <div className={classes.container}>
        <div className={classnames(classes.printOneLine, classes.printTitle)}>
          <PageTitle title="Grocery List" noIcon showHelp aside={this.renderAside()} condensed />
          <WeekToggle week={week} weekOfShort={weekOfShort} />
        </div>

        <Paper className={classes.paper}>
          <List className={classes.list}>
            <ListItem key="header" divider className={classes.header}>
              <ListItemIcon className={classes.icon}>
                <span>&nbsp;</span>
              </ListItemIcon>
              <ListItemText className={classes.itemText}
                            primary="Item"
                            secondary="Exact Amount Needed"
                            secondaryTypographyProps={{ className: classes.amount }}
              />
            </ListItem>
            {items.map((item, i) => (
                <ListItem key={i} divider className={classes.item}>
                  <ListItemIcon className={classes.icon}>
                    <SquareUnchecked />
                  </ListItemIcon>
                  <ListItemText className={classes.itemText}
                                primary={this.renderItemPrimary(item)}
                                primaryTypographyProps={{ className: classes.mainLabel }}
                                secondary={this.formatQty(item)}
                                secondaryTypographyProps={{ className: classes.amount }}
                  />
                </ListItem>
              ),
            )}
          </List>
        </Paper>

        <FullPageSpinner open={loading} />
      </div>
    )
  }
}

export default withStyles(styles)(GroceryList)
