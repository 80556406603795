import React, { Component } from 'react'
import { ApolloConsumer, Query } from 'react-apollo'
import { Redirect, withRouter } from 'react-router-dom'
import get from 'lodash/get'

import { CurrentUserConsumer } from '@balance/lib/contexts'

import { CANCEL_ACCOUNT, GET_PAYMENT_DETAILS } from '@balance/lib/api/payment'

import { PaymentDetail } from '../../components/Payment'

class PaymentDetailContainer extends Component {
  state = {}

  // componentWillReceiveProps = (nextProps) => {
  //   console.log('PaymentDetailContainer.cWRP', nextProps, this.state, this.props)
  // }
  //
  // componentDidUpdate = () => {
  //   console.log('PaymentDetailContainer.dU', this.state)
  // }

  render () {
    // const step = get(this.props, 'match.params.step')
    // console.log('profile step', step, this.state)
    // console.log('profile payment container.render', this.state)
    return (
      <CurrentUserConsumer>
        {({ state, refetch }) => {
          // console.log('PaymentDetailContainer.user consumer', state)

          refetch && refetch()

          // const profile = get(state, 'profile', {})
          // <Mutation mutation={ADJUST_PROFILE}>
          return (
            <Query query={GET_PAYMENT_DETAILS}>
              {({ data, loading, ...rest }) => {
                // console.log('payment data', loading, data, rest)

                const payment = get(data, 'paymentDetails', {})
                let jumpToEdit = false
                if (!loading && payment) {
                  jumpToEdit = payment.isActive && !payment.isIap && (!payment.isCc || !payment.ccLast4)
                }
                if (jumpToEdit) {
                  return (
                    <Redirect to="/profile/payment/edit" />
                  )
                }
                return (
                  <ApolloConsumer>
                    {(client) => {
                      const cancelAccount = async (details) => {
                        // console.log('cancelPayment', details)
                        const { data } = await client.mutate({
                          mutation: CANCEL_ACCOUNT,
                          variables: { attributes: details },
                        })
                        // console.log('cancelAccount result', data)
                        refetch && refetch()
                        return get(data, 'cancelAccount')
                      }

                      return (
                        <PaymentDetail user={state}
                                       payment={payment}
                                       cancelAccount={cancelAccount} />
                      )
                    }}
                  </ApolloConsumer>

                )
              }}
            </Query>
          )
        }}
      </CurrentUserConsumer>
    )
  }
}

export default withRouter(PaymentDetailContainer)
