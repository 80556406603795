import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import { InactiveAccount } from '../../components/User'

class InactiveContainer extends Component {

  render () {

    return <InactiveAccount />

  }
}

export default withRouter(InactiveContainer)
