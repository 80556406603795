// @flow
import React, { PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import { Spinner } from '../Global'

import FormField from './FormField'
import { Search } from '../../styles/icons'
import formStyles from '../../styles/forms'

type Props = {
  classes: Object,
  label: string,
  searching: boolean,
  notFound: boolean,
  iconStart: boolean,
  onSearch: Function,
  onChange: Function,
  // onSubmit: Function,
}

type State = {
  query: ?string,
  searching: boolean,
  notFoundOpen: boolean,
}

const styles = theme => {
  return {
    ...formStyles(theme),
    iconBtn: {
      padding: 6,
      marginTop: -10,
    },
    icon: {
      width: 24,
      height: 24,
    },
  }
}

class SearchField extends PureComponent<Props, State> {
  static defaultProps = {
    label: 'Search',
    onSearch: () => {},
  }

  searchRef: { current: null | ?HTMLInputElement }

  constructor (props: Props) {
    super(props)

    this.searchRef = React.createRef()

    this.state = {
      query: '',
      searching: props.searching || false,
      notFoundOpen: props.notFound || false,
    }
  }

  cancelSearch = () => {
    this.setState({
      query: '',
      searching: false,
    })
  }

  focusSearch = () => {
    // console.log('focusing...', this.searchRef)
    this.searchRef && this.searchRef.current && this.searchRef.current.focus()
  }

  handleSearchChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const { onChange } = this.props
    const term = e.target.value
    // console.log('handleSearchChange', term)
    this.setState({
        query: term,
      },
      onChange && onChange(term),
    )
  }

  handleSearch = async () => {
    // console.log('searching...', this.state.query)
    if (!this.state.query) {
      this.focusSearch()
    } else {
      this.setState({ searching: true })
      this.props.onSearch && await this.props.onSearch(this.state.query)
      this.setState({ searching: false })
    }
  }

  render () {
    // console.log('SearchField.render', this.props, this.state)
    const { label, classes, onSearch, onChange, searching : searchingProp, iconStart, ...rest } = this.props
    const { query, searching } = this.state

    const iconPlacement = iconStart ? 'start' : 'end'
    const iconAdornment = `${iconPlacement}Adornment`
    const adornment = (
      <InputAdornment position={iconPlacement}>
        <IconButton className={classes.iconBtn} onClick={this.handleSearch}>
          {searching ? (
            <Spinner className={classes.progress} />
          ) : (
            <Search className={classes.icon} />
          )}
        </IconButton>
      </InputAdornment>
    )

    const fieldProps = {
      [iconAdornment]: adornment,
    }

    return (
      <FormField
        name="search"
        label={label}
        type="search"
        inputRef={this.searchRef}
        onChange={this.handleSearchChange}
        value={query}
        fieldProps={fieldProps}
        {...rest}
      />
    )
  }
}

export default withStyles(styles)(SearchField)
