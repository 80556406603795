import React, { Component } from 'react'
import { Mutation } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'

import { CONFIRM_ACCOUNT } from '@balance/lib/api/user'

import ConfirmAccount from '../../components/User/ConfirmAccount'

class ConfirmAccountContainer extends Component {

  constructor (props, ctx) {
    super(props, ctx)

    this.state = {
      error: '',
    }
  }

  redirect = () => {
    // window.location.replace('/')
    this.props.history.replace('/', { reload: true, loggedIn: true })
  }

  render () {
    const confirmationToken = get(this.props, 'location.search').replace('?confirmation_token=', '')

    console.log('confirmationToken', confirmationToken, this.props)

    return (
      <Mutation mutation={CONFIRM_ACCOUNT}>
        {(confirm, {data, loading, error}, ...rest) => {
          console.log('confirm account container props', data)

          const doConfirm = async (attributes) => {
            console.log('confirmAccount start', attributes)
            const result = await confirm({variables: {confirmationToken: confirmationToken, ...attributes}})
            console.log('confirmAccount result', result)
            return result
          }

          return (
            <ConfirmAccount onLoad={doConfirm} redirect={this.redirect}/>
          )
        }}
      </Mutation>
    )
  }
}

export default withRouter(ConfirmAccountContainer)
