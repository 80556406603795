"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  DAYS_OF_WEEK: true,
  WEEK_DAYS: true,
  MONTH_NAMES: true,
  UNITS: true,
  defaults: true
};
Object.defineProperty(exports, "defaults", {
  enumerable: true,
  get: function get() {
    return _defaults.default;
  }
});
exports.UNITS = exports.MONTH_NAMES = exports.WEEK_DAYS = exports.DAYS_OF_WEEK = void 0;

var _defaults = _interopRequireWildcard(require("./defaults"));

Object.keys(_defaults).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _defaults[key];
    }
  });
});

var _us_states = require("./us_states");

Object.keys(_us_states).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _us_states[key];
    }
  });
});

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {};

          if (desc.get || desc.set) {
            Object.defineProperty(newObj, key, desc);
          } else {
            newObj[key] = obj[key];
          }
        }
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

var DAYS_OF_WEEK = ['MON', 'TUE', 'WED', 'THU', 'FRI'];
exports.DAYS_OF_WEEK = DAYS_OF_WEEK;
var WEEK_DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
exports.WEEK_DAYS = WEEK_DAYS;
var MONTH_NAMES = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
exports.MONTH_NAMES = MONTH_NAMES;
var UNITS = [{
  unit: 'g',
  label: 'g'
}, {
  unit: 'kg',
  label: 'kg'
}, {
  unit: 'oz',
  label: 'oz'
}, {
  unit: 'lb',
  label: 'lb'
}, {
  unit: 'tbsp',
  label: 'tbsp'
}, {
  unit: 'tsp',
  label: 'tsp'
}];
exports.UNITS = UNITS;