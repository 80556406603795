import React, { Component } from 'react'
import classnames from 'classnames'

import { FieldArray, Form, Formik, getIn } from 'formik'
import { Checkbox, IconButton, Radio, withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'

import get from 'lodash/get'
import compact from 'lodash/compact'

import { transform } from '@balance/lib/api/errors'
import { DEMOGRAPHICS, GOALS } from '@balance/lib/data'
import { profileActivitySchema, profileDemoSchema, profileGoalsSchema } from '@balance/lib/validations'

import { ActionBar, FormField, FormGroup, Option, RadioGroup } from '../Form'
import { Button, ButtonLink } from '../Button'
import PageTitle from '../PageTitle/PageTitle'

import formStyles from '../../styles/forms'
import {
  Delete, Email, FitnessCenter, Notification, Person, RestaurantMenu, Smartphone, ThumbDown, Timeline,
} from '../../styles/icons'
import TimeZoneField from '../Form/TimeZoneField'
import { IngredientSearch } from '../Ingredient'

type Props = {
  profile: Object,
  step: number,
  onSubmit: Function,
  onPrevious: Function,
  onGoTo: Function,
  onSearchFoods: Function,
  isLastStep: boolean,
  showTabs: boolean,
  systemConfig: Object,
}

type State = {
  error?: Object,
}

const styles = (theme) => ({
  ...formStyles(theme),
  tabs: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: [[8 * 2, 0]],
    marginBottom: '1rem',
    // height: 50,

    // '@media screen and (max-width: 359px)': {
    //   display: 'none',
    // },
  },
})
// const IS_DEV = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

const activityLevels = [
  {
    level: 1,
    label: 'Completely Sedentary (Bed Rest)',
    descr: 'Does not exercise and does not move around for work',
  },
  {
    level: 2,
    label: 'Very Sedentary',
    descr: 'Does not exercise and does not move around for work',
  },
  {
    level: 3,
    label: 'Lightly Active',
    descr: 'Lightly exercises 1-2 times per week and does not move around often for work',
  },
  {
    level: 4,
    label: 'Semi-moderately Active',
    descr: 'Lightly exercises 2-3 times per week and does not move around often for work',
  },
  {
    level: 5,
    label: 'Moderately Active',
    descr: 'Moderately exercises 3-5 times per week and moves every so often for work',
  },
  {
    level: 6,
    label: 'Very Active',
    descr: 'exercises 5-6 times per week and moves often for work',
  },
  {
    level: 7,
    label: 'Extremely Active',
    descr: 'exercises hard 6-7 times per week and moves often for work',
  },
  {
    level: 8,
    label: 'Majorly Active',
    descr: 'exercises hard every day and moves constantly for work',
  },
  {
    level: 9,
    label: 'Professional Athlete',
    descr: 'Lower Intensity',
  },
  {
    level: 10,
    label: 'Professional Athlete',
    descr: 'Higher Intensity',
  },
].reverse()

const sortByLabel = (a, b, labelField = 'label') => {
  const al = a[labelField].toLowerCase()
  const bl = b[labelField].toLowerCase()
  let comparison = 0
  if (al > bl) {
    comparison = 1
  } else if (al < bl) {
    comparison = -1
  }
  return comparison
}

const sortByName = (a, b) => sortByLabel(a, b, 'name')

// const allDietaryRestrictions = [
//   { key: 'GLUTEN_FREE', label: 'Gluten Free' },
//   { key: 'DAIRY_FREE', label: 'Dairy Free' },
//   { key: 'EGG_FREE', label: 'Egg Free' },
//   { key: 'FISH_FREE', label: 'Fish Free' },
//   { key: 'TREE_NUT_FREE', label: 'No Tree Nuts' },
//   { key: 'NO_OIL_ADDED', label: 'No Oil Added' },
//   { key: 'NO_SUGAR_ADDED', label: 'No Sugar Added' },
//   { key: 'MILK_FREE', label: 'Milk Free' },
//   { key: 'SOY_FREE', label: 'Soy Free' },
//   { key: 'VEGAN', label: 'Vegan' },
//   { key: 'VEGETARIAN', label: 'Vegetarian' },
//   { key: 'PESCATARIAN', label: 'Pescatarian' },
//   { key: 'PALEO', label: 'Paleo' },
//   { key: 'PEANUT_FREE', label: 'Peanut Free' },
//   { key: 'PORK_FREE', label: 'Pork Free' },
//   { key: 'RED_MEAT_FREE', label: 'Red Meat Free' },
//   { key: 'SHELLFISH_FREE', label: 'Shellfish Free' },
//   { key: 'KETO', label: 'Ketogenic' },
// ].sort(sortByLabel)

const TAB_LIST = [
  'Personal',
  'Goals',
  'Activity Level',
  'Diet',
  'Notifications',
  'Targets',
]

const FakeTab = ({ label, onClick, selected, ...rest }) => (
  <Button size="small" onClick={onClick}
          variant={selected ? 'outlined' : 'text'}
          color={selected ? 'primary' : 'default'}
          {...rest}
  >{label}</Button>
)

// const TabContainer = (({ classes, children }) => {
//   return (
//     <Typography component="div">
//       {children}
//     </Typography>
//   )
// })

const ProfileDemo = ({ values, showTabs, handleChange, changeProps, systemConfig, validationProps }) => {
  // console.log('ProfileDemo()', values, showTabs, timeZones)

  const timeZones = get(systemConfig, 'timeZones', [])
  return (
    <FormGroup icon={<Person />}>
      {showTabs && [
        <FormField key="firstName" name="firstName" label="First Name" value={values.firstName}
                   onChange={handleChange}
                   {...changeProps} {...validationProps('firstName')}
        />,
        <FormField key="lastName" name="lastName" label="Last Name" value={values.lastName}
                   onChange={handleChange}
                   {...changeProps} {...validationProps('lastName')}
        />,
        <FormField key="email" name="email" label="Email" value={values.email}
                   onChange={handleChange}
                   {...changeProps} {...validationProps('email')}
        />,
        <TimeZoneField key="timezone" timezone={values.timezone} onChange={handleChange} timeZones={timeZones} />,
      ]}

      <RadioGroup name="gender" label="Gender" value={values.gender} horizontal
                  onChange={handleChange} {...validationProps('gender')}>
        <Option label="Male" value="male" onChange={handleChange} />
        <Option label="Female" value="female" onChange={handleChange} />
        <Option label="Other" value="other" onChange={handleChange} />
      </RadioGroup>

      <FormField name="age" label="Age (years)" value={values.age}
                 type="number"
                 onChange={handleChange}
                 {...changeProps} {...validationProps('age')}
      />

      <FormField name="weightLb" label="Current Weight (lbs)" value={values.weightLb}
                 type="number"
                 onChange={handleChange}
                 {...changeProps} {...validationProps('weightLb')}
      />
      <FormField name="heightIn" label="Height (inches)" value={values.heightIn}
                 type="number"
                 onChange={handleChange}
                 {...changeProps} {...validationProps('heightIn')}
      />

    </FormGroup>
  )
}

const ProfileGoals = ({ values, handleChange, changeProps, validationProps }) => {
  // console.log('ProfileGoals()', values)

  const weightChangeLabel = values.weightGoal === 'gain' ? 'Gain' : 'Loss'
  const withMuscle = Boolean(values.buildMuscle === true || values.buildMuscle === 'true')
  const noMuscle = Boolean(values.buildMuscle === false || values.buildMuscle === 'false')
  return (
    <FormGroup icon={<Timeline />}>

      <RadioGroup name="buildMuscle" label="Build Muscle?" horizontal
                  value={String(values.buildMuscle)} onChange={handleChange}
                  {...validationProps('buildMuscle')}>
        <Option label="Yes" value={'true'} checked={withMuscle} onChange={handleChange} />
        <Option label="No" value={'false'} checked={noMuscle} onChange={handleChange} />
      </RadioGroup>

      <RadioGroup name="weightGoal" label="Weight Change Goal" value={values.weightGoal} horizontal
                  onChange={handleChange} {...validationProps('weightGoal')}>
        <Option label="Gain" value="gain" onChange={handleChange} />
        <Option label="Lose" value="lose" onChange={handleChange} />
        <Option label="Maintain" value="maintain" onChange={handleChange} />
      </RadioGroup>

      {values.weightGoal === 'maintain' || (
        <FormField name="weightChange" label={`Weight ${weightChangeLabel} (lbs)`} value={values.weightChange}
                   type="number"
                   onChange={handleChange}
                   {...changeProps} {...validationProps('weightChange')}
        />
      )}

      <FormField name="bodyFatPct" label="Current Body Fat % (optional)" value={values.bodyFatPct || ''}
                 type="number"
                 onChange={handleChange}
                 {...changeProps} {...validationProps('bodyFatPct')}
      />

    </FormGroup>
  )
}

const ProfileActivityLevel = ({ values, classes, validationProps, handleChange }) => {
  const lvl = parseInt(values.activityLevel, 10)
  // console.log('ProfileActivityLevel()', values.activityLevel, lvl, validationProps('activityLevel'))

  const { touched, error } = validationProps('activityLevel')
  return (
    <FormGroup icon={<FitnessCenter />} title="Activity Level">
      <p className={classes.error}>{touched && error}</p>
      <FormControl component="fieldset">
        {activityLevels.map((al, i) => (
          <label key={i} className={classnames(classes.listItem, classes.radioListItem)}>
            <Radio
              checked={lvl === al.level}
              onChange={handleChange}
              value={al.level}
              name="activityLevel"
              inputProps={{ 'aria-label': al.label }}
            />
            <div>
              <strong>{al.label}</strong>
              <span>{al.descr}</span>
            </div>
          </label>

        ))}
      </FormControl>
      <p className={classes.error}>{touched && error}</p>
    </FormGroup>
  )
}
const ProfileNutrition = ({ values, classes, systemConfig,  onSearchFoods }) => {
  const selected = values.restrictions

  const allDietaryRestrictions = get(systemConfig, 'dietaryRestrictions', []).sort(sortByLabel)
  // console.log('ProfileNutrition()', onSearchFoods, values.restrictions, allDietaryRestrictions)
  // console.log('syncing', allDietaryRestrictions)
  const dislikes = get(values, 'dislikes', []).sort(sortByName)

  return (
    <React.Fragment>
      <FormGroup icon={<RestaurantMenu />} title="Dietary Restrictions">
        <FieldArray name="restrictions"
                    render={({ replace }) => (
                      <FormControl component="fieldset" className={classes.checkboxGroup}>
                        {allDietaryRestrictions.map((al, i) => (
                          <label key={al.key} className={classnames(classes.listItem, classes.checkboxListItem,
                            classes.checkboxWrappable)}>
                            <Checkbox
                              checked={selected.indexOf(al.key) >= 0}
                              onChange={(e) => replace(i, e.currentTarget.checked ? al.key : false)}
                              value={al.key}
                              name={`restrictions.${i}`}
                              inputProps={{ 'aria-label': al.label }}
                            />
                            <div>
                              <strong>{al.label}</strong>
                            </div>
                          </label>
                        ))}
                      </FormControl>
                    )}
        />
      </FormGroup>

      <FormGroup icon={<ThumbDown />} title="Dislikes / Ingredients to avoid">

        <FieldArray name="dislikes"
                    render={({ push, remove }) => (
                      <React.Fragment>
                        <IngredientSearch onSearch={onSearchFoods} onSelect={(item) => push(item)} />
                        <FormControl component="fieldset" className={classes.checkboxGroup}>
                          {dislikes.map((al, i) => (
                            <label key={al.id} className={classnames(classes.listItem, classes.checkboxListItem,
                              classes.checkboxWrappable)}>
                              <IconButton title="Remove from list" onClick={() => remove(i)}>
                                <Delete />
                              </IconButton>
                              <div>
                                <strong>{al.name}</strong>
                              </div>
                            </label>
                          ))}
                        </FormControl>
                      </React.Fragment>
                    )}
        />
      </FormGroup>

    </React.Fragment>
  )
}
const ProfileNotificationPrefs = ({ values, classes, systemConfig, handleChange, changeProps, validationProps }) => {
  // console.log('ProfileNotificationPrefs()', values.notificationPreferences)
  const allNotificationPreferences = get(systemConfig, 'notificationPreferences', []).sort(sortByLabel)
  return (
    <FormGroup icon={<Notification />} title="Notification Preferences">
      <Grid container alignItems="center">
        <Grid item xs={6} className={classes.listItem}><strong>&nbsp;</strong></Grid>
        <Grid item xs={3}
              className={classnames(classes.listItem, classes.checkboxOption)}><Email /><span>Email</span></Grid>
        <Grid item xs={3} className={classnames(classes.listItem, classes.checkboxOption)}><Smartphone />
          <span>App Push</span></Grid>
      </Grid>

      <FieldArray
        name="notificationPreferences"
        render={({ replace }) => (
          <FormControl component="fieldset" className={classes.checkboxGroup}>
            {allNotificationPreferences.map((al, i) => {
              const email = get(values, `notificationPreferences.${i}.email`, true)
              const push = get(values, `notificationPreferences.${i}.push`, true)
              // console.log('pref', al.key, email, push)
              return (
                <Grid key={al.key} container alignItems="center">
                  <Grid item xs={6}>
                    {al.label}
                  </Grid>
                  <Grid item xs={3} className={classes.checkboxOption}>
                    <Checkbox
                      checked={email}
                      // value={`${email}`}
                      onChange={handleChange}
                      name={`notificationPreferences.${i}.email`}
                      inputProps={{ 'aria-label': al.label }}
                    />
                  </Grid>
                  <Grid item xs={3} className={classes.checkboxOption}>
                    <Checkbox
                      onChange={handleChange}
                      checked={push}
                      // value={`${push}`}
                      name={`notificationPreferences.${i}.push`}
                      inputProps={{ 'aria-label': al.label }}
                    />
                  </Grid>
                </Grid>
              )
            })}
          </FormControl>
        )}
      />
    </FormGroup>)
}

const profileConfig = {
  demo: { step: 1, comp: ProfileDemo, schema: profileDemoSchema, fields: { ...DEMOGRAPHICS } },
  goals: { step: 2, comp: ProfileGoals, schema: profileGoalsSchema, fields: { ...GOALS } },
  activity: {
    step: 3,
    comp: ProfileActivityLevel,
    schema: profileActivitySchema,
    fields: { activityLevel: 0, resetAll: false },
  },
  nutrition: { step: 4, comp: ProfileNutrition, schema: null, fields: { restrictions: [], dislikeIds: [] } },
  notificationPreferences: {
    step: 5,
    comp: ProfileNotificationPrefs,
    schema: null,
    fields: { notificationPreferences: [] },
  },
}

export class ProfileWizard extends Component<Props, State> {
  static defaultProps = {
    step: '',
    profile: {},
  }
  state = {
    error: this.props.error || '',
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.currentTarget.value,
      error: '',
    })
  }

  normalizeValues = (values) => {
    const config = get(profileConfig, this.props.step, {})

    let normalized = {
      ...values,
      resetAll: values.resetAll === true || values.resetAll === 'true',
      buildMuscle: values.buildMuscle === true || values.buildMuscle === 'true',
      activityLevel: parseInt(values.activityLevel, 10),
      restrictions: compact(values.restrictions),
      dislikeIds: values.dislikes.map(d => d.id),
      weightChange: values.weightGoal === 'maintain' ? null : values.weightChange,
    }

    let numerics = ['bodyFatPct', 'weightChange']
    numerics.forEach(v => {
      if (!(values[v] && parseFloat(values[v]) > 0.0)) {
        delete normalized[v]
      }
    })

    let result = {}
    for (let f in config.fields) {
      if (normalized.hasOwnProperty(f)) {
        result[f] = normalized[f]
      }
    }

    console.log('normalized', result, normalized, config, config.fields)
    return result
  }

  handleSubmit = async (values, actions) => {
    console.log('ProfileWizard.onSubmit', { values, props: this.props, state: this.state })

    try {
      const result = await this.props.onSubmit(this.normalizeValues(values))
      // console.log('profile step done?', result)
      const success = get(result, 'data.saveProfile.success', false)
      if (!success) {
        const errors = transform(get(result, 'errors'))
        if (errors) {
          actions.setErrors(errors)
          if (errors.base) {
            this.setState({ error: errors.base.message })
          }
        }
      }
    } catch (err) {
      console.error('unable to submit data!', err)
    } finally {
      actions.setSubmitting(false)
    }
  }

  syncedRestrictions = (existing) => {
    const allDietaryRestrictions = get(this.props, 'systemConfig.dietaryRestrictions', []).sort(sortByLabel)
    // console.log('syncing', input, allDietaryRestrictions)
    return allDietaryRestrictions.map(item => existing.indexOf(item.key) > -1 ? item.key : false)
  }

  syncedNotificationPreferences = (existing) => {
    const notificationPreferences = get(this.props, 'systemConfig.notificationPreferences', []).sort(sortByLabel)

    console.log('syncing notificationPreferences', existing, notificationPreferences)
    return notificationPreferences.map(item => {
      const matchingItem = existing.find(it => it.key === item.key)
      return {
        email: true,
        push: true,
        ...item,
        ...matchingItem,
        __typename: undefined,
      }
    })
  }

  renderTabs = (selectedIndex = -1) => {
    const { classes, onGoTo } = this.props
    // FAKE TABS! the MUI Tabs component is glitchy and sometimes causing layout issues as if the element were floated out

    return (
      <div className={classes.tabs} data-tour="profile-tabs">
        {TAB_LIST.map((label, i) => (
          <FakeTab key={i} onClick={() => onGoTo(i)} label={label} selected={i === selectedIndex}
                   data-tour={`profile-tab-${label}`} />
        ))}
      </div>
    )
  }

  render () {
    const { classes, step, onPrevious, isLastStep, showTabs, profile: profileIn, systemConfig, onSearchFoods } = this.props
    // const {error} = this.state

    console.log('ProfileWizard.render', step, onSearchFoods, this.props, this.state)

    const dciOverride = get(profileIn, 'dciOverride')
    const pctOverride = get(profileIn, 'pctOverride')

    const showOverrideWarning = ['demo', 'goals', 'activity'].includes(step) && (dciOverride || pctOverride)
    const overrides = []
    if (dciOverride) {
      overrides.push('DCI value')
    }
    if (pctOverride) {
      overrides.push('nutrient target values')
    }
    const overridden = compact(overrides).join(' and ')

    // console.log('showing override warning', showOverrideWarning)
    const config = get(profileConfig, step, {})
    const stepClass = config.comp
    const stepIndex = config.step - 1 || 0
    // console.log('stepIndex', stepIndex, config)
    const profile = {
      resetAll: 'false',
      buildMuscle: false,
      dislikes: [],
      ...profileIn,
      restrictions: this.syncedRestrictions(get(profileIn, 'restrictions', [])),
      notificationPreferences: this.syncedNotificationPreferences(get(profileIn, 'notificationPreferences', [])),
    }
    console.log('profile in', profile)

    // const smallScreen = window.innerWidth < 500
    /**
     * evaluating if a field has been touched:
     * - value || (
     * - no value && !isValid
     * - no value && touched )
     */
    return (

      <Formik
        onSubmit={this.handleSubmit}
        isInitialValid={false}
        initialValues={profile}
        enableReinitialize
        validateOnChange={true}
        validationSchema={config.schema}>
        {({ values, errors, touched, isSubmitting, isValid, handleSubmit, onSubmit, handleChange, handleBlur }) => {

          // console.log('profile wizard form', values, errors, touched, isSubmitting, isValid)
          // console.log('isValid, isSubmitting', isValid, isSubmitting)
          const validationProps = name => {
            const hasError = Boolean(getIn(errors, name))
            const hasValue = getIn(values, name) !== undefined
            // const isTouched = getIn(touched, name)
            // console.log('validating', name, hasError, isTouched, hasValue, hasValue || (!isValid && !isTouched))
            return {
              touched: hasValue || hasError,
              error: getIn(errors, name),
            }
          }
          const changeProps = { onChange: handleChange, onBlur: handleBlur }

          const subForm = stepClass &&
            React.createElement(stepClass,
              { showTabs, classes, values, handleChange, systemConfig, changeProps, validationProps, onSearchFoods })
          const hasForm = Boolean(subForm)
          return (

            <Form className={classes.container}>
              <PageTitle noIcon title="Profile Setup" />

              <div className={classes.content}>

                {showTabs && this.renderTabs(stepIndex)}

                {hasForm ? subForm : 'Invalid Request'}

                {showOverrideWarning && (
                  <FormGroup>

                    <p className={classnames(classes.error, classes.overrideWarning)}>
                      WARNING! Some of our calculations are dependent on these inputs, but you
                      have overridden the calculated {overridden}. Do you want to keep
                      your explicit values, or allow the system to recalculate based on your
                      new inputs?
                    </p>

                    <RadioGroup name="resetAll" label={false} value={values.resetAll} horizontal
                                onChange={handleChange} {...validationProps('resetAll')}>
                      <Option label="Keep my values" value="false" onChange={handleChange} />
                      <Option label="Reset to calculated values" value="true" onChange={handleChange} />
                    </RadioGroup>

                  </FormGroup>
                )}

                <FormGroup>
                  <p className={classes.error}>{this.state.error}</p>

                  {hasForm && (
                    <ActionBar>
                      {Boolean(showTabs) ||
                      <Button type="submit" onClick={onPrevious} disabled={!onPrevious || isSubmitting}
                              color="secondary"
                              variant="outlined"> Back </Button>}
                      <Button type="submit" onClick={handleSubmit} disabled={isSubmitting} color="primary"
                              variant="contained"> {showTabs || isLastStep ? 'Save' : 'Next'} </Button>
                      <ButtonLink variant="outlined" to="/"> Cancel </ButtonLink>
                    </ActionBar>
                  )}
                </FormGroup>

              </div>
            </Form>
          )
        }}
      </Formik>
    )
  }
}

export default withStyles(styles)(ProfileWizard)
