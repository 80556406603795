// @flow
// import firebase from 'react-native-firebase'
import get from 'lodash/get'
//
// const uuidv4 = require('uuid/v4')
//
export const CHANNEL = 'bod-channel'
//
// export const messaging = firebase.messaging

export function getMessaging (): Object {
  const messaging = get(window, 'cordova.plugins.firebase.messaging')
  if (messaging) {
    return messaging
  }
  return {}
}

export function init () {
  // console.log('notifications.init', window.FCMPlugin, window.FCMPluginNG)

  const messaging = get(window, 'cordova.plugins.firebase.messaging')
  if (messaging) {
    console.log('using FCM messaging plugin', messaging)
  }

  if (window.FCMPluginNG) {
    window.FCMPluginNG.getToken(function (token) {
      console.info('token', token)
    })
  }

  if (window.FCMPlugin) {
    window.FCMPlugin.getToken(function (token) {
      //this is the FCM token which can be used
      //to send notification to specific device
      // console.log(token)
      //window.FCMPlugin.onNotification( onNotificationCallback(data), successCallback(msg), errorCallback(err) )
      //Here you define your application behaviour based on the notification data.
      window.FCMPlugin.onNotification(function (data) {
        // console.log(data)
        //data.wasTapped == true means in Background :  Notification was received on device tray and tapped by the user.
        //data.wasTapped == false means in foreground :  Notification was received in foreground. Maybe the user needs to be notified.
        if (data.wasTapped) {
          //Notification was received on device tray and tapped by the user.
          alert(JSON.stringify(data))
        } else {
          //Notification was received in foreground. Maybe the user needs to be notified.
          alert(JSON.stringify(data))
        }
      })
    })

  }

  initChannel()
}

//
export const initChannel = async () => {
  // console.log('trying to setup channel for android ....')
  // A channel is required for Android 8 (API 26):
//   const channel = new firebase.notifications.Android.Channel(CHANNEL, 'Queful',
//     firebase.notifications.Android.Importance.Max)
//   channel.setDescription('Queful notifications channel')
//
// // Create the channel
//   firebase.notifications().android.createChannel(channel)
}
//
export const getToken = () => {
  return new Promise((resolve, reject) => {
    getMessaging().getToken()
      .then(function (token) {
        // console.log('Got device token: ', token)
        resolve(token)
      })
      .catch(function (err) {
        console.log(err)
        reject(err)
      })
  })
}
//
// export const hasPermission = async () => {
//   return await firebase.messaging().hasPermission()
// }
//
export const requestPermission = async () => {
  // user doesn't have permission
  // console.log('requesting user permission ... ')
  return new Promise((resolve, reject) => {
    try {

      getMessaging().requestPermission({ forceShow: true }).then(function () {
        // console.log('user granted permission!')
        resolve(getToken())
      })

      // User has authorised

    } catch (error) {
      // console.log('user denied permission!', error)
      reject(error)
      // User has rejected permissions
    }
  })
}
//
// //
// // const enabled = await firebase.messaging().hasPermission()
// // if (enabled) {
// //   console.log('user already provided permission')
// //   // user has permissions
// //
// //   this.setupListeners()
// //
// // } else {
// //   // user doesn't have permission
// //   console.log('no user permission ... asking')
// //   try {
// //     await firebase.messaging().requestPermission()
// //     console.log('user granted permission!')
// //     // User has authorised
// //   } catch (error) {
// //     console.log('user denied permission!', error)
// //     // User has rejected permissions
// //   }
// // }
// // }
//
// export const send = (title, body, data, id = uuidv4()) => {
//
//   // console.log('skipping notification', title, body)
//   // return null;
//
//   initChannel()
//
//   const notif = new firebase.notifications.Notification().
//     setNotificationId(id).
//     setTitle(title).
//     setBody(body).
//     android.setChannelId(CHANNEL).
//     android.setSmallIcon('queful').
//     setData(data)
//
//   console.log('notif', notif)
//   firebase.notifications().displayNotification(notif)
// }
