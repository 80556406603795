// @flow
import React from 'react'
import ReactGA from 'react-ga'

import get from 'lodash/get'

import { IS_DEV } from '@balance/lib/config/constants'

type Props = {}
type State = {
  currentPage: string,
}

const DEBUG = false

class Analytics extends React.PureComponent<Props, State> {
  static defaultProps = {}
  state = {
    currentPage: get(this.props, 'location.pathname'),
  }

  componentDidMount () {
    if (DEBUG || !IS_DEV) {
      ReactGA.initialize('UA-132742198-2') //Unique Google Analytics tracking number
    }
  }

  componentDidUpdate = () => {
    if (DEBUG || !IS_DEV) {
      const cur = get(this.state, 'location.pathname')
      const next = get(this.props, 'location.pathname')
      // console.log('Analytics.cWRP', cur, next)
      if (cur !== next) {
        ReactGA.pageview(next)
        this.setState({currentPage: next})
      }
    }
  }

  render () {
    return null
  }
}

export default Analytics
