// @flow
import React, { PureComponent } from 'react'
import get from 'lodash/get'

import { Formik, getIn } from 'formik'

import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

import { recipeEditSchema } from '@balance/lib/validations'

import PageTitle from '../PageTitle/PageTitle'
import baseStyles from '../../styles/forms'

import RecipeForm from './RecipeForm'

type Props = {
  initialRecipe: Object,
  searchFoods: Function,
  saveRecipe: Function,
}
type State = {
  recipe: Object,
  query: String,
  querySource: String,
  searching: boolean,
  suggestions: Array<Object>,
}

const styles = theme => {
  const base = baseStyles(theme)
  return {
    ...base,
    root: {
      height: 250,
      flexGrow: 1,
    },
    wrapper: {
      ...base.wrapper,
      borderTop: '1px solid #ccc',
    },
    card: {
      overflow: 'visible',
    },
    suggestionContainer: {
      position: 'relative',
      margin: [[theme.spacing.unit * 2, 0]],
      // padding: [[theme.spacing.unit * 2, theme.spacing.unit * 3]],
    },
    suggestionsContainerOpen: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing.unit,
      left: 0,
      right: 0,
    },
    suggestion: {
      display: 'block',
    },
    suggestionsList: {
      margin: 0,
      padding: 0,
      listStyleType: 'none',
    },
    divider: {
      height: theme.spacing.unit * 2,
    },
  }
}

class RecipeEdit extends PureComponent<Props, State> {
  static defaultProps = {}
  state = {
    recipe: this.props.initialRecipe,
  }

  componentDidUpdate (prevProps): void {
    // console.log('RecipeEdit.cDU', prevProps, this.props)
    const recipeIn = this.props.initialRecipe
    if (recipeIn !== prevProps.initialRecipe) {
      // console.log('updating recipe state.... ')
      this.setState({
        recipe: recipeIn,
      })
    }
  }

  // handleRecipeChange = ({ target }) => {
  //   console.log('handleRecipeChange', target.name, target.type, target.value, this.state.recipe)
  //   let value = target.value
  //   if (target.type === 'checkbox') {
  //     value = !!target.checked
  //   }
  //   this.setState(prevState => ({
  //     recipe: {
  //       ...prevState.recipe,
  //       [target.name]: value,
  //     },
  //   }))
  // }

  // updateRecipeState = (keyPath, value) => {
  //   this.setState(prevState => {
  //     const oldRecipe = prevState.recipe
  //     const recipe = set(oldRecipe, keyPath, value)
  //     console.log('updateRecipeState', oldRecipe, recipe)
  //     return { recipe }
  //   })
  // }

  handleSubmit = async (values) => {
    // console.log('handleSubmit', values, this.state)
    const recipe = values
    const result = await this.props.saveRecipe({
      id: recipe.id,
      type: recipe.type,
      servingSize: recipe.servingSize,
      public: recipe.public,
      dietNames: recipe.dietNames,
      instructions: recipe.instructions,
      clone: !!recipe.clone,
      // note: don't auto-spread the food since there are fields on the food that don't apply directly to the ingredient
      ingredients: recipe.ingredients.map(food => ({
        id: food.id,
        brand: food.brand,
        fixed: food.fixed,
        foodId: food.foodId,
        apiRef: food.apiRef,
        qty: food.qty,
        unit: food.unit,
        foodFixed: food.foodFixed,
        fixedQty: food.fixedQty,
        fixedUnit: food.fixedUnit,
        goesWithId: food.goesWithId,
      })),
    })
    // console.log('result', result)
    return result // no-op?
  }

  render () {
    const { classes } = this.props
    // console.log('RecipeEdit.render', this.props, this.state)
    const { recipe } = this.state
    // console.log('recipe render', recipe.type)

    const isAdmin = 'admin' === get(this.props, 'user.role')

    const initial = {
      ...recipe,
      servingSize: 1,
      ingredients: recipe.ingredients || [],
    }
    const pageTitle = recipe && recipe.id ? 'Edit Recipe' : 'New Recipe'

    return (
      <div className={classes.container}>
        <PageTitle title={pageTitle} noIcon showHelp />
        <Card className={classes.card}>
          <CardContent>
            <Formik
              onSubmit={this.handleSubmit}
              isInitialValid={true}
              initialValues={initial}
              enableReinitialize
              validateOnChange={true}
              validationSchema={recipeEditSchema}>
              {({ values, errors, touched, isSubmitting, isValid, handleSubmit, onSubmit, handleChange, handleBlur, setFieldValue, ...rest }) => {

                // console.log('recipe edit form', values, errors, touched, isSubmitting, isValid)

                const validationProps = name => {
                  const hasError = Boolean(getIn(errors, name))
                  const hasValue = getIn(values, name) !== undefined
                  // const isTouched = getIn(touched, name)
                  // console.log('validating', name, hasError, isTouched, hasValue, hasValue || (!isValid && !isTouched))
                  return {
                    touched: hasValue || hasError,
                    error: getIn(errors, name),
                  }
                }

                const handleAddIngredient = (item) => {
                  // console.log('handleAddIngredient', item)

                  const list = [...values.ingredients, item]
                  setFieldValue(`ingredients`, list)
                  setFieldValue('clone', recipe.mealCount > 0)
                }

                const handleUpdateIngredient = (item) => {
                  // console.log('handleUpdateIngredient', item)
                  const index = values.ingredients.findIndex((ingr) => ingr.id === item.id)
                  // console.log('index of', index)
                  setFieldValue(`ingredients.${index}`, item)
                }

                const handleRemoveIngredient = (item) => {
                  // console.log('handleRemoveIngredient', item)
                  // const ingr = values.ingredients.find((i) => i.id === ingr.id)
                  const list = values.ingredients.filter((ingr) => ingr.id !== item.id)
                  const linksRemoved = list.map(ingr =>
                    ingr.goesWithId && ingr.goesWithId === item.id ? { ...ingr, goesWithId: null } : ingr)
                  setFieldValue(`ingredients`, linksRemoved)
                  setFieldValue('clone', recipe.mealCount > 0)
                }

                return <RecipeForm recipe={values} isAdmin={isAdmin}
                                   searchFoods={this.props.searchFoods}
                                   errors={errors} onChange={handleChange} onBlur={handleBlur}
                                   validationProps={validationProps}
                                   onAddIngredient={handleAddIngredient}
                                   onUpdateIngredient={handleUpdateIngredient}
                                   onRemoveIngredient={handleRemoveIngredient}
                                   isValid={isValid} isSubmitting={isSubmitting} />
              }}
            </Formik>
          </CardContent>
        </Card>
      </div>
    )
  }
}

export default withStyles(styles)(RecipeEdit)
