import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'

import { CurrentUserConsumer } from '@balance/lib/contexts'
import { isComplete } from '@balance/lib/api/profile'
import { GET_GROCERY_LIST } from '@balance/lib/api/meal-plan'

import { GroceryList } from '../../components/Meals'
import ProfileNotComplete from '../../components/Profile/ProfileNotComplete'

class GroceryListContainer extends Component {

  state = {
    error: '',
  }

  render () {
    let week = get(this.props, 'location.search', '').replace('?week=', '')
    // console.log('week', week, this.props)
    if (!['last', 'this', 'next'].includes(week)) { week = '' }

    return (
      <CurrentUserConsumer>
        {({ state: user }) => {
          // console.log('cu', user)

          const profile = get(user, 'profile', {})
          if (!isComplete(profile)) {
            return <ProfileNotComplete profile={profile} />
          }

          return (
            <Query query={GET_GROCERY_LIST} variables={{ week }}>
              {({ data, loading, props }) => {
                // console.log('grocery list container props', loading, data, props)

                const listData = get(data, 'groceryList', {})
                const items = get(listData, 'items', [])

                return (
                  <GroceryList {...listData} items={items} loading={loading} user={user} week={week}  />
                )
              }}
            </Query>
          )
        }}
      </CurrentUserConsumer>
    )
  }
}

export default withRouter(GroceryListContainer)
