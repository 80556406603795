"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SAVE_PROFILE = exports.PROFILE_FRAGMENT = exports.hasMealSettings = exports.hasProfile = exports.isComplete = void 0;

var _graphqlTag = _interopRequireDefault(require("graphql-tag"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    mutation saveProfile($attributes: ProfileInput!) {\n        saveProfile(attributes: $attributes) {\n            success\n            profile {\n                ...ProfileDetail\n            }\n        }\n    }\n    ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n    fragment ProfileDetail on Profile {\n        id\n        __typename\n        firstName\n        lastName\n        email\n        timezone\n        gender\n        age\n        weightLb\n        heightIn\n        weightGoal\n        weightChange\n        buildMuscle\n        bodyFatPct\n        activityLevel\n        restrictions\n        dislikeIds\n        dislikes {\n            id\n            name\n        }\n        notificationPreferences {\n            key\n            label\n            email\n            push\n        }\n        mealDays\n        mealVariety\n        mealSplit\n        mealPlanStart\n        mealCount\n        snackCount\n        totalMealCount\n        hasMealPlans\n        bmr\n        dci\n        dciOverride\n        dciDefault\n        proteinPct\n        fatPct\n        carbsPct\n        pctOverride\n        proteinPctDefault\n        fatPctDefault\n        carbsPctDefault\n        nutrientTargetPct {\n            nutrient\n            target\n        }\n        user {\n            id\n        }\n    }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

var isComplete = function isComplete(profile) {
  return hasProfile(profile) && hasMealSettings(profile);
};

exports.isComplete = isComplete;

var hasProfile = function hasProfile(profile) {
  return Boolean(profile) && profile.weightGoal && profile.activityLevel && profile.bmr && profile.dci && profile.bmr > 0 && profile.dci > 0;
};

exports.hasProfile = hasProfile;

var hasMealSettings = function hasMealSettings(profile) {
  return Boolean(profile) && profile.mealDays;
};

exports.hasMealSettings = hasMealSettings;
var PROFILE_FRAGMENT = (0, _graphqlTag.default)(_templateObject());
exports.PROFILE_FRAGMENT = PROFILE_FRAGMENT;
var SAVE_PROFILE = (0, _graphqlTag.default)(_templateObject2(), PROFILE_FRAGMENT);
exports.SAVE_PROFILE = SAVE_PROFILE;