// @flow

import React, { Component } from 'react'
import get from 'lodash/get'
import { withStyles } from '@material-ui/core/styles'

import { IS_DEV } from '@balance/lib/config/constants'

import styles from '../../styles/forms'
import { colors } from '../../styles'
import { ButtonLink } from '../Button'
import { ActionBar } from '../Form'
import PageTitle from '../PageTitle/PageTitle'

type Props = {
  onLoad: Function,
  redirect: Function,
  error?: ?Object,
}

type State = {
  email: String,
}

export class ConfirmAccount extends Component<Props, State> {
  state = {
    email: IS_DEV ? 'greg@bod.com' : '',
    error: this.props.error || '',
  }

  async componentDidMount (): void {
    // console.log('ConfirmAccount.cDM')
    const result = await this.props.onLoad()
    // console.log('result', result)
    const success = get(result, 'data.confirmAccount.success', false)
    // console.log('success?', success)
    if (success && this.props.redirect) {
      this.props.redirect()
    } else {
      const message = get(result, 'errors.0.message', false)
      // console.log('error message', message)
      this.setState({error: message})
    }
  }

  render () {
    // console.log('ConfirmAccount.render', this.state)
    const {classes} = this.props
    const {error} = this.state

    return (
      <div>

        <div className={classes.container}>
          <PageTitle noIcon title="Confirming Account ..." />

          <div className={classes.content}>
            <p style={{color: colors.danger}}>{error}</p>

            <ActionBar>
              <ButtonLink primary to="/login">Sign in</ButtonLink>
            </ActionBar>

          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(ConfirmAccount)
