import React, { Component } from 'react'

export class HideErrorBoundary extends Component {
  constructor (props) {
    super(props)
    this.state = {hasError: false}
  }

  componentDidCatch (error, info) {
    // Display fallback UI
//console.log('componentDidCatch', error, info)
    this.setState({hasError: true})
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info)
  }

  render () {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <div></div>
    }
    return this.props.children
  }
}

export default HideErrorBoundary
