// @flow
import React, { cloneElement } from 'react'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import classnames from 'classnames'

type Props = {
  classes: Object,
  title?: string,
  children: any,
  icon?: any,
  noIcon: boolean,
  aside?: string,
  asideClass?: Object,
}

const styles = theme => ({
  title: {
    margin: [[theme.spacing.unit * 2, 0]],
    lineHeight: '40px', // to match icon size
  },
  group: {
    marginBottom: theme.spacing.unit * 2,
  },
  firstCol: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    textAlign: 'center',
  },
  iconCol: {
    marginTop: theme.spacing.unit * 2,
  },
})

export const MultiField = ({children}: Object) => {
  const count = React.Children.count(children)
  if (count === 1) {
    return children
  }
  const size = count === 2 ? 6 : 12 // flow concession, for now
  return (
    <Grid container spacing={16}>
      {React.Children.map(children, (child, i) => {
        return (
          <Grid item xs={size}>{child}</Grid>
        )
      })}
    </Grid>
  )
}

export const FormGroup = ({children, icon, noIcon, aside, asideClass, title, classes, ...props}: Props) => {
  const mainColSize = noIcon ? 12 : 10
  // console.log('form group children', children)
  return (
    <Grid container className={classes.group}>
      <React.Fragment>
        {noIcon || (
          <Grid item xs={2} className={classnames(classes.firstCol, asideClass)}>
            {aside && (
              <div>{aside}</div>
            )}
            {icon && (
              <div className={classes.iconCol}>
                {cloneElement(icon, {color: 'inherit', style: {fontSize: 40}})}
              </div>
            )}
          </Grid>
        )}
      </React.Fragment>
      <Grid item xs={mainColSize}>
        <React.Fragment>
          {title && (
            <Typography variant="h4" color="primary" className={classes.title}>
              {title || 'Page Title'}
            </Typography>
          )}
          {children}
        </React.Fragment>
      </Grid>
    </Grid>
  )
}

FormGroup.defaultProps = {
  asideClass: {},
}
export default withStyles(styles)(FormGroup)
