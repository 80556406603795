import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import { FormField } from '../Form'

import { IS_DEV } from '@balance/lib/config/constants'

import { Button, ButtonLink } from '../Button'

import styles from '../../styles/forms'
import { colors } from '../../styles'
import { Visibility, VisibilityOff } from '../../styles/icons'

import PageTitle from '../PageTitle/PageTitle'

export class SignIn extends Component {
  state = {
    name: '',
    email: IS_DEV ? 'ghaygood@gmail.com' : '',
    password: IS_DEV ? '123123' : '',
    showPassword: false,
    error: this.props.error || '',
    useEnvironment: 'production',
  }

  allowEnvToggle = () => { return !!(IS_DEV && window.cordova && window.NativeStorage) }

  componentDidMount () {
    if (this.allowEnvToggle()) {
      window.NativeStorage.getItem('use_environment', this.updateEnvironment)
    }
  }

  updateEnvironment = (data) => {
    // console.log('updateEnvironment: got data!', data)
    if (data && ['production', 'staging', 'dev'].includes(data)) {
      this.setState({ useEnvironment: data })
    }
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }

  handleMouseDownPassword = event => {
    event.preventDefault()
  }

  handleChangeEnvironment = (e, isChecked) => {
    const { value } = e.target
    // console.log('change environment', e, value, isChecked)
    if (this.allowEnvToggle()) {
      window.NativeStorage.setItem('use_environment', isChecked ? value : 'production', this.reload)
    }
  }

  reload = (e) => {
    // console.log('reload?', e)
    setTimeout(() => {window.location.reload()}, 200) // wait for save
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.currentTarget.value.trim(),
      error: '',
    })
  }

  render () {
    // console.log('SignIn.render', API_URL, getApiUrl(), IS_DEV)
    const { classes, error } = this.props
    const { useEnvironment } = this.state

    return (
      <div className={classes.container}>
        <PageTitle noIcon title='Sign In' />
        <form noValidate autoComplete="off" onSubmit={this._login}>
          <div className='flex flex-column'>
            <TextField
              id="email"
              label="Email"
              type="email"
              className={classes.textField}
              value={this.state.email}
              onChange={this.handleChange('email')}
              margin="normal"
              fullWidth
            />
            <FormControl className={classNames(classes.margin, classes.textField)}>
              <InputLabel htmlFor="adornment-password">Password</InputLabel>
              <Input
                id="adornment-password"
                type={this.state.showPassword ? 'text' : 'password'}
                value={this.state.password}
                onChange={this.handleChange('password')}
                fullWidth
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
          <div>
            <p style={{ color: colors.danger }}>{error}</p>
          </div>
          <div className="">
            <Button variant="contained" color="primary" className={classes.button} type="submit"
                    onClick={this._login}>
              Login
            </Button>

            <ButtonLink variant="contained" className={classes.button} to="/signup">
              Want to create an account?
            </ButtonLink>

          </div>
          <div>

            <Link className={classes.link} to="/account/help">Having trouble signing in ?</Link><br />

            {/*cordova: {!!window.cordova ? 'yes' : 'no'}<br />*/}
            {/*nativestorage: {!!window.NativeStorage ? 'yes' : 'no'}<br />*/}
            {/*allowEnvToggle: {!!this.allowEnvToggle() ? 'yes' : 'no'}<br />*/}
            {/*useEnvironment: {useEnvironment}<br />*/}
          </div>
          {this.allowEnvToggle() && (
            <div>
              Use Environment:<br/>
              <FormField radio name='use_environment' label="Production"
                         value="production" checked={useEnvironment === 'production'}
                         onChange={this.handleChangeEnvironment} />
              <FormField radio name='use_environment' label="Staging"
                         value="staging" checked={useEnvironment === 'staging'}
                         onChange={this.handleChangeEnvironment} />
              <FormField radio name='use_environment' label="Dev"
                         value="dev" checked={useEnvironment === 'dev'}
                         onChange={this.handleChangeEnvironment} />
            </div>
          )}
        </form>
      </div>
    )
  }

  _login = async (event) => {
    event.stopPropagation()
    event.preventDefault()

    // console.log('_login start')
    const { email, password } = this.state
    await this.props.onLogin({ email, password })
    // console.log('_login result', result)
  }
}

export default withStyles(styles)(SignIn)
