// @flow
import React, { PureComponent } from 'react'
import classnames from 'classnames'

import { withStyles } from '@material-ui/core/styles'
import Radio from '@material-ui/core/Radio'
import MuiRadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'

import formStyles from '../../styles/forms'

type Props = {
  classes: Object,
  required: boolean,
  horizontal: boolean,
  name: string,
  label: string,
  value: string | number | null,
  children: Array<Function>,
  onChange: Function,
  error: string,
  touched: boolean,
}
type State = {
  value: string,
}

const styles = theme => ({
  ...formStyles(theme),
  formControl: {
    display: 'flex',
    flexDirection: 'column',
  },
  horizontal: {
    flexDirection: 'row',
  },
  group: {},
})

export const Option = ({label, value, checked, description, name, ...props}: Object) => {
  // console.log('Option', name, label, value, checked)
  const fullLabel = description
    ? <span>{label} &mdash; <em>{description}</em></span>
    : label

  return (
    <FormControlLabel checked={checked} value={value} control={<Radio />}
                      label={fullLabel} name={name} {...props} />
  )
}

export class RadioGroup extends PureComponent<Props, State> {
  static defaultProps = {
    classes: {},
    required: false,
    horizontal: false,
  }

  state = {
    value: this.props.value || '',
  }

  UNSAFE_componentWillReceiveProps (nextProps): void {
    this.setState({value: nextProps.value})
  }

  // componentDidMount (): void {
  //   this.props.onChange({value: this.props.value, name: this.props.name})
  // }

  handleChange = (event: SyntheticEvent<HTMLInputElement>, checked) => {
    // console.log('radiogroup change', checked, event.target, event.target.value)
    this.setState({value: event.target.value})
    this.props.onChange && this.props.onChange(event.target.value)
  }

  render () {
    const {classes, required, name, label, children, horizontal, error, touched} = this.props
    const hClass = horizontal ? classes.horizontal : null
    // console.log('RadioGroup.render', this.props, this.state)
    return (
      <FormControl component="fieldset" required={required} className={classes.formControl}>
        <FormLabel component="legend">{label}</FormLabel>
        <MuiRadioGroup
          aria-label={label}
          name={name}
          row={horizontal}
          className={classnames(classes.group, hClass)}
          value={this.state.value}
          onChange={this.handleChange}
        >
          {children}
        </MuiRadioGroup>
        {touched && error && (
          <div className={classes.error}>{error}</div>
        )}
      </FormControl>
    )
  }
}

export default withStyles(styles)(RadioGroup)
