// @flow
import React, { PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import classNames from 'classnames'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import { Edit, Delete, MoreVertIcon, Search } from '../../styles/icons'

type Props = {
  classes: Object,
  children: any,
  label: string,
  value: string,
  half: boolean,
  shaded: boolean,
  searchable: boolean,
  editable: boolean,
  onSearch: Function,
  onEdit: Function,
  onDelete: Function,
  disableUnderline: boolean,
  menu?: Array<Object>,
}

type State = {
  menuOpen: boolean,
  anchorEl: ?any,
}

const styles = theme => ({
  wrapper: {
    paddingRight: 4 * theme.spacing.unit,
  },
  textField: {
    // backgroundColor: '#eee',
    // margin: theme.spacing.unit,
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    // marginRight: 2 * theme.spacing.unit,
    // width: '95%',
    // paddingRight: theme.spacing.unit,
    // maxWidth: 300,
  },
  shaded: {
    backgroundColor: '#eee',
  },
  inputField: {
    padding: [[0, theme.spacing.unit * 2, theme.spacing.unit]],
    color: [theme.content.color, '!important'],
    '&:before': {
      borderBottomStyle: ['solid', '!important'],
      borderBottomWidth: ['2px', '!important'],
    },
  },
  inputLabel: {
    left: 4,
    color: ['#9E9E9E', '!important'],
  },
  helperText: {
    marginTop: 0,
    padding: [[theme.spacing.unit, theme.spacing.unit * 2]],
    backgroundColor: theme.palette.background.default,
  },
  menuItem: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& $primary, & $icon': {
        color: theme.palette.common.white,
      },
    },
  },
  primary: {},
  icon: {},
})

export class DisplayField extends PureComponent<Props, State> {
  static defaultProps = {
    disableUnderline: false,
  }
  state = {
    anchorEl: null,
    menuOpen: false,
  }

  handleClick = (event: SyntheticEvent<HTMLElement>) => {
    this.setState({menuOpen: true, anchorEl: event.currentTarget})
  }

  handleClose = () => {
    this.setState({menuOpen: false, anchorEl: null})
  }

  handleEdit = () => {
    this.handleClose()
    this.props.onEdit && this.props.onEdit()
  }

  handleDelete = () => {
    this.handleClose()
    this.props.onDelete && this.props.onDelete()
  }

  handleAction = (onClick) => {
    // console.log('handleAction', onClick)
    this.handleClose()
    onClick && onClick()
  }

  customMenu = () => {
    return this.renderMenuAdornment('custom-menu', this.props.menu)
  }

  editMenu = () => {
    const {editable, onDelete} = this.props

    if (!editable) {
      return null
    }

    if (!onDelete) {
      return (
        <InputAdornment position="end">
          <IconButton onClick={this.handleEdit}>
            <Edit />
          </IconButton>
        </InputAdornment>
      )
    }

    const menuOptions = [
      {
        label: 'Edit',
        icon: <Edit />,
        onClick: this.props.onEdit,
      },
      {
        label: 'Delete',
        icon: <Delete />,
        onClick: this.props.onDelete,
      },
    ]
    return this.renderMenuAdornment('edit-menu', menuOptions)
  }

  renderSearchAdornment = () => {
    return (
      <InputAdornment position="end">
        <IconButton
          aria-label="Open Search"
          aria-haspopup="true"
          onClick={this.openSearch}
        >
          <Search />
        </IconButton>
      </InputAdornment>
    )
  }

  renderMenuAdornment = (id, menuOptions) => {
    // console.log('renderMenuAdornment', id, menuOptions)
    const {classes} = this.props
    const {anchorEl} = this.state
    return (
      <InputAdornment position="end">
        <IconButton
          aria-label="Open menu"
          aria-owns={anchorEl ? id : null}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id={id}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {menuOptions.map((option, i) => (
              <MenuItem key={i} onClick={() => this.handleAction(option.onClick)}>
                {option.icon && (
                  <ListItemIcon className={classes.icon}>
                    {option.icon}
                  </ListItemIcon>
                )}
                <ListItemText classes={{primary: classes.primary}} inset={Boolean(option.icon)} primary={option.label} />
              </MenuItem>
            ),
          )}
        </Menu>
      </InputAdornment>
    )
  }

  render () {
    const {children, classes, label, value, menu, searchable, editable, shaded, touched, error, disableUnderline, onEdit, onDelete, half, ...props} = this.props
    // console.log('DisplayField.render', {label, value, touched, error, props})

    let inputProps = {disabled: true, className: classes.inputField, disableUnderline}
    let menuAdornment = null
    if (searchable) {
      inputProps = {
        ...inputProps,
        disabled: false,
        endAdornment: this.renderSearchAdornment(),
      }
    } else {
      if (menu && menu.length > 0) {
        menuAdornment = this.customMenu()
      } else if (editable) {
        menuAdornment = this.editMenu()
      }
      if (menuAdornment) {
        inputProps = {
          ...inputProps,
          endAdornment: menuAdornment,
        }
      }
    }

    const fallbackValue = searchable ? '' : ' '
    return (
      <TextField
        label={label || ''}
        error={!!error}
        helperText={!!error && error}
        fullWidth={true}
        value={value || fallbackValue}
        className={classNames(classes.textField, shaded && classes.shaded)}
        InputLabelProps={{className: classNames(classes.inputField, classes.inputLabel)}}
        FormHelperTextProps={{className: classes.helperText}}
        InputProps={inputProps}
        {...props}
      >
        {children}
      </TextField>
    )
  }
}

export default withStyles(styles)(DisplayField)
