// @flow
import React, { Fragment, PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'

import classnames from 'classnames'
import { asPct } from '@balance/lib/utils'

import { SearchField } from '../Form'
import { Add } from '../../styles/icons'
import { Button } from '../Button'

type Props = {
  onSelect: Function,
  onSearch: Function,
  nestedForm: boolean,
  showSecondaryInfo: boolean,
  initialResults: Array<Object>, // for testing
}
type State = {
  searching: boolean,
  searched: boolean,
  query: string,
  results: Array<Object>,
}

const styles = theme => ({
  search: {},
  form: {
    display: 'flex',
    alignItems: 'center',
  },
  note: {
    margin: [[theme.spacing.unit, 0, 0]],
    textAlign: 'right',
    fontSize: '0.75rem',
  },
  results: {
    margin: [[theme.spacing.unit / 2, 0]],
    minHeight: 300,
    maxHeight: '75vh',
    overflowY: 'scroll',

    '& ::-webkit-scrollbar': {
      width: 5,
      height: 5,
    },

    '& ::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 3px rgba(0,0,0,0.3)',
      borderRadius: 20,
    },

    '& ::-webkit-scrollbar-thumb': {
      borderRadius: 20,
      backgroundColor: 'rgba(0, 0, 0, .3)',
      '-webkit-box-shadow': 'inset 0 0 3px rgba(0,0,0,0.5)',
    },
  },
})

class IngredientSearch extends PureComponent<Props, State> {
  static defaultProps = {
    nestedForm: false,
    showSecondaryInfo: false,
  }
  state = {
    searching: false,
    searched: false,
    query: '',
    results: this.props.initialResults,
  }

  handleSearch = async (query) => {
    const { onSearch } = this.props
    console.log('handleSearch', query)
    this.setState({ search: true })
    const results = onSearch && await onSearch(query)
    this.setState({ search: false, searched: true, results })

    document.querySelector('.js-search-ingr-field').scrollIntoView({
      behavior: 'smooth',
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.handleSearch(this.state.query)
  }

  handleKeyPress = (e) => {
    console.log('IngrSearch.handleKeyPress', e, e.key, e.keyCode)
    if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
      this.handleSubmit(e)
    }
  }

  handleChange = (query) => {
    // console.log('handleChange', query)
    this.setState({ query })
  }

  handleAddIngredient = (ingredient) => {
    const { onSelect } = this.props
    console.log('handleAddIngredient', ingredient)
    onSelect && onSelect(ingredient)
  }

  renderResultInfo = (ingredient) => {
    if (this.props.showSecondaryInfo) {
      const p = asPct(ingredient.proteinPct, 0)
      const f = asPct(ingredient.fatPct, 0)
      const c = 100 - p - f // just to be sure total = 100
      return `Calories: ${ingredient.kcal} Protein: ${p}% Fat ${f}% Carbs: ${c}%`
    }
    return '';
  }

  renderResult = (ingredient) => {
    const resultInfo = this.renderResultInfo(ingredient)
    return (
      <ListItem key={ingredient.id}>
        <ListItemText secondary={resultInfo}>{ingredient.name}</ListItemText>
        <ListItemSecondaryAction>
          <IconButton title="Add Ingredient" onClick={() => this.handleAddIngredient(ingredient)}>
            <Add />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    )
  }

  renderNoResults = () => {
    return (
      <Typography component="p">
        No results matched!
      </Typography>
    )
  }

  renderResults = () => {
    const { classes } = this.props
    const { searched, results } = this.state

    if (!results || results.length === 0) {
      return searched ? this.renderNoResults() : null
    }
    // console.log('results', results)
    return (
      <Fragment>
        <Divider />
        <List className={classnames('result-list', classes.results)}>
          {results && results.map((result) => this.renderResult(result))}
        </List>
      </Fragment>
    )
  }

  renderInnerForm = () => {
    const { classes } = this.props
    const { searching, query } = this.state

    return (
      <React.Fragment>
        <div className={classnames('js-search-ingr-field', classes.form)}>
          <SearchField label="Search Ingredients" searching={searching}
                       onKeyPress={this.handleKeyPress}
                       onSearch={this.handleSearch} onChange={this.handleChange} />
          <Button size="large" onClick={() => this.handleSearch(query)}>Search</Button>
        </div>
        {this.renderResults()}
      </React.Fragment>
    )
  }

  render () {
    const { nestedForm } = this.props

    return (
      <div>
        {nestedForm ? (
          <form onSubmit={this.handleSubmit}>
            {this.renderInnerForm()}
          </form>
        ) : (
          <div>
            {this.renderInnerForm()}
          </div>
        )}
      </div>
    )
  }
}

export default withStyles(styles)(IngredientSearch)
