import React, { Component } from 'react'
import { Formik, Form, getIn } from 'formik'
import { withStyles } from '@material-ui/core'

import get from 'lodash/get'
import { transform } from '@balance/lib/api/errors'
import { trimAll } from '@balance/lib/utils'
import { DEFAULT_PROFILE } from '@balance/lib/data'
import { signupSchema } from '@balance/lib/validations'
import { colors } from '@balance/lib/styles'

import { detectTimeZone } from '../../lib/timezone'
import { FormField, FormGroup, ActionBar, TimeZoneField } from '../Form'
import { ButtonLink, Button } from '../Button'
import PageTitle from '../PageTitle/PageTitle'
import { AccountCircle, Email, Lock } from '../../styles/icons'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    // maxWidth: 500,
    padding: 20,
    margin: '0 auto',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '100%',
    // flexBasis: 200,
  },
  button: {
    margin: theme.spacing.unit,
  },
  menu: {
    width: 200,
  },
  link: {
    display: 'inline-block',
    color: colors.primary,
    margin: '1rem 10px 0',
    textDecoration: 'none',
  },

})

// const IS_DEV = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

export class SignUp extends Component {
  state = {
    login: true, // switch between SignUp and SignUp
    name: '',
    email: '',
    password: '',
    timezone: '',
    showPassword: false,
    error: this.props.error || '',
  }

  componentDidMount (): void {
    this.setState({ timezone: detectTimeZone() })
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }

  handleMouseDownPassword = event => {
    event.preventDefault()
  }

  handleChange = name => event => {
    // console.log('handleChange', name, event)
    this.setState({
      [name]: event.currentTarget.value,
      error: '',
    })
  }

  handleSubmit = async (values, actions) => {
    // console.log('SignUp.onSubmit', { values, props: this.props, state: this.state })
    const { email, password } = values
    if (!email || !password) {
      this.setState({
        error: 'Insufficient credentials',
      })
      // console.log('invalid creds!')
      return
    }

    try {
      const result = await this.props.onSubmit({ ...trimAll(values) })
      // console.log('signup done?', { result })
      const userId = get(result, 'data.signup.id')
      // console.log('userId', userId)
      if (!userId) {
        // console.log('redirecting to home ...', this.props.history)
        // this.props.history.push('/signup-complete', { reloadUser: true, loggedIn: true })
        // window.location.replace('/')
      // } else {
        const errors = transform(get(result, 'errors'))
        if (errors) {
          actions.setErrors(errors)
        }
      }
    } catch (err) {
      console.error('unable to submit data!', err)
    } finally {
      actions.setSubmitting(false)
    }
  }

  render () {
    const { classes, timeZones } = this.props
    // const {error} = this.state

    // console.log('SignUp.render', this.state)

    const email = ''
    const profile = {
      ...DEFAULT_PROFILE,
      firstName: '',
      lastName: '',
      email: email,
      password: '',
      passwordConfirmation: '',
      timezone: this.state.timezone,
      // firstName: 'Greg',
      // lastName: 'Jones',
      // email: email,
      // emailConfirmation: email,
      // password: '123123',
      // passwordConfirmation: '123123',
    }
    return (

      <Formik
        onSubmit={this.handleSubmit}
        isInitialValid={false}
        initialValues={profile}
        enableReinitialize
        validateOnChange={true}
        validationSchema={signupSchema}>
        {({ values, errors, touched, isSubmitting, isValid, handleSubmit, onSubmit, handleChange, handleBlur }) => {

          // console.log('sign up form', values, errors, touched, isSubmitting, isValid)
          const validationProps = name => { return { touched: getIn(touched, name), error: getIn(errors, name) } }
          const changeProps = { onChange: handleChange, onBlur: handleBlur }

          return (

            <div>

              <Form className={classes.container}>
                <PageTitle noIcon title="Sign Up" />

                <div className={classes.content}>

                  <FormGroup icon={<AccountCircle />}>

                    <FormField name="firstName" label="First Name" value={values.firstName}
                               {...changeProps} {...validationProps('firstName')} />
                    <FormField name="lastName" label="Last Name" value={values.lastName}
                               {...changeProps} {...validationProps('lastName')} />

                  </FormGroup>


                  <FormGroup icon={<Email />}>


                    <FormField name="email" label="Email" value={values.email}
                               type="email"
                               onChange={this.handleChange}
                               {...changeProps} {...validationProps('email')}
                    />

                  </FormGroup>

                  <FormGroup icon={<Lock />}>

                    <FormField name="password" label="Password"
                               value={values.password}
                               type={this.state.showPassword ? 'text' : 'password'}
                               onChange={this.handleChange}
                               {...changeProps} {...validationProps('password')}
                    />

                    <FormField name="passwordConfirmation" label="Confirm Password"
                               value={values.passwordConfirmation}
                               type={this.state.showPassword ? 'text' : 'password'}
                               onChange={this.handleChange}
                               {...changeProps} {...validationProps('passwordConfirmation')}
                    />

                    <p>{this.state.error}</p>
                  </FormGroup>

                  <FormGroup>
                    <TimeZoneField timezone={values.timezone} onChange={handleChange} timeZones={timeZones} />
                  </FormGroup>

                  <FormGroup>
                    <p>
                      By creating an account, you are agreeing to
                      our <a href="/pages/terms-of-use" target="_blank">terms of use</a> and have
                      read our <a href="/pages/disclaimer" target="_blank">disclaimer</a>.
                    </p>

                    <ActionBar>
                      <Button type="submit" onClick={handleSubmit} disabled={isSubmitting} color="primary"
                              primary> Sign Up </Button>
                      <ButtonLink to="/"> Cancel </ButtonLink>
                    </ActionBar>
                  </FormGroup>
                </div>
              </Form>
            </div>
          )
        }}
      </Formik>
    )
  }
}

export default withStyles(styles)(SignUp)
