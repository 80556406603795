import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import styles from '../../styles/global'

function HelpContent ({ classes }) {
  return (
    <Typography component="div" className={classes.content}>
      <h3>Glossary</h3>
      <ul>
        <li><strong>Basal Metabolic Rate</strong>: Otherwise known as “metabolism”. The amount of calories you
          would burn per day without factoring in movement and exercise.
        </li>
        <li><strong>Daily Caloric Intake</strong>: The calculated target amount of calories you should consume
          in order to achieve your nutrition goals. You can adjust these amounts if needed.
        </li>
        <li><strong>“Fixed” Ingredient</strong>: An ingredient in a recipe whose amount will not change
          regardless
          of the calorie and macronutrient goals for your meal plan.
        </li>
        <li><strong>Grocery List</strong>: A compiled list of all food items required to fulfill your weekly
          meal plan. This list uses a typical packaging variety of each item.
        </li>
        <li><strong>“Linked” Ingredient</strong>: An ingredient in a recipe whose amount will change
          proportionally to the ingredient with which it is linked.
        </li>
        <li><strong>Macronutrient Target Percentages</strong>: The calculated target ratios of Protein, Fat, and
          Carbohydrates based on your goals and dietary restrictions. You can adjust these amounts if needed.
        </li>
        <li><strong>Meal Plan</strong>: A display of the week’s meals and snacks whose ingredients and
          measurements follow the dietary restrictions, daily calorie intake and macronutrient percentages
          specific to you.
        </li>
        <li><strong>Meal Prep</strong>: An alternate view of your meal plan. This view groups repeated meals
          and snacks together in order to aid in bulk meal preparation.
        </li>
        <li><strong>Meal Settings</strong>: Parameters that determine the number of meal planning days, the
          start
          day of each meal plan, the level of variety and the number of meals and snacks per day.
        </li>
        <li>
          <strong>Remove (meal)</strong>: Removes the meal and allows you to search for a replacement recipe
          from the library.
        </li>
        <li>
          <strong>Replace All Meals</strong>: Pull a different recipe from the library, adjusted to your goals,
          and replace everywhere in that week's meal plan.
        </li>
        <li>
          <strong>Replace Meal</strong>: Pull a different recipe from the library, adjusted to your goals.
        </li>
        <li><strong>Trial Period</strong>: You have seven (7) days of free use until you are required to sign up
          for a monthly subscription.
        </li>
        <li><strong>“Whole Serving Size?”</strong>: Indication that the full serving of an item is needed in
          order for a recipe to make sense. For example, 2 full slices of bread are needed in order to create a
          sandwich.
        </li>
      </ul>

      <h3>Serving Approximations</h3>
      <ul>
        <li>3-4 ounces of Meat/Fish = Palm of Your Hand</li>
        <li>1 ounce of Nuts/Berries/Vegetables = 1 Handful</li>
        <li>1 ounce of Crackers/Pretzels/Cereal = 2 Handfuls</li>
        <li>½ ounce of Oil/Butter = 1 Tbsp / 1 spoonful</li>
        <li>1 ounce of Oil/Butter = 2 Tbsp / 1 spoonfuls</li>
      </ul>

      <h3>Measurement Conversions</h3>
      <ul>
        <li>1 ounce = ~28 grams</li>
        <li>2 ounces = ~56 grams</li>
        <li>4 fluid ounces = ½ Cup</li>
        <li>8 fluid ounces = 1 Cup</li>
      </ul>
    </Typography>
  )
}

export default withStyles(styles)(HelpContent)
