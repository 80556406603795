// @flow
import React, { PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import { MoreVertIcon } from '../../styles/icons'
import { Button } from '../Button'

export type MenuItemType = {
  icon?: any,
  label: string,
  onClick: Function,
  disabled?: boolean,
  buttonProps?: Object,
}

type Props = {
  classes: Object,
  containerClass?: string,
  menuId: string,
  menuOptions: Array<MenuItemType>,
  onClick: Function,
  onClose: Function,
}
type State = {
  open: boolean,
  anchorEl?: ?Node,
}

const styles = theme => ({
  menuItem: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& $primary, & $icon': {
        color: theme.palette.common.white,
      },
    },
  },
  primary: {},
  icon: {},
})

class ItemMenu extends PureComponent<Props, State> {
  static defaultProps = {}
  state = {
    anchorEl: null,
    open: false,
  }

  handleAction = (handleClick: Function) => {
    // console.log('handleAction', handleClick)
    this.handleClose()
    handleClick && handleClick()
  }

  handleClick = (event: SyntheticEvent<HTMLElement>) => {
    this.setState({ open: true, anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ open: false, anchorEl: null })
    this.props.onClose && this.props.onClose()
  }

  render () {
    const { classes, containerClass, label, icon, buttonProps, menuId, menuOptions } = this.props
    const { anchorEl } = this.state

    return (
      <div className={containerClass}>
        {label ? (
          <Button aria-label='Open menu'
                  aria-owns={anchorEl ? menuId : null}
                  aria-haspopup='true' onClick={this.handleClick}
                  {...buttonProps}
          >
            {icon ? icon : null} {label}
          </Button>
        ) : (
          <IconButton
            aria-label='Open menu'
            aria-owns={anchorEl ? menuId : null}
            aria-haspopup='true'
            onClick={this.handleClick}
            {...buttonProps}
          >
            <MoreVertIcon />
          </IconButton>
        )}
        <Menu
          id={menuId}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {menuOptions.map((option, i) => {
            return (
              <MenuItem key={i} onClick={() => this.handleAction(option.onClick)} disabled={!!option.disabled}>
                {option.icon && (
                  <ListItemIcon className={classes.icon}>
                    {option.icon}
                  </ListItemIcon>
                )}
                <ListItemText
                  classes={{ primary: classes.primary }} inset={Boolean(option.icon)}
                  primary={option.label} />
              </MenuItem>
            )
          })}
        </Menu>
      </div>
    )
  }
}

export default withStyles(styles)(ItemMenu)
