// @flow
import React, { PureComponent } from 'react'
import get from 'lodash/get'
import { withStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import Collapse from '@material-ui/core/Collapse'

import { colors } from '@balance/lib/styles'

import { Modal } from '../Modal'
import { Spinner } from '../Global'
import { IngredientTable } from '../Table'
import { Button } from '../Button'
import { TypeChip } from '../Chip'
import { DeleteOutline, List, Loop, MenuIcon, Search } from '../../styles/icons'
import { RecipeSearch } from '../Recipe'
import { ItemMenu } from '../Menu'

type Props = {
  classes: Object,
  meal: Object,
  recipes: Array<Object>,
  recipeUseCount: Object,
  user: Object,
  onReplaceMeal: Function,
  onAddRecipe: Function,
  onSearchRecipe: Function,
}

type State = {
  replacing: boolean,
  replacingAll: boolean,
  removing: boolean,
  instrShowing: boolean,
  modalOpen: boolean,
  searching: boolean,
  processing: boolean,
  searchResults: Array<Object>,
}

const styles = (theme) => ({
  summary: {
    alignItems: 'center',
    // justifyContent: 'space-between',
  },
  chip: {
    width: 100,
    marginRight: theme.spacing.unit,
  },
  mealType: {
    backgroundColor: colors.darkGreen,
    color: 'white',
    fontSize: '1rem',
    padding: 10,
  },
  details: {
    flexDirection: 'column',
  },
  table: {
    width: '100%',
    // minWidth: 500,
  },
  scrollableTable: {
    // border: '1px solid blue',
    overflowX: 'scroll',

    '& ::-webkit-scrollbar': {
      width: 5,
      height: 5,
    },

    '& ::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 3px rgba(0,0,0,0.3)',
      borderRadius: 20,
    },

    '& ::-webkit-scrollbar-thumb': {
      borderRadius: 20,
      backgroundColor: 'rgba(0, 0, 0, .3)',
      '-webkit-box-shadow': 'inset 0 0 3px rgba(0,0,0,0.5)',
    },
  },
  textarea: {
    width: '100%',
    marginTop: 0,
  },
  buttonWrap: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  actionText: {
    textAlign: 'right',

    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing.unit,
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing.unit,
    },
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
})

class MealPanel extends PureComponent<Props, State> {
  state = {
    replacing: false,
    replacingAll: false,
    removing: false,
    searching: false,
    instrShowing: false,
    processing: false,
    modalOpen: false,
    searchResults: [],
  }

  handleReplaceAll = async () => {
    const { meal, onReplaceMeal } = this.props
    this.setState({ replacingAll: true, processing: true })
    onReplaceMeal && await onReplaceMeal(meal, true)
    this.setState({ replacingAll: false, processing: false })
  }

  handleReplaceAllWithRecipe = async (recipe) => {
    // console.log('handleReplaceAllWithRecipe', recipe, get(recipe, 'id'))
    const { meal, onReplaceMeal } = this.props
    this.setState({ modalOpen: false, replacingAll: true, processing: true })
    onReplaceMeal && await onReplaceMeal(meal, true, get(recipe, 'id'))
    this.setState({ modalOpen: false, replacingAll: false, processing: false })
  }

  handleReplaceMeal = async () => {
    const { meal, onReplaceMeal } = this.props
    this.setState({ replacing: true, processing: true })
    onReplaceMeal && await onReplaceMeal(meal)
    this.setState({ replacing: false, processing: false })
  }

  handleRemoveMeal = async () => {
    const { meal, onRemoveMeal } = this.props
    this.setState({ removing: true, processing: true })
    onRemoveMeal && await onRemoveMeal(meal)
    this.setState({ removing: false, processing: false })
  }

  handleSearchRecipe = async (query) => {
    const { meal, onSearchRecipe } = this.props
    this.setState({ searching: true, processing: true })
    const searchResults = onSearchRecipe && await onSearchRecipe(query, meal.type)
    this.setState({ searching: false, processing: false })
    return searchResults
  }

  handleShowSearchModal = () => {
    this.setState({ modalOpen: true, processing: true })
  }

  handleCloseModal = () => {
    this.setState({ modalOpen: false, processing: false })
  }

  handleAddRecipe = async (recipe) => {
    const { onAddRecipe } = this.props
    this.setState({ replacing: true, processing: true })
    onAddRecipe && await onAddRecipe(recipe)
    this.setState({ replacing: false, processing: false })
  }

  toggleInstructions = () => {
    this.setState(prevState => ({ instrShowing: !prevState.instrShowing }))
  }

  renderInstructions = () => {
    const { classes, recipes } = this.props
    const { instrShowing } = this.state
    const instructions = recipes.map(r => r.instructions).join('')
    // console.log('renderInstructions', meal, instructions)
    if (!instructions) { return null }

    return (
      <div>
        <div className={classes.buttonWrap}>
          <Button variant="text" disableRipple size="small" onClick={this.toggleInstructions}
                  data-tour="meal-plan-instructions">
            <List />
            Instructions
          </Button>
        </div>
        <Collapse in={instrShowing} timeout="auto" unmountOnExit>
          <TextField disabled label={false} variant="outlined" margin="normal"
                     multiline className={classes.textarea}
                     defaultValue={instrShowing ? instructions : ''} />
        </Collapse>
      </div>
    )
  }

  menuOptions = () => {
    const { meal, recipeUseCount } = this.props
    const { replacing, replacingAll, removing, processing } = this.state
    const recipeCountMeal = meal.recipeIds.length
    const recipeCountTotal = meal.recipeIds.reduce((acc, cur) => acc + recipeUseCount[cur], 0)

    const disabled = replacing || replacingAll || removing || processing

    let menuOptions = []
    if (recipeCountTotal > recipeCountMeal) {
      menuOptions.push({
        label: 'Replace all this week',
        onClick: this.handleReplaceAll,
        icon: <Loop />,
        disabled,
      })
      menuOptions.push({
        label: 'Remove all this week & search',
        onClick: this.handleShowSearchModal,
        icon: <Search />,
        disabled,
      })
    }

    menuOptions.push({
      label: 'Replace this meal',
      onClick: this.handleReplaceMeal,
      icon: <Loop />,
      disabled,
    })

    menuOptions.push({
      label: 'Remove this meal',
      onClick: this.handleRemoveMeal,
      icon: <DeleteOutline />,
      disabled,
    })

    return menuOptions
  }

  render () {
    const { meal, classes, user, onReplaceMeal } = this.props
    const { modalOpen, processing } = this.state
    // const recipeCountMeal = meal.recipeIds.length
    // const recipeCountTotal = meal.recipeIds.reduce((acc, cur) => acc + recipeUseCount[cur], 0)
    // console.log('MealPanel', meal, recipeUseCount, recipeCountMeal, recipeCountTotal)
    const ingredients = get(meal, 'ingredients', [])
    // const profile = get(user, 'profile', {})
    if (!meal) {
      return null
    }
    const meal_type = meal.type === 'regular' ? 'meal' : meal.type
    const empty = meal.empty
    // const disabled = replacing || replacingAll || removing

    return (
      <ExpansionPanel data-meal-id={meal.id} data-tour="meal-plan-panel">
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} classes={{ content: classes.summary }}>
          <TypeChip fixed large label={meal_type} className={classes.mealType} />
          {meal.mismatch && <TypeChip label="random" />}
          {empty && <TypeChip label="empty" />}
          <Typography className={classes.heading}> </Typography>
          {false && meal.message && (
            <Typography variant="p" color="textSecondary">
              {meal.message}
            </Typography>
          )}
        </ExpansionPanelSummary>
        {empty ? (
          <ExpansionPanelDetails className={classes.details}>
            <Typography component="div">
              <RecipeSearch onSearch={this.handleSearchRecipe} onSelect={this.handleAddRecipe} />
            </Typography>
          </ExpansionPanelDetails>
        ) : (
          <ExpansionPanelDetails className={classes.details}>
            {this.renderInstructions()}
            <Typography component="div" className={classes.scrollableTable}>
              <IngredientTable ingredients={ingredients} recipe={meal} showCalTarget={user.role === 'admin'} />
            </Typography>
          </ExpansionPanelDetails>

        )}
        <Divider />
        {!empty && onReplaceMeal && (
          <ExpansionPanelActions>
            <div className={classes.actions} data-tour="meal-plan-actions">
              <Typography className={classes.actionText}>Want a different {meal_type}?</Typography>

              <div className={classes.actionButtons}>
                <ItemMenu label="Change"
                          icon={processing ? <Spinner color="secondary" size={16} thickness={4} /> : <MenuIcon />}
                          buttonProps={{ size: 'small', color: 'secondary' }}
                          menuOptions={this.menuOptions()} />

                {/*{recipeCountTotal > recipeCountMeal && (*/}
                {/*  <Button size="small" color="secondary" onClick={this.handleReplaceAll}*/}
                {/*          disabled={disabled}>*/}
                {/*    {replacingAll ? <Spinner color="secondary" size={16} thickness={4} /> :*/}
                {/*      <Loop />}*/}
                {/*    Replace all this week*/}
                {/*  </Button>*/}
                {/*)}*/}
                {/*<Button size="small" color="secondary" onClick={this.handleReplaceMeal}*/}
                {/*        disabled={disabled}>*/}
                {/*  {replacing ? <Spinner color="secondary" size={16} thickness={4} /> :*/}
                {/*    <Loop />}*/}
                {/*  Replace*/}
                {/*</Button>*/}
                {/*<Button size="small" color="secondary" onClick={this.handleRemoveMeal}*/}
                {/*        disabled={disabled}>*/}
                {/*  {removing ? <Spinner color="secondary" size={16} thickness={4} /> :*/}
                {/*    <DeleteOutline />}*/}
                {/*  Remove*/}
                {/*</Button>*/}
                {/*{false && ingredients.count > 1 && (*/}
                {/*  <Button size="small" color="primary">*/}
                {/*    Edit*/}
                {/*  </Button>*/}
                {/*)}*/}
              </div>
            </div>
          </ExpansionPanelActions>
        )}

        <Modal open={modalOpen} showCloseButton onClose={this.handleCloseModal}>
          <RecipeSearch onSearch={this.handleSearchRecipe} onSelect={this.handleReplaceAllWithRecipe} />
        </Modal>

      </ExpansionPanel>
    )
  }
}

export default withStyles(styles)(MealPanel)
