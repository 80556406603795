// @flow
import React, { Fragment, PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core'
import get from 'lodash/get'
import memoize from 'memoize-one'

import { Formik, getIn } from 'formik'
import { FullPageSpinner, Spinner } from '../Global'

import { profilePaymentSchema } from '@balance/lib/validations'
import { IS_DEV } from '@balance/lib/config/constants'

import { DisplayField, FormField, FormGroup } from '../Form'
import PageTitle from '../PageTitle/PageTitle'
import global from '../../styles/global'
import ButtonLink from '../Button/ButtonLink'

import { Payment, Person, PriceTag, TouchApp } from '../../styles/icons'
import ActionBar from '../Form/ActionBar'
import Button from '../Button/Button'
import {
  getMainProduct, getReceipt, isAndroid, isAvailable as isIapAvailable, nativePaymentLabel,
} from '../../lib/inAppPurchasing'

import amex from 'payment-icons/min/flat/amex.svg'
import visa from 'payment-icons/min/flat/visa.svg'
import mastercard from 'payment-icons/min/flat/mastercard.svg'
import discover from 'payment-icons/min/flat/discover.svg'
import InAppPayment from './InAppPayment'

type Props = {
  user: object,
  payment: object,
  startCheckout: Function,
  savePayment: Function,
  validateReceipt: Function,
}
type State = {
  ready: boolean,
  loading: boolean,
  isSubmitting: boolean, // replacement for Formik's
  isValidating: boolean,
  isValid: boolean,
  success: boolean,
  token: string,
  validUntil: Date,
  apiUrl: string,
  userIp: string,
  error: string,
  cc: {
    num: string,
    mmyy: string,
    cvc: string,
    zip: string,
  },
  useIAP: boolean,
  iapProduct: Object,
}

const styles = (theme) => {
  const gs = global(theme)
  return {
    ...gs,
    cards: {
      '& img': {
        maxWidth: 32,
        marginRight: 4,
      },
    },
    paymentOptionsGroup: {
      marginTop: theme.spacing.unit * 2,
    },
  }
}

class PaymentEdit extends PureComponent<Props, State> {
  static defaultProps = {
    user: {},
    payment: {},
  }
  state = {
    ready: false,
    loading: !!window.cordova, // cordova needs to load IAP products
    success: false,
    isSubmitting: false,
    isValidating: false,
    token: '',
    validUntil: null,
    apiUrl: '',
    userIp: '',
    error: '',
    cc: {
      num: IS_DEV ? '4242424242424242' : '',
      mmyy: IS_DEV ? '10/22' : '',
      cvc: IS_DEV ? '123' : '',
      zip: IS_DEV ? '12345' : '',
    },
    useIAP: false,
  }

  name = memoize((option1, option2) => {
    // console.log('name options', option1, option2)
    return option1 || option2
  })

  componentDidMount = async (): void => {
    // console.log('PaymentEdit.cDM', this.props)

    let result = {}
    const iapIsAvailable = isIapAvailable()
    let iapProduct = {}
    if (iapIsAvailable) {
      try {
        iapProduct = await getMainProduct()
      } catch (err) {
        // console.log('PaymentEdit.cDM error', err)
        this.setState({ error: err.text })
      }
    } else {
      result = await this.props.startCheckout()
      // console.log('result?', result)
    }
    this.setState({
      ...result,
      iapAvailable: iapIsAvailable,
      iapProduct: iapProduct,
      useIAP: iapIsAvailable,
      success: false,
      isSubmitting: false,
      ready: result && Boolean(result.success) === true,
      loading: false,
    }, this.loadCheckoutJs)

    if (iapIsAvailable) {
      this.validateReceipt() // don't await on it, tho
    }
  }

  validateReceipt = async () => {
    // console.log('validating receipt ...')
    if (this.state.isValidating || isAndroid()) { return }

    this.setState({ isValidating: true })
    try {
      const receiptData = await getReceipt()
      // console.log('got data', receiptData)
      // console.log('got data (length)', receiptData.length)
      /*const valid = */
      this.props.validateReceipt && await this.props.validateReceipt(receiptData)
      // console.log('valid?', valid)
    } catch (err) {
      // console.log('got error', err)
    }
  }

  // componentDidUpdate = () => {
  //   console.log('PaymentEdit.cDU', this.props)
  //   this.setState({
  //   })
  // }

  handlePayWithCC = () => {
    this.setState({ useIAP: false })
  }

  handlePayWithIAP = () => {
    this.setState({ useIAP: true })
  }

  handleSubmit = async (values) => {
    this.setState({ isSubmitting: true })
    // console.log('PaymentEdit.handleSubmit', this.state, values)
    await this.submitPayment(values)
  }

  handlePaymentError = (error) => {
    this.setState({ error, isSubmitting: false })
    this.showPaymentResult('error', error)
  }

  handleDeclined = (response) => {
    const { errorName, errorMessage } = response
    this.setState({
      error: ['Declined. Reason: ', [errorName, errorMessage].join(': ')].join(' '),
      isSubmitting: false,
    })
    this.showPaymentResult('declined', JSON.stringify(response))
  }

  handleApproval = async (response) => {
    const { savePayment } = this.props
    // console.log('approved!', response)
    this.showPaymentResult('approval', JSON.stringify(response))

    const { ssl_first_name, ssl_last_name, ssl_card_number, ssl_card_short_description, ssl_exp_date, ssl_recurring_id } = response
    // console.log('response values?', response)
    /*const result = */
    await savePayment({
      firstName: ssl_first_name,
      lastName: ssl_last_name,
      ccLast4: ssl_card_number.slice(-4),
      ccDesc: ssl_card_short_description,
      ccExpDate: ssl_exp_date,
      recurringTxnId: ssl_recurring_id,
    })

    this.setState({ success: true, error: '', isSubmitting: false })
    // console.log('done', result)
  }

  handleIapSuccess = async (receiptData) => {
    const { savePayment } = this.props
    // console.log('handleIapSuccess', receiptData)
    // this.showPaymentResult('approval', JSON.stringify(receiptData))

    const { transactionId, receipt } = receiptData
    // console.log('response values?', response)
    /*const result = */
    await savePayment({
      iapStore: get(window, 'device.platform'),
      iapReceipt: receipt,
      iapOriginalTxnId: transactionId,
      // firstName: ssl_first_name,
      // lastName: ssl_last_name,
      // ccLast4: ssl_card_number.slice(-4),
      // ccDesc: ssl_card_short_description,
      // ccExpDate: ssl_exp_date,
      // recurringTxnId: ssl_recurring_id,
    })

    this.setState({ success: true, error: '', isSubmitting: false })
    // console.log('handleIapSuccess done', result)
  }

  handleCancelled = () => {
    this.setState({ error: 'Cancelled!', isSubmitting: false })
    this.showPaymentResult('cancelled', '')
  }

  getMonthlyFee = () => {
    const { useIAP, iapAvailable, iapProduct } = this.state
    if (useIAP && iapAvailable && iapProduct && iapProduct.price) {
      return `${iapProduct.price} (${iapProduct.currency})`
    }
    return `$${get(this.props, 'payment.monthlyPrice', '4.99')} (USD)`
  }

  handleonDCCDecision = (DCCResponse) => {
    // DCCResponse has fields “ssl_conversion_rate”, ssl_cardholder_amount”, “ssl_markup”, ssl_dcc_rate_provider”,  //ssl_cardholder_currency” Merchant display DCC fields, call ConvergeEmbeddedPayment.dccDecision() after customer makes // decision;
  }

  onThreeDSecure = (threeDSecureResponse) => {
    // threeDSecureResponse has fields “acs_url”, “pareq” and “md”
    // Redirect Cardholder to 3DSecure site, call ConvergeEmbeddedPayment.threeDSecureReturn() after redirect comeback.

  }

  showPaymentResult = (status, msg, hash) => {
    // console.log('showPaymentResult', status, msg, hash)
    // document.getElementById('txn_status').innerHTML = '<b>' + status + '</b>'
    // document.getElementById('txn_response').innerHTML = msg + '</b>'
    // document.getElementById('txn_hash').innerHTML = hash
  }

  loadCheckoutJs = () => {
    if (this.state.iapAvailable) { return }
    // console.log('loadCheckoutJs', this.state)
    if (!this.state.ready) { return }

    const platform = get(window, 'device.platform')
    // return ['iOS', 'Android'].includes(platform)
    if (window.cordova && platform) { return }

    const jsFile = `${this.state.apiUrl}/hosted-payments/Checkout.js`
    const script = document.createElement('script')

    script.src = jsFile
    script.async = true

    document.body.appendChild(script)
  }

  submitPayment = async (values) => {
    // console.log('submitPayment', values, this.state)
    const { user } = this.props
    const { token, userIp } = this.state
    const { firstName, lastName } = values
    if (!window.ConvergeEmbeddedPayment || !token) {
      this.setState({
        ready: false,
        error: 'Unable to process payments at this time.',
      })
      return false
    }

    var card = get(values, 'cc.num')
    var mmyy = get(values, 'cc.mmyy')
    var cvc = get(values, 'cc.cvc')
    var zip = get(values, 'cc.zip')
    const price = get(this.props, 'payment.monthlyPrice', '4.99')

    var startPaymentDate = new Date()
    // nextPaymentDate.setMonth(nextPaymentDate.getMonth() + 1)
    let mm = startPaymentDate.getMonth() + 1
    let dd = startPaymentDate.getDate()
    var startPaymentDateStr = `${mm < 10 ? '0' : ''}${mm}/${dd < 10 ? '0' : ''}${dd}/${startPaymentDate.getFullYear()}`

    var paymentData = {
      ssl_transaction_type: 'ccaddrecurring',

      ssl_txn_auth_token: token, // session token

      ssl_card_number: card,
      ssl_exp_date: mmyy,
      ssl_cvc: cvc,
      ssl_avs_zip: zip,
      ssl_add_token: 'Y',
      ssl_amount: price,
      ssl_billing_cycle: 'MONTHLY',
      ssl_end_of_month: 'N',
      ssl_next_payment_date: startPaymentDateStr,
      ssl_cardholder_ip: userIp,
      ssl_email: user.email,
      ssl_first_name: firstName,
      ssl_last_name: lastName,
    }

    // <!-- use callback to handle the transaction response appropriately -->

    var callbacks = {
      onError: this.handlePaymentError, // Take appropriate action to handle errors
      onDeclined: this.handleDeclined, // Take appropriate action to handle decline
      onApproval: this.handleApproval, // Take appropriate action to handle approvals
      onCancelled: this.handleCancelled, // Take appropriate action to handle cancellation?
      // onDCCDecision: this.handleonDCCDecision,
      // onThreeDSecure: this.onThreeDSecure,
    }

    await window.ConvergeEmbeddedPayment.pay(paymentData, callbacks)
    // ConvergeEmbeddedPayment.dccDecision(dccOption, callback)
// dccOption = true/false
//     ConvergeEmbeddedPayment.threeDSecureReturn(md, pares, callback)
    return true
  }

  render () {
    const { classes } = this.props
    const { ready, loading, success, isSubmitting, useIAP, iapAvailable } = this.state
    // console.log('PaymentEdit.render', this.props, this.state)

    const isNotProd = get(this.state, 'apiUrl', '').indexOf('demo') > -1

    const existingPayment = get(this.props, 'payment', {})

    const alreadyUsingCC = get(existingPayment, 'isCc')
    const alreadyUsingIap = get(existingPayment, 'isIap')
    // const iapStore = get(existingPayment, 'iapStore')
    // console.log('iap', { iapStore, alreadyUsingCC, alreadyUsingIap })

    const firstName = this.name(get(existingPayment, 'firstName'), get(this.props, 'user.firstName'))
    const lastName = this.name(get(existingPayment, 'lastName'), get(this.props, 'user.lastName'))
    // console.log('names', firstName, lastName)

    const initialForm = {
      firstName,
      lastName,
      ...this.state,
    }

    const fee = this.getMonthlyFee()

    if (loading) {
      return <FullPageSpinner open={true} />
    }

    if (success) {
      return (
        <div className={classes.container}>
          <PageTitle noIcon title="Billing Details" />

          <p>
            Success! You're all set to start enjoying a
            balanced diet! <Link to={{ pathname: '/meal-plan', state: { reload: true } }}>View your customized meal
            plan</Link>
          </p>
        </div>
      )
    }

    return (
      <div className={classes.container}>
        <PageTitle noIcon title="Billing Details" />

        <Formik
          onSubmit={this.handleSubmit}
          isInitialValid={false}
          initialValues={initialForm}
          enableReinitialize={false}
          validateOnChange={true}
          validationSchema={profilePaymentSchema}>
          {({ values, errors, touched, isValid, handleSubmit, handleChange, handleBlur }) => {

            const systemReady = Boolean(window.ConvergeEmbeddedPayment) && ready
            // console.log('profile payment form', values, errors)
            // console.log('systemReady, isValid, isSubmitting, disabled', systemReady, isValid, isSubmitting,
            //   !systemReady || !isValid || isSubmitting)
            const validationProps = name => {
              const hasError = Boolean(getIn(errors, name))
              const hasValue = getIn(values, name) !== undefined
              // const isTouched = getIn(touched, name)
              // console.log('validating', name, hasError, isTouched, hasValue, hasValue || (!isValid && !isTouched))
              return {
                touched: hasValue || hasError,
                error: getIn(errors, name),
              }
            }
            const changeProps = { onChange: handleChange, onBlur: handleBlur }

            return (
              <Fragment>
                <FormGroup>
                  <p>
                    Subscribe now to gain access to the weekly meal plans that we generate for you based on your fitness
                    and nutritional profile and goals. Your first membership fee of {fee} will be billed immediately and
                    automatically renew every month; you can cancel at any time, but once your subscription expires (at
                    the end of the then-current billing cycle),
                    you will no longer have access to the weekly meal plan generator or grocery lists.
                  </p>
                  {iapAvailable && (
                    <p>
                      You can cancel by simply visiting the {nativePaymentLabel()} subscriptions page. Note that
                      cancelling your account within
                      the app does not cancel your subscription; that must be done
                      via the app store.
                    </p>
                  )}

                </FormGroup>

                <FormGroup icon={<PriceTag />}>
                  <DisplayField name="fee" label="Monthly Membership Fee" value={fee} />

                </FormGroup>

                {iapAvailable && (
                  <FormGroup icon={<TouchApp />}>

                    <p>
                      By clicking “Subscribe” and completing the {nativePaymentLabel()} process,
                      you are agreeing to Balance on Demand’s <Link target="_blank" to="/pages/terms-of-use">Terms
                      and Conditions</Link>.
                    </p>

                    <div className={classes.paymentOptionsGroup}>
                      {(useIAP || alreadyUsingIap) ? (
                        <InAppPayment onPayWithCC={this.handlePayWithCC} saveReceipt={this.handleIapSuccess}
                                      validateReceipt={this.validateReceipt} existingPayment={existingPayment} />
                      ) : (
                        <Button variant="outlined" onClick={this.handlePayWithIAP}>
                          Subscribe via {nativePaymentLabel()}
                        </Button>
                      )}
                    </div>

                  </FormGroup>)}

                {(!useIAP || alreadyUsingCC) && !iapAvailable && (
                  <Fragment>
                    <FormGroup icon={<Person />}>
                      <FormField name="firstName" label="First Name" value={values.firstName}
                                 onChange={handleChange}
                                 {...changeProps} {...validationProps('firstName')}
                      />
                      <FormField name="lastName" label="Last Name" value={values.lastName}
                                 onChange={handleChange}
                                 {...changeProps} {...validationProps('lastName')}
                      />

                    </FormGroup>
                    <FormGroup icon={<Payment />}>

                      <FormField name="cc.num" label="Credit Card Number" value={values.cc.num}
                                 onChange={handleChange}
                                 {...changeProps} {...validationProps('cc.num')}
                      />
                      <div className={classes.cards}>
                        <img alt="Visa" src={visa} />
                        <img alt="MasterCard" src={mastercard} />
                        <img alt="American Express" src={amex} />
                        <img alt="Discover" src={discover} />
                      </div>

                      <FormField name="cc.mmyy" label="Credit Card MM/YY" value={values.cc.mmyy}
                                 onChange={handleChange}
                                 {...changeProps} {...validationProps('cc.mmyy')}
                      />

                      <FormField name="cc.cvc" label="Credit Card CVC" value={values.cc.cvc}
                                 maxLength="4"
                                 onChange={handleChange}
                                 {...changeProps} {...validationProps('cc.cvc')}
                      />

                      <FormField name="cc.zip" label="Billing Postal Code" value={values.cc.zip}
                                 onChange={handleChange}
                                 {...changeProps} {...validationProps('cc.zip')}
                      />

                      <p className={classes.error}>{this.state.error}</p>
                    </FormGroup>

                    <FormGroup>

                      {isNotProd && (
                        <p className={classes.error}>
                          NOTE: this system is hooked up to a test processing environment. Real credit cards should not
                          be used (although they are still secure). You can view a list of valid test credit card
                          numbers <a rel="noopener noreferrer" href="https://www.datatrans.ch/showcase/test-cc-numbers"
                                     target="_blank">here</a>.
                          Other numbers may or may not be declined by the API; 4141414141414141 is a known invalid card.
                        </p>
                      )}

                      <p>
                        By clicking “Pay”, you are agreeing to Balance on Demand’s <Link target="_blank"
                                                                                         to="/pages/terms-of-use">Terms
                        and
                        Conditions</Link>.
                      </p>

                      <ActionBar>
                        <Button variant="contained" primary disabled={!systemReady || !isValid || isSubmitting}
                                onClick={handleSubmit}>
                          {isSubmitting && <Spinner size={16} thickness={4} />} Pay
                        </Button>
                        <ButtonLink variant="outlined" to="/">Not now</ButtonLink>
                      </ActionBar>

                    </FormGroup>
                  </Fragment>
                )}
              </Fragment>
            )
          }}
        </Formik>

      </div>
    )
  }
}

export default withStyles(styles)(PaymentEdit)
