import React from 'react'
import get from 'lodash/get'
import classnames from 'classnames'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import printStyles from '../../styles/print'

import { ButtonLink } from '../Button'
import { Calendar } from '../../styles/icons'

const styles = theme => ({
  list: {
    margin: [[theme.spacing.unit, 0]],
    padding: 0,
    listStyleType: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',

    '@media print': {
      // marginTop: -40,
      // float: 'right',
      clear: 'both',
    },
  },
  item: {
    display: 'inline',
    marginRight: theme.spacing.unit / 2,
    whiteSpace: 'nowrap',
  },
  ...printStyles(theme), // more specificity down here
})

const Link = ({ selected, ...props }) => (
  <ButtonLink size="small" color={selected ? 'primary' : 'default'}
              variant={selected ? 'outlined' : 'text'} {...props} />
)

function WeekToggle ({ classes, weekOfShort, ...props }) {
  // console.log('WeekToggle', props)
  let week = get(props, 'location.search', '').replace('?week=', '')
  if (!['last', 'next'].includes(week)) { week = '' }
  // console.log('week selected', week)

  const path = get(props, 'location.pathname')
  const links = [
    { tag: 'last', to: `${path}?week=last`, label: 'Last Week' },
    { tag: '', to: `${path}`, label: 'This Week' },
    { tag: 'next', to: `${path}?week=next`, label: 'Next Week' },
  ]
  return (
    <div>
      <ul className={classes.list}>
        <li className={classes.item}><Calendar color="secondary" /></li>
        <li className={classnames(classes.item, classes.printOnly)}>
          <Link selected to="/">Week of {weekOfShort}</Link>
        </li>
        {links.map((linkInfo, i) => {
          const isCurrent = week === linkInfo.tag
          return (
            <li key={i}
                className={classnames(classes.item, classes.noPrint)}>
              <Link selected={isCurrent} to={linkInfo.to}>
                <span className={classes.label}>{linkInfo.label}</span>
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default withRouter(withStyles(styles)(WeekToggle))
