// @flow
import React, { Fragment, PureComponent } from 'react'
import { Divider, Typography, withStyles } from '@material-ui/core'
import get from 'lodash/get'

import { hasMealSettings } from '@balance/lib/api/profile'
import { colors } from '@balance/lib/styles'

import { FormField, FormGroup } from '../Form'
import PageTitle from '../PageTitle/PageTitle'
import globalStyles from '../../styles/global'
import { Button, ButtonLink, InlineButton } from '../Button'

import { Edit, Person } from '../../styles/icons'
import ActionBar from '../Form/ActionBar'

type Props = {
  profile: Object,
  saveProfile: Function,
}
type State = {
  dci: number,
  protein: number,
  fat: number,
  carbs: number,
  editingDci: boolean,
  editingPct: boolean,
  dciReset: boolean,
  pctReset: boolean,
}

const styles = theme => {
  const global = globalStyles(theme)
  return {
    ...global,
    icon: {
      ...global.icon,
    },
    subHeader: {
      color: colors.darkGreen,
    },
    oneliner: {
      minHeight: 36,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
    },
    inlineFormWrapper: {
      minHeight: 36,
      display: 'flex',
      flexDirection: 'column',

      [theme.breakpoints.up('md')]: {
        alignItems: 'center',
        flexDirection: 'row',
      },
    },
    field: {
      textAlign: 'right',
      [theme.breakpoints.up('md')]: {
        margin: [[0, theme.spacing.unit]],
        width: 150,
      },
    },
    fixedLabel: {
      width: 200,
    },
    fixedField: {
      textAlign: 'right',
      [theme.breakpoints.up('md')]: {
        margin: [[0, theme.spacing.unit]],
        width: 70,
      },
    },
    button: {
      maxWidth: 150,
    },
  }
}

class ProfileResults extends PureComponent<Props, State> {
  static defaultProps = {}
  state = {
    dci: get(this.props, 'profile.dci', 0),
    protein: get(this.props, 'profile.proteinPct', 0),
    fat: get(this.props, 'profile.fatPct', 0),
    carbs: get(this.props, 'profile.carbsPct', 0),
    editingDci: false,
    editingPct: false,
    dciReset: false,
    pctReset: false,
  }

  resetDci = () => {
    this.setState(prevState => (
      {
        dci: this.props.profile.dciDefault,
        editingDci: true,
        dciReset: true,
      }
    ))
  }

  resetPct = () => {
    this.setState(prevState => (
      {
        protein: get(this.props, 'profile.proteinPctDefault', 0),
        fat: get(this.props, 'profile.fatPctDefault', 0),
        carbs: get(this.props, 'profile.carbsPctDefault', 0),
        editingPct: true,
        pctReset: true,
      }
    ))
  }

  toggleEditDci = () => {
    this.setState(prevState => (
      {
        // dci: this.props.profile.dci,
        editingDci: !prevState.editingDci,
      }
    ))
  }

  toggleEditPct = () => {
    this.setState(prevState => (
      {
        // dci: this.props.profile.dci,
        editingPct: !prevState.editingPct,
      }
    ))
  }

  cancelEditDci = () => {
    this.setState({
      dci: this.props.profile.dci,
      editingDci: false,
      dciReset: false,
    })
  }

  cancelEditPct = () => {
    this.setState({
      protein: get(this.props, 'profile.proteinPct', 0),
      fat: get(this.props, 'profile.fatPct', 0),
      carbs: get(this.props, 'profile.carbsPct', 0),
      editingPct: false,
      pctReset: false,
    })
  }

  handleChange = (e) => {
    const { target: { value } } = e
    // console.log('handleChange', name, value)
    const num = isNaN(parseFloat(value)) ? value : parseFloat(value)
    this.setState({
      [e.target.name]: num,
    })
  }

  handleSaveProfile = async () => {

    const { dci, proteinPct, fatPct, carbsPct } = this.props.profile
    const { dci: dci2, protein: proteinPctNew, fat: fatPctNew, carbs: carbsPctNew, dciReset, pctReset } = this.state

    let haveChange = false
    let changes = {}
    if (dci !== dci2) {
      changes['dci'] = dci2
      changes['dciOverride'] = true
      haveChange = true
    }
    if (dciReset) {
      changes['dciReset'] = true
      haveChange = true
    }
    if (proteinPct !== proteinPctNew || fatPct !== fatPctNew || carbsPct !== carbsPctNew) {
      changes['proteinPct'] = proteinPctNew
      changes['fatPct'] = fatPctNew
      changes['carbsPct'] = carbsPctNew
      changes['pctOverride'] = true
      haveChange = true
    }
    if (pctReset) {
      changes['pctReset'] = true
      haveChange = true
    }
    // console.log('changes', changes)
    if (haveChange) {
      /*const result = */await this.props.saveProfile(changes)
      // console.log('result', result)
    }
    this.cancelEditDci()
    this.cancelEditPct()
  }

  render () {
    const { classes, profile } = this.props
    const { editingDci, editingPct, dci, protein, fat, carbs } = this.state
    // console.log('ProfileResults.render', profile, this.state)

    if (!profile) {
      return null
    }

    const anyMealSettings = hasMealSettings(profile)
    const btnPrefix = anyMealSettings ? 'Modify' : 'Proceed to'

    const total = protein + fat + carbs
    const valid = total === 100
    // console.log('total', total, valid)

    // const targets = get(profile, 'nutrientTargetPct', [])
    // const macro = {}
    // ;['protein', 'fat', 'carbs'].forEach(nType => {
    //   const tFound = targets && targets.find(n => n.nutrient === nType)
    //   const t = tFound && tFound.target
    //   macro[nType] = t ? `${t}%` : ''
    // })
    // console.log('macro', macro)

    return (
      <div className={classes.container}>
        <PageTitle noIcon title="Profile Targets" />

        <FormGroup icon={<Person />}>
          <section className={classes.section}>
            <Typography variant="h4" className={classes.subHeader}>Basal Metabolic Rate</Typography>
            <p></p>
            <Typography variant="h6">
              Your calculated BMR is: {profile.bmr || 'N/A'}
            </Typography>
            <p>
              This is based on the values you provided for your
              {Boolean(profile.bodyFatPct)
                ? ' gender, age, height, and weight.'
                : ' weight and body fat.'}
            </p>
          </section>
          <Divider />

          <section className={classes.section}>
            <Typography variant="h4" className={classes.subHeader}>Daily Caloric Intake</Typography>
            <p></p>
            <Typography variant="h6">
              {editingDci ?
                (
                  <div className={classes.inlineFormWrapper}>
                    <span>Your calculated DCI is:&nbsp;</span>
                    <FormField autoFocus name="dci" value={dci} onChange={this.handleChange}
                               fullWidth={false}
                               className={classes.field} />
                    <ActionBar inline>
                      <Button primary size="small" className={classes.button}
                              onClick={this.handleSaveProfile}>Save</Button>
                      <Button size="small" className={classes.button} onClick={this.cancelEditDci}>Cancel</Button>
                    </ActionBar>
                  </div>
                )
                :
                (
                  <Fragment>
                    <div className={classes.oneliner}>
                      Your calculated DCI is:&nbsp; {dci || 'N/A'}
                      <Edit color="action" onClick={this.toggleEditDci} className={classes.icon} />
                    </div>
                  </Fragment>
                )
              }
            </Typography>
            <p>
              {profile.dciOverride ? (
                <Fragment>
                  You have overridden the calculated DCI of {profile.dciDefault}. You
                  can <InlineButton onClick={this.resetDci}>reset to the
                  default</InlineButton> or <InlineButton onClick={this.toggleEditDci}><strong>adjust this
                  value</strong></InlineButton> as needed.
                </Fragment>
              ) : (
                <Fragment>
                  This is based on the values you provided for your
                  activity level and nutritional goals, plus your BMI. You
                  can <InlineButton onClick={this.toggleEditDci}><strong>adjust this value</strong></InlineButton> as
                  needed.
                </Fragment>
              )}
            </p>
          </section>

          <Divider />

          <section className={classes.section}>
            <Typography variant="h4" className={classes.subHeader}>Macronutrient Target Percentages</Typography>
            <p>
              Based on your inputs and our calculations, your target nutrient breakdown is:
            </p>
            <div>
              {!editingPct ? (
                <Fragment>
                  <Typography variant="h6">Proteins: {protein}%</Typography>
                  <Typography variant="h6">Fat: {fat}%</Typography>
                  <Typography variant="h6">Carbohydrates: {carbs}%</Typography>
                </Fragment>
              ) : (
                <div>
                  <div>
                    <Typography variant="h6" className={classes.oneliner}>
                      <label className={classes.fixedLabel}>Proteins:</label>
                      <FormField autoFocus name="protein" value={protein} onChange={this.handleChange}
                                 fullWidth={false}
                                 className={classes.fixedField} />%
                    </Typography>

                    <Typography variant="h6" className={classes.oneliner}>
                      <label className={classes.fixedLabel}>Fat:</label>
                      <FormField autoFocus name="fat" value={fat} onChange={this.handleChange}
                                 fullWidth={false}
                                 className={classes.fixedField} />%
                    </Typography>

                    <Typography variant="h6" className={classes.oneliner}>
                      <label className={classes.fixedLabel}>Carbohydrates:</label>
                      <FormField autoFocus name="carbs" value={carbs} onChange={this.handleChange}
                                 fullWidth={false}
                                 className={classes.fixedField} />%
                    </Typography>
                    {valid || <p className={classes.error}>Combined values must equal 100%, not {total}% </p>}
                  </div>

                  <ActionBar inline>
                    <Button primary size="small" className={classes.button}
                            disabled={!valid}
                            onClick={this.handleSaveProfile}>Save</Button>
                    <Button size="small" className={classes.button} onClick={this.cancelEditPct}>Cancel</Button>
                  </ActionBar>
                </div>
              )}
            </div>
            <p>
              {profile.pctOverride ? (
                <Fragment>
                  You have overridden the calculated percentages
                  of {profile.proteinPctDefault}%/{profile.fatPctDefault}%/{profile.carbsPctDefault}%.
                  You can <InlineButton onClick={this.resetPct}>reset to the
                  default values</InlineButton> or <InlineButton onClick={this.toggleEditDci}>adjust these values
                  further</InlineButton> as needed.
                </Fragment>
              ) : (
                <Fragment>
                  You can <InlineButton onClick={this.toggleEditPct}><strong>adjust
                  these values</strong></InlineButton> if necessary.
                </Fragment>
              )}


            </p>
          </section>
          <Divider />

          <ActionBar>
            <ButtonLink variant="contained" primary to="/profile/meals">{btnPrefix} meal settings</ButtonLink>
            <ButtonLink variant="outlined" primary to="/profile/demo">Modify my profile</ButtonLink>
          </ActionBar>

        </FormGroup>
      </div>
    )
  }
}

export default withStyles(styles)(ProfileResults)
