// @flow
import React, { PureComponent, cloneElement } from 'react'
import { withStyles } from '@material-ui/core/styles'
import MuiFab from '@material-ui/core/Fab'
import classnames from 'classnames'

type Props = {
  classes: Object,
  extended: boolean,
  icon: Function,
  label: string,
  hasPopup: boolean,
  position?: string,
  size?: string,
  transparent: boolean,
}

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
    position: 'fixed',
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2,
    zIndex: 1,
  },
  topRight: {
    boxShadow: 'none',
    position: 'absolute',
    bottom: 'auto',
    top: 60,
  },
  topLeft: {
    position: 'absolute',
    right: 'auto',
    left: theme.spacing.unit * 2,
    bottom: 'auto',
    top: 84,
  },
  icon: {},
  iconLarge: {
    fontSize: '2rem',
  },
  extended: {
    marginRight: theme.spacing.unit,
  },
})

class FAB extends PureComponent<Props> {
  static defaultProps = {
    extended: false,
  }

  render () {
    const { classes, extended, icon, label, position, size, hasPopup, ...rest } = this.props

    if (!icon) {
      return null
    }
    const iconSize = size === 'large' ? classes.iconLarge : null
    const iconClass = extended ? classes.extended : classes.icon
    const variant = extended ? 'extended' : 'round'
    const otherButtonClass = position === 'top-right'
      ? classes.topRight
      : position === 'top-left' ? classes.topLeft : null

    return (
      <MuiFab
        variant={variant}
        aria-label={label}
        color="primary"
        className={classnames(classes.button, otherButtonClass)}
        aria-haspopup={hasPopup}
        {...rest}
      >
        {cloneElement(icon, { className: classnames(iconClass, iconSize) })}
        {extended && label}
      </MuiFab>
    )
  }
}

export default withStyles(styles)(FAB)
