"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.recipeEditSchema = exports.profilePaymentSchema = exports.profileMealsSchema = exports.profileActivitySchema = exports.profileGoalsSchema = exports.profileDemoSchema = exports.resetPasswordSchema = exports.signupSchema = exports.passwordConfirmation = exports.password = exports.emailConfirmation = void 0;

var _get = _interopRequireDefault(require("lodash/get"));

var Yup = _interopRequireWildcard(require("yup"));

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  } else {
    var newObj = {};

    if (obj != null) {
      for (var key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {};

          if (desc.get || desc.set) {
            Object.defineProperty(newObj, key, desc);
          } else {
            newObj[key] = obj[key];
          }
        }
      }
    }

    newObj.default = obj;
    return newObj;
  }
}

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
} //const Yup = require('yup')


Yup.addMethod(Yup.string, 'reformatPhone', function (formats, parseStrict) {
  //console.log('Yup.phone?', formats, parseStrict)
  return this.test('phone', 'Phone is invalid', function (value, originalValue) {
    //console.log('Yup.phone.transform?', value, originalValue, this.isType(value))
    if (this.isType(value)) {
      var stripped = value.replace(/^.*(\d[3]).*(\d[3]).*(\d[4]).*$/, '$1-$2-$3');
      return stripped;
    }

    try {
      var _stripped = value.replace(/^.*(\d[3]).*(\d[3]).*(\d[4]).*$/, '$1-$2-$3');

      return _stripped;
    } catch (err) {
      console.error('unable to reformat phone!', err, value);
    }

    return value;
  });
});
Yup.addMethod(Yup.string, 'phone', function (formats, parseStrict) {
  //console.log('Yup.phone.test', formats, parseStrict)
  return this.test('phone', 'Phone is invalid', function (value) {
    //console.log('Yup.phone.test?', value)
    return value.test(/^.*(\d[3]).*(\d[3]).*(\d[4]).*$/); // if (this.isType(value)) {
    //   const stripped = value.replace(/^.*(\d[3]).*(\d[3]).*(\d[4]).*$/, '$1-$2-$3')
    //   return stripped
    // }
    //
    // try {
    //   const stripped = value.replace(/^.*(\d[3]).*(\d[3]).*(\d[4]).*$/, '$1-$2-$3')
    //   return stripped
    // } catch (err) {
    //   console.error('unable to reformat phone!', err, value)
    // }
    //
    // return value
  });
});
var anyValue = Yup.string().trim().required('Required.');
var name = Yup.string().min(5, 'Name must be at least 5 characters long.').required('Name is required.');
var firstName = Yup.string().trim().required('First name is required.');
var lastName = Yup.string().trim().required('Last name is required.'); //min(3, 'Last name must be at least 3 characters long.').

var phone = Yup.string().trim().required('Phone is required.'); // Yup.string().matches(/.*\d[3].*\d[3].*\d[4].*/, 'Phone is invalid').required('Phone must be provided')
// Yup.string().phone('Phone is invalid').required('Phone must be provided')

var email = Yup.string().trim().email('Email is invalid.').required('Email is required.');
var relationship = Yup.string().required('Relationship is required.');
var tag = Yup.string().required('Tag number is required.');
var schoolId = Yup.string().required('School is required.');
var gradeLevel = Yup.string().required('This year\'s grade is required.');
var teacherId = Yup.string().required('Homeroom teacher is required.');
var livesWith = Yup.string().required('Living arrangement is required.');
var street1 = Yup.string().trim().required('Street address is required.');
var city = Yup.string().trim().required('City is required.');
var state = Yup.string().trim().required('State is required.');
var postal = Yup.string().trim().required('ZIP is required.');
var address = Yup.object().shape({
  street1: street1,
  city: city,
  region: state,
  postal: postal
});
var emailConfirmation = Yup.string().trim().oneOf([Yup.ref('email'), null], 'Emails don\'t match').required('Email confirmation is required');
exports.emailConfirmation = emailConfirmation;
var password = Yup.string().trim().required('Password is required');
exports.password = password;
var passwordConfirmation = Yup.string().trim().oneOf([Yup.ref('password'), null], 'Passwords don\'t match').required('Password confirmation is required');
exports.passwordConfirmation = passwordConfirmation;
var genderError = 'A gender selection is required for calculation purposes.';
var gender = Yup.string().required(genderError);
var Numeric = Yup.number().typeError('Value must be a number').positive('Value must be greater than zero');
var age = Numeric.required('Age is required.');
var weightLb = Numeric.required('Weight is required.');
var heightIn = Numeric.required('Height is required.');
var qty = Yup.number().required('Quantity is required.').typeError('Quantity must be a number');
var unit = Yup.string().required('Unit is required.');
var type = Yup.string().required('Type is required.');
var ingredient = Yup.object().shape({
  qty: qty,
  unit: unit
});
var ingredients = Yup.array().of(ingredient).min(1, 'At least one ingredient must be included.');
var weightGoal = Yup.string().trim(). // oneOf(['gain', 'lose', 'maintain'], 'Invalid selection').
required('Goal selection is required.');
var buildMuscle = Yup.boolean().nullable().required('Selection is required');
var mmyyRegex = /^(0[1-9]|1[0-2])\/?(2[0-9])*[0-9][0-9]$/;
var mmyy = Yup.string().trim().required().matches(mmyyRegex, 'Please use the format MM/YY or MM/YYYY.');
var anySelection = Yup.string().trim().nullable(). // oneOf(['gain', 'lose', 'maintain'], 'Invalid selection').
required('A selection is required.');
var signupSchema = Yup.object().shape({
  firstName: firstName,
  lastName: lastName,
  email: email,
  password: password,
  passwordConfirmation: passwordConfirmation
});
exports.signupSchema = signupSchema;
var resetPasswordSchema = Yup.object().shape({
  password: password,
  passwordConfirmation: passwordConfirmation
});
exports.resetPasswordSchema = resetPasswordSchema;
var profileDemoSchema = Yup.object().shape({
  gender: gender,
  age: age,
  weightLb: weightLb,
  heightIn: heightIn
});
exports.profileDemoSchema = profileDemoSchema;
var profileGoalsSchema = Yup.object().shape({
  weightGoal: weightGoal,
  buildMuscle: buildMuscle,
  weightChange: Yup.number().when('weightGoal', {
    is: function is(val) {
      return val === 'maintain';
    },
    then: Yup.number().nullable().notRequired(),
    otherwise: Numeric.required('Value is required.')
  }) // weightChange: '',
  // bodyFatPct: '',

});
exports.profileGoalsSchema = profileGoalsSchema;
var profileActivitySchema = Yup.object().shape({
  activityLevel: anySelection
});
exports.profileActivitySchema = profileActivitySchema;
var profileMealsSchema = Yup.object().shape({
  mealDays: anySelection,
  mealPlanStart: anySelection,
  mealVariety: anySelection,
  mealSplit: anySelection
});
exports.profileMealsSchema = profileMealsSchema;
var profilePaymentSchema = Yup.object().shape({
  cc: Yup.object().shape({
    num: anyValue.matches(/^\d{14,19}$/, 'Card number must be 14-19 digits.'),
    mmyy: mmyy,
    cvc: anyValue.matches(/^\d{3,4}$/, 'CVC must be 3-4 digits.'),
    zip: anyValue // .matches(/^\d{5}(\-?\d{4})*$/, 'ZIP must be 5 or 9 digits',),

  })
});
exports.profilePaymentSchema = profilePaymentSchema;
var recipeEditSchema = Yup.object().shape({
  type: type,
  servingSize: Numeric,
  ingredients: ingredients
}); // export const parentSchema = Yup.object().shape({
//   firstName,
//   lastName,
//   relationship,
//   useFamilyAddress: Yup.boolean(),
//   address: Yup.object().when('useFamilyAddress', {
//     is: true,
//     then: Yup.object().notRequired(),
//     otherwise: address,
//   }),
//   phones: Yup.array().of(phone),
//   emails: Yup.array().of(email),
// })
//
// export const studentSchema = Yup.object().shape({
//   firstName,
//   lastName,
//   // phone,
//   // tag,
//   schoolId,
//   gradeLevel,
//   // teacherId,
//   // livesWith,
// })
//
// export const contactSchema = Yup.object().shape({
//   firstName,
//   lastName,
//   relationship,
//   phones: Yup.array().of(phone),
// })
//
// export const offCampusSchema = Yup.object().shape({
//   name,
//   mobilePhone: phone,
//   email,
//   address,
// })
//
// export const scheduleFormSchema = Yup.object().shape({
//   // days: Yup.array().compact(d => { return !d.active }).min(1, 'At least one day must be selected.'),
//   dismissalType: Yup.string().required('A dismissal selection is required.'),
//   driverId: Yup.string().required('A driver selection is required.'),
//   // recurrence: Yup.string().required('A recurrence selection is required.'),
// })
//
// export const scheduleFormWithFriendSchema = Yup.object().shape({
//   // days: Yup.array().compact(d => { return !d.active }).min(1, 'At least one day must be selected.'),
//   dismissalType: Yup.string().required('A dismissal selection is required.'),
//   leavingWithId: Yup.string().required('A student selection is required.'),
//   // recurrence: Yup.string().required('A recurrence selection is required.'),
// })
//
// export const scheduleFormDriverOrFriendSchema = Yup.object().shape({
//   // days: Yup.array().compact(d => { return !d.active }).min(1, 'At least one day must be selected.'),
//   dismissalType: Yup.string().required('A dismissal selection is required.'),
//   driverId: Yup.string().test('driverId', 'A driver selection is required.', function (value) {
//     const leavingWithId = get(this, 'parent.leavingWithId')
//     // console.log('driver test', value, leavingWithId, this.parent)
//     if (!leavingWithId) return Boolean(value)
//     // console.log('assuming driver is valid then', value)
//     return Boolean(leavingWithId) && !Boolean(value)
//   }),
//   leavingWithId: Yup.string().test('leavingWithId', 'A student selection is required.', function (value) {
//     const driverId = get(this, 'parent.driverId')
//     // console.log('leaving with test', value, driverId)
//     if (!driverId) return Boolean(value)
//     // console.log('assuming ride is valid then', value)
//     return Boolean(driverId) && !Boolean(value)
//   }),
// })

exports.recipeEditSchema = recipeEditSchema;