import React, { Fragment } from 'react'

import { Link } from 'react-router-dom'

import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import { colors } from '@balance/lib/styles'

import {
  Person, AccountCircle, Food, Grocery, SignOut, Settings, Payment, Clock, Help as HelpIcon, Warning,
} from '../../styles/icons'

import { version as appVersion } from '../../../package.json'

const ListIcon = (props) => <ListItemIcon style={{ color: colors.darkGreen }} {...props} />

export const loggedInMenu = (user) => {
  return (
    <div>
      <Link to='/meal-plan'>
        <ListItem button>
          <ListIcon>
            <Food />
          </ListIcon>
          <ListItemText primary="Meal Plan" />
        </ListItem>
      </Link>
      <Link to='/grocery-list'>
        <ListItem button>
          <ListIcon>
            <Grocery />
          </ListIcon>
          <ListItemText primary="Grocery List" />
        </ListItem>
      </Link>
      <Link to='/recipes' data-tour="menu-option-recipe-list">
        <ListItem button>
          <ListIcon>
            <Food />
          </ListIcon>
          <ListItemText primary="Recipe List" />
        </ListItem>
      </Link>
      <Link to='/profile/meals'>
        <ListItem button>
          <ListIcon>
            <Settings />
          </ListIcon>
          <ListItemText primary="Meal Settings" />
        </ListItem>
      </Link>

      <Divider />

      <Link to='/profile/demo'>
        <ListItem button>
          <ListIcon>
            <Person />
          </ListIcon>
          <ListItemText primary="User Profile" />
        </ListItem>
      </Link>
      <Link to='/profile/payment'>
        <ListItem button>
          <ListIcon>
            <Payment />
          </ListIcon>
          <ListItemText primary="Billing Details" />
        </ListItem>
      </Link>

      <Divider />
      <Link to="/help">
        <ListItem button>
          <ListIcon>
            <HelpIcon />
          </ListIcon>
          <ListItemText primary="Help" />
        </ListItem>
      </Link>
      <Link to="/logout">
        <ListItem button>
          <ListIcon>
            <SignOut />
          </ListIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </Link>
    </div>
  )
}

export const loggedOutMenu = (
  <div>
    <Link to="/login">
      <ListItem button>
        <ListIcon>
          <SignOut />
        </ListIcon>
        <ListItemText primary="Login" />
      </ListItem>
    </Link>
  </div>
)

export const drawerHeader = (user) => {

  const buyNowText = <span>{user.trialDaysLeft} days remaining<br /><Link to="/profile/payment">Sign up now!</Link></span>
  const renewNowText = <span><Link to="/profile/payment">Renew now!</Link></span>
  return (
    <Fragment>
      <ListItem>
        <ListIcon>
          <AccountCircle />
        </ListIcon>
        <ListItemText primary={`Hello${(user && user.firstName && `, ${user.firstName}`) || ''}!`} />
      </ListItem>
      {user.status === 'trial' && (
        <ListItem>
          <ListIcon>
            <Clock />
          </ListIcon>
          <ListItemText primary={`Trial Access`} secondary={buyNowText} />
        </ListItem>
      )}
      {['lapsed', 'inactive', 'cancelled'].includes(user.status) && (
        <ListItem>
          <ListIcon>
            <Warning />
          </ListIcon>
          <ListItemText primary="Account Inactive" secondary={renewNowText} />
        </ListItem>
      )}
    </Fragment>
  )
}

export const drawerFooter = (
  <ListItem>
    <ListItemText inset={true} primary={`Version ${appVersion}`} />
  </ListItem>
)
