// @flow
import React, { PureComponent } from 'react'
import { withStyles, Paper, Divider, Typography } from '@material-ui/core'
import get from 'lodash/get'
import capitalize from 'lodash/capitalize'
import memoize from 'memoize-one'
import classnames from 'classnames'

import Grid from '@material-ui/core/Grid'

import { asPct } from '@balance/lib/utils'

import { PageTitle } from '../PageTitle'
import { FullPageSpinner } from '../Global'
import baseStyles from '../../styles/global'
import printStyles from '../../styles/print'

import MealPanel from './MealPanel'
import TypeChip from '../Chip/TypeChip'
import { ButtonLink, InlineButton } from '../Button'

import { Grocery, Restaurant } from '../../styles/icons'
import WeekToggle from './WeekToggle'
import { Alert } from '../Modal'

type MealPlan = {
  dayCount: number,
  days: Array<Object>,
  meals: Array<Object>,
}
type Props = {
  plan: MealPlan,
  user: Object,
  onReplaceMeal: Function,
  onReplaceMealPlan: Function,
  onRemoveMeal: Function,
  onAddRecipe: Function,
  onSearchRecipe: Function,
  week?: ?string,
}
type State = {
  alertOpen: boolean,
}

const styles = (theme) => {
  return {
    ...baseStyles(theme),
    day: {
      width: '100%',
      padding: theme.spacing.unit,
      margin: [[theme.spacing.unit, 0]],
    },
    table: {
      width: '100%',
      // minWidth: 500,
    },
    thead: {
      color: '#3d3d3d',
      fontSize: 10,
      fontWeight: '500',
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
    },
    summary: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
    },
    summaryItem: {
      textAlign: 'center',
      marginBottom: 8,
    },
    amount: {
      marginTop: 4,
      '& span': {
        display: 'block',
      },
    },
    ...printStyles(theme),
  }
}

class MealPlanByDay extends PureComponent<Props, State> {
  static defaultProps = {
    plan: {
      days: [],
      meals: [],
    },
    user: { profile: {} },
    week: '',
  }
  state = {
    alertOpen: false,
  }

  mealsSortedByTypeThenName = memoize((mealids, allMeals) => {
    const mealCount = get(this.props, 'user.profile.mealCount', 0)
    const snackCount = get(this.props, 'user.profile.snackCount', 0)

    // console.log('mealids', mealids, mealCount, snackCount)
    const meals = { breakfast: [], regular: [], snack: [] }
    mealids.forEach(m_id => {
      const meal = allMeals.find(m => m.id === m_id)
      // console.log('meal?', meal)
      meals[meal.type].push(meal)
    })
    // console.log('meals', meals)

    let mealNum = 1
    let fake = { recipeIds: [], empty: true }
    let sparse = []
    if (meals['breakfast'].length > 0) {
      sparse = [...meals['breakfast']]
      mealNum += meals['breakfast'].length
    } else {
      sparse = [{ ...fake, type: 'breakfast', number: mealNum }]
      mealNum += 1
    }

    let sortedRegular = meals['regular'] //.sort(sortByName)
    sparse = [...sparse, ...sortedRegular]
    mealNum += sortedRegular.length
    let missing = mealCount - 1 /*breakfast */ - sortedRegular.length
    // console.log('missing meals', missing)
    for (let i = 0; i < missing; i++) {
      sparse = [...sparse, { ...fake, type: 'regular', number: mealNum }]
      mealNum += 1
    }

    let sortedSnacks = meals['snack'] //.sort(sortByName)
    sparse = [...sparse, ...sortedSnacks]
    mealNum += sortedSnacks.length
    missing = snackCount - sortedSnacks.length
    // console.log('missing snacks', missing)
    for (let i = 0; i < missing; i++) {
      sparse = [...sparse, { ...fake, type: 'snack', number: mealNum }]
      mealNum += 1
    }

    // console.log('sparse', sparse)
    return sparse
  })

  mealsSortedByTypeThenNumber = memoize((mealids, allMeals) => {
    const mealCount = get(this.props, 'user.profile.mealCount', 0)
    const snackCount = get(this.props, 'user.profile.snackCount', 0)

    // console.log('mealids', mealids, allMeals, mealCount, snackCount)
    const meals = { breakfast: [], regular: [], snack: [] }
    mealids.forEach(m_id => {
      const meal = allMeals.find(m => m.id === m_id)
      // console.log('meal?', meal)
      meals[meal.type].push(meal)
    })
    // console.log('meals', meals)

    let fake = { recipeIds: [], empty: true }
    let sequence = [] // new Array(mealCount + snackCount)
    if (meals['breakfast'].length > 0) {
      sequence = [...meals['breakfast']] // should only be 1, but in the future ....?
    } else {
      sequence = [{ ...fake, type: 'breakfast', number: 1 }]
    }

    let mealNumStart = 2
    for (let num = mealNumStart; num <= mealCount; num++) {
      let nextMeal = meals['regular'].find(m => m.number === num)
      if (nextMeal) {
        sequence[num - 1] = nextMeal
      } else {
        sequence[num - 1] = { ...fake, type: 'regular', number: num }
      }
    }

    mealNumStart = mealCount + 1
    const snackMax = mealCount + snackCount
    for (let num = mealNumStart; num <= snackMax; num++) {
      let nextMeal = meals['snack'].find(m => m.number === num)
      if (nextMeal) {
        sequence[num - 1] = nextMeal
      } else {
        sequence[num - 1] = { ...fake, type: 'snack', number: num }
      }
    }

    // console.log('sequence', sequence)
    return sequence
  })

  recipeUseCount = memoize(plan => {
    if (!plan || !plan.meals) { return null }
    return plan.meals.reduce((acc, cur) => {
      cur.recipeIds.forEach(rId => { if (rId in acc) { acc[rId]++ } else { acc[rId] = 1 } })
      return acc
    }, {})
  })

  handleClose = () => {
    this.setState({ alertOpen: false })
  }

  handleReplaceMealPlan = () => {
    this.setState({ alertOpen: true })
  }

  handleReplaceMealPlanConfirmed = async () => {
    const { onReplaceMealPlan, week } = this.props
    this.handleClose()
    await onReplaceMealPlan(week)
  }

  renderDaySummary = (day) => {
    const { classes } = this.props

    const items = [
      ['Protein (g)', `${day.protein}`, `(${asPct(day.proteinPct, 1)}%)`],
      ['Fat (g)', `${day.fat}`, `(${asPct(day.fatPct, 1)}%)`],
      ['Carbs (g)', `${day.carbs}`, `(${asPct(day.carbsPct, 1)}%)`],
      ['Fiber (g)', `${day.fiber}`, ''],
      ['Sugar (g)', `${day.sugar}`, ''],
      ['Sodium (mg)', `${day.sodium}`, ''],
    ]
    return (
      <Grid container className={classes.summary}>
        {items.map((item, i) => (
          <Grid key={i} item xs={4} sm={2} className={classes.summaryItem}>
            <label className={classes.thead}>{item[0]}</label><br />
            <label className={classes.amount}>
              {item[1]}
              {item[2] && <span>{item[2]}</span>}
            </label>
          </Grid>
        ))}
      </Grid>
    )
  }

  renderDay = (day) => {
    const { classes, plan, user, onReplaceMeal, onRemoveMeal, onAddRecipe, onSearchRecipe, week } = this.props
    const allMeals = get(plan, 'meals', [])
    const allRecipes = get(plan, 'recipes', [])
    const meals = this.mealsSortedByTypeThenNumber(day.mealIds, allMeals)

    const canModify = week !== 'last'

    // console.log('renderDay', this.recipeUseCount(plan), plan, day, meals, user)
    return (
      <Paper key={day.number} className={classes.day}>
        <Typography variant="h5" className={classes.heading}>
          {day.label} - {day.kcal} calories
        </Typography>
        {false && day.message && (
          <Typography variant="p" color="textSecondary">
            {day.message}
          </Typography>
        )}
        <Divider />
        <div>
          {meals.map((meal, i) => {
            const recipes = allRecipes.filter(r => meal.recipeIds.includes(r.id))
            return <MealPanel key={i} meal={meal} user={user} recipes={recipes}
                              recipeUseCount={this.recipeUseCount(plan)}
                              onReplaceMeal={canModify ? (meal, all, withRecipeId = null) => onReplaceMeal(day.number, meal, all, withRecipeId) : null}
                              onRemoveMeal={canModify ? (meal) => onRemoveMeal(meal) : null}
                              onAddRecipe={(recipe) => onAddRecipe(recipe, day.number, meal.number)}
                              onSearchRecipe={onSearchRecipe}
            />
          })}
        </div>
        <Divider />
        {this.renderDaySummary(day)}
      </Paper>
    )
  }

  renderAside = () => {
    const { week } = this.props
    let weekQS = week ? `?week=${week}` : ''
    let aside = []
    aside.push(
      <ButtonLink key="0" size="small" to={`/meal-prep${weekQS}`} secondary variant="text" data-tour="meal-prep">
        <Restaurant />
        Meal Prep
      </ButtonLink>,
    )
    aside.push(
      <ButtonLink key="1" size="small" to={`/grocery-list${weekQS}`} secondary variant="text" data-tour="grocery-list">
        <Grocery />
        Grocery List
      </ButtonLink>,
    )
    return aside
  }

  render () {
    // console.log('MealPlanByDay.render', this.props)
    const { classes, plan, user, loading, week } = this.props
    if (!user || !user.profile) {
      return null
    }
    const { profile: { dci, nutrientTargetPct } } = user
    const days = get(plan, 'days', [])
    const mismatch = get(plan, 'mismatch', false)
    const nutrients = nutrientTargetPct.map(n => `${n.target}% ${capitalize(n.nutrient)}`)

    return (
      <div className={classes.container}>
        <div className={classnames(classes.printOneLine, classes.printTitle)}>
          <PageTitle title="Current Meal Plan" noIcon showHelp aside={this.renderAside()} condensed />
          <WeekToggle week={week} weekOfShort={plan.weekOfShort} />
        </div>

        <p>
          Daily Calorie Target: {dci}<br />
          Target Nutrients: <span style={{ whiteSpace: 'nowrap' }}>{nutrients.join(' / ')}</span><br />
          <em>Note</em>: The timing of meal consumption is up to user discretion.<br />
          {week !== 'last' && (
            <span>
              (If you want to replace this meal plan with {week === 'next' ? 'this' : 'last'} week's, <InlineButton
              onClick={this.handleReplaceMealPlan}>click here</InlineButton>.)
            </span>
          )}
        </p>
        {mismatch && (
          <p><TypeChip label="random" /> indictates that we were unable to find a recipe to match all of your
            preferences,
            and thus one has been randomly chosen to match a subset of your dietary needs.</p>
        )}

        {days.map(day => this.renderDay(day))}

        <Alert onCancel={this.handleClose}
               onProceed={this.handleReplaceMealPlanConfirmed}
               proceedLabel="Replace it!"
               dangerous
               open={this.state.alertOpen}
               title="Confirm Deletion"
               text="Are you sure you want to delete this meal plan and copy the prior week's plan?" />
        <FullPageSpinner open={loading} />
      </div>
    )
  }
}

export default withStyles(styles)(MealPlanByDay)
