import { colors } from '.'

import globalStyles from './global'

export const formStyles = theme => {
  const global = globalStyles(theme)
  return {
    ...global,
    // container: {
    //   ...global.container,
    // },
    wrapper: {
      maxWidth: 500,
      margin: `${theme.spacing.unit * 2}px auto`,
    },
    textField: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      width: '100%',
      // flexBasis: 200,
    },
    textarea: {
      marginLeft: 0, // theme.spacing.unit,
      marginRight: 0, // theme.spacing.unit,
      padding: [[0, theme.spacing.unit]],
      width: '100%',
      // height: 150,

      '& label': {
        left: theme.spacing.unit,
      },
    },
    button: {
      margin: theme.spacing.unit,
    },
    menu: {
      width: 200,
    },
    link: {
      display: 'inline-block',
      color: colors.primary,
      margin: '1rem 10px 0',
      textDecoration: 'none',
    },
    listItem: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: theme.spacing.unit,
      '& > div': {
        paddingTop: 12, // static number from @material/IconButton
      },
      '& strong': {
        display: 'block',
        fontWeight: 500,
      },
      '& div span': {
        display: 'block',
        marginBottom: 12, // counter above padding
      },
    },
    checkboxOption: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    checkboxListItem: {},
    checkboxGroup: {
      display: 'block',
    },
    checkbox: {
      padding: 0,
      marginRight: theme.spacing.unit / 2,
    },
    checkboxWrappable: {

      [theme.breakpoints.up(400)]: {
        display: 'inline-flex',
        width: '50%',
      },

      [theme.breakpoints.up('md')]: {
        width: '33%',
      },

      [theme.breakpoints.up('lg')]: {
        width: '25%',
      },
    },
    radioListItem: {
      // '&:not(:last-child)': {
      //   borderBottom: '1px solid #ccc',
      // },
    },
    overrideWarning: {
      margin: 0,
    }
  }
}

export default formStyles
