// @flow
import React, { PureComponent } from 'react'

import { withStyles } from '@material-ui/core/styles'

import Button from '../Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

type Props = {
  classes: Object,
  children?: any,
  onCancel: Function,
  cancelLabel: String,
  onProceed: Function,
  proceedLabel: String,
  dangerous: Boolean,
  title?: string,
  text?: string,
  open: boolean,
}

type State = {
  open: boolean,
}

const styles = theme => ({
  danger: {
    color: 'white',
    backgroundColor: theme.palette.error.main,
    borderColor: theme.palette.error.main,

    '&:hover, &:active, &:focus': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  paper: {
    width: '50vw',
  },
  buttons: {
    textAlign: 'right'
  }
})

class AlertDialog extends PureComponent<Props, State> {
  static defaultProps = {
    open: false,
    dangerous: false,
    cancelLabel: 'Cancel',
    proceedLabel: 'Proceed',
  }

  constructor (props: Props) {
    super(props)

    this.state = {
      open: props.open,
    }
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    if (newProps.open !== this.state.open) {
      this.setState({open: newProps.open})
    }
  }

  handleCancel = () => {
    const {onCancel} = this.props
    onCancel && onCancel()
    this.setState({open: false})
  }

  handleProceed = () => {
    const {onProceed} = this.props
    onProceed && onProceed()
    this.setState({open: false})
  }

  render () {
    // console.log('Alert.render', this.props, this.state)
    const {classes, children, title, text, cancelLabel, proceedLabel, dangerous} = this.props

    const proceedProps = dangerous ? {className: classes.danger, color: 'default', variant: 'contained'} : {}

    return (
      <Dialog
        maxWidth="sm"
        fullWidth
        open={this.state.open}
        onClose={this.handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title || 'Alert'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {text || 'Your message here...'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {children}
          <Button onClick={this.handleCancel}>
            {cancelLabel}
          </Button>
          <Button onClick={this.handleProceed} color="primary" {...proceedProps}>
            {proceedLabel}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(AlertDialog)
