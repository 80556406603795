// @flow
import React, { Component } from 'react'
import { Mutation } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'

import { UPDATE_USER } from '@balance/lib/api/user'

import { Onboarding } from '../../components/Onboarding'

type Props = {
  currentUser: Object,
  onboarded: ?Array<string>,
  path: string,
}

const DISABLE_ONBOARDING = false

class OnboardingContainer extends Component<Props> {

  render () {
    const { onboarded, currentUser } = this.props
    const path = get(this.props, 'location.pathname')
    const hasPlanAccess = get(currentUser, 'hasPlanAccess')
    if (!hasPlanAccess || DISABLE_ONBOARDING) {
      return null
    }
    return (
      <Mutation mutation={UPDATE_USER}>
        {(updateUser, { data, loading, error }, ...rest) => {
          // console.log('onboarding container props', data, currentUser)

          const handleSaveStep = async (step) => {
            // console.log('handleSaveStep start', step)
            const result = await updateUser({ variables: { attributes: { id: currentUser.id, onboardedStep: step } } })
            // console.log('handleSaveStep result', result)
            return result
          }

          return (
            <Onboarding onboarded={onboarded} path={path} onTourEnd={handleSaveStep} />
          )
        }}
      </Mutation>
    )
  }
}

export default withRouter(OnboardingContainer)
