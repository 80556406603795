import React, { Component } from 'react'
import { ApolloConsumer, Query } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'

import { CurrentUserConsumer } from '@balance/lib/contexts'
import { GET_PAYMENT_DETAILS, SAVE_PAYMENT_DETAILS, START_CHECKOUT, VALIDATE_RECEIPT } from '@balance/lib/api/payment'

import { PaymentEdit } from '../../components/Payment'

class PaymentEditContainer extends Component {
  state = {
    lastFetchedAt: 0,
  }

  // constructor (props) {
  //   super(props)
  //   // console.log('PaymentEditContainer()', this.state)
  // }

  // componentWillReceiveProps = (nextProps) => {
  //   console.log('PaymentEditContainer.cWRP', nextProps, this.state, this.props)
  // }

  // componentDidUpdate = () => {
  //   console.log('PaymentEditContainer.dU', this.state)
  // }

  render () {
    // const step = get(this.props, 'match.params.step')
    // console.log('profile step', step, this.state)
    // console.log('profile payment container.render', this.state)
    return (
      <CurrentUserConsumer>
        {({ state, refetch }) => {
          // console.log('PaymentEditContainer.user consumer', state)

          const now = new Date()
          const gap = this.state.lastFetchedAt > 0 ? now - this.state.lastFetchedAt : 1000
          // console.log('gap', gap, this.state.lastFetchedAt)
          if (refetch && gap > 500) {
            this.setState({ lastFetchedAt: now })
            // refetch()
          }

          return (
            <Query query={GET_PAYMENT_DETAILS}>
              {({ data, loading, ...rest }) => {
                // console.log('payment data', loading, data, rest)

                const payment = get(data, 'paymentDetails')
                // console.log('payment', payment)

                return (
                  <ApolloConsumer>
                    {(client) => {

                      const startCheckout = async () => {
                        // console.log('startCheckout')
                        const { data } = await client.query({
                          query: START_CHECKOUT,
                          fetchPolicy: 'network-only',
                        })

                        return get(data, 'startCheckout')
                      }

                      const savePayment = async (details) => {
                        console.log('savePayment', details)
                        const { data } = await client.mutate({
                          mutation: SAVE_PAYMENT_DETAILS,
                          variables: { attributes: details },
                        })
                        console.log('savePayment result', data)
                        refetch && refetch()

                        return get(data, 'savePayment')
                      }

                      const validateReceipt = async (receiptData) => {
                        console.log('validateReceipt()', receiptData)
                        const { data } = await client.mutate({
                          mutation: VALIDATE_RECEIPT,
                          variables: { userId: state.id, receiptData },
                        })
                        console.log('validateReceipt result', data)
                        return get(data, 'valid', false)
                      }

                      return (
                        <PaymentEdit user={state}
                                     payment={payment}
                                     startCheckout={startCheckout}
                                     savePayment={savePayment}
                                     validateReceipt={validateReceipt}
                        />
                      )
                    }}
                  </ApolloConsumer>
                )
              }}
            </Query>
          )
        }}
      </CurrentUserConsumer>
    )
  }
}

export default withRouter(PaymentEditContainer)
