// @flow
import React, { PureComponent } from 'react'
import get from 'lodash/get'
import { withStyles } from '@material-ui/core'
import { Button } from '../Button'
import styles from '../../styles/global'
import {
  getProducts, isAndroid, isIos, MONTHLY_FEE_PRODUCT_ID, restorePurchases, subscribe,
} from '../../lib/inAppPurchasing'

type Props = {
  onPayWithCC: Function,
  saveReceipt: Function,
  existingPayment: Object,
}
type State = {
  error: ?String,
}

const formatError = (err) => {
  if (err) {
    if (err.message && err.text) {
      return `${err.message}: ${err.text}`
    } else {
      return `${err.message}${err.text}`
    }
  }
}

class InAppPayment extends PureComponent<Props, State> {
  static defaultProps = {}
  state = {
    products: [],
    error: '',
  }

  async componentDidMount () {
    await this.handleGetProducts()
  }

  handleGetProducts = async () => {
    try {
      const products = await getProducts()
      // console.log('handleGetProducts', products)
      this.setState({ products })
    } catch (err) {
      // console.log('error', err)
      if (err && err.message) {
        this.setState({ error: formatError(err) })
      }
    }
  }

  handleSubscribe = async () => {
    try {
      this.setState({ error: null })
      const data = await subscribe(MONTHLY_FEE_PRODUCT_ID)
      // console.log('handleSubscribe', data)
      /*const result = */this.props.saveReceipt && await this.props.saveReceipt(data)
    } catch (err) {
      // console.log('handleSubscribe error', err)
      if (err && err.message) {
        this.setState({ error: formatError(err) })
      }
    }
  }

  handleAndroidTestSubscribeSuccess = async () => {
    try {
      this.setState({ error: null })
      const data = await subscribe('com.balanceondemand.app.monthly_fee')
      // console.log('handleSubscribe2', data)
      /*const result = */this.props.saveReceipt && await this.props.saveReceipt(data)
    } catch (err) {
      // console.log('handleSubscribe2 error', err)
      if (err && err.message) {
        this.setState({ error: formatError(err) })
      }
    }
  }

  restorePurchases = async () => {
    try {
      const data = await restorePurchases()
      // console.log('restorePurchases', data)
      let count = data.length
      for (let i = 0; i < count; i++) {
        let receipt = data[i]
        if (receipt && receipt.productId === MONTHLY_FEE_PRODUCT_ID) {
          if (receipt.state === 3) {

          }
        }
      }
    } catch (err) {
      // console.log('restorePurchases error', err)
    }
  }

  handlePayWithCC = () => {
    this.props.onPayWithCC && this.props.onPayWithCC()
  }

  renderError = () => {
    if (this.state.error) {
      return (
        <div className={this.props.classes.error}><p>{this.state.error}</p></div>
      )
    }
  }

  render () {
    const { existingPayment } = this.props
    // console.log('maybe rendering inAppPayment', window.device)

    const platform = get(window, 'device.platform')
    if (!platform) { return null }

    if (existingPayment && existingPayment.isIap && existingPayment.iapStore !== platform) {
      return (
        <div>
          You are already subscribed via the {existingPayment.iapStore} App Store!
        </div>
      )
    }

    if (isIos()) {
      // console.log('paying with iOS')
      return (
        <div>
          <Button primary onClick={this.handleSubscribe}>
            Subscribe Now
          </Button>
          {/*<Button variant="text" size="small" onClick={this.restorePurchases}>*/}
          {/*  Restore Purchases*/}
          {/*</Button>*/}
          {/*<Button variant="text" size="small" onClick={this.props.validateReceipt}>*/}
          {/*  Validate Receipt*/}
          {/*</Button>*/}
          {/*<p>*/}
          {/*  -- OR --*/}
          {/*</p>*/}
          {/*<Button variant="outlined" onClick={this.handlePayWithCC}>*/}
          {/*  Subscribe via credit card*/}
          {/*</Button>*/}

          {this.renderError()}

        </div>
      )
    } else if (isAndroid()) {
      // console.log('paying with google')
      return (
        <div>
          <Button primary onClick={this.handleSubscribe}>
            Subscribe Now
          </Button>
          {/*{(true || IS_DEV) && (*/}
          {/*  <Button secondary onClick={this.handleAndroidTestSubscribeSuccess}>*/}
          {/*    [TEST] Subscribe Now*/}
          {/*  </Button>*/}
          {/*)}*/}

          {this.renderError()}
        </div>
      )
    } else if (this.state.error) {
      return this.renderError()
    }
    console.log('ran out of native payment options!')
    return null
  }
}

export default withStyles(styles)(InAppPayment)
