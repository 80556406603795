// @flow
import React, { PureComponent } from 'react'

type Props = {}
type State = {}

class MealPlanSummary extends PureComponent<Props, State> {
  static defaultProps = {}

  render () {
    return (
      <div>
        Meal Plan Summary
      </div>
    )
  }
}

export default MealPlanSummary
