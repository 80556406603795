import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'

const ALLOW_ENV_CHANGING = false

// source: https://stackoverflow.com/a/30071105
const onAppend = function (elem, f) {
  if (ALLOW_ENV_CHANGING) {
    var observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (m) {
        if (m.addedNodes.length) {
          f(m.addedNodes)
        }
      })
    })
    observer.observe(elem, { childList: true })
  }
}

if (ALLOW_ENV_CHANGING) {
  onAppend(document.body, function (added) {
    console.log(added) // [p]
    startApp()
  })
}

function preStart () {
  if (!ALLOW_ENV_CHANGING) {
    startApp()
    return
  }

  console.log('preStart', window.NativeStorage)
  if (window.cordova && window.NativeStorage) {
    console.log('preStart check 2')
    window.NativeStorage.getItem('use_environment', changeEnvironment, nativeStorageFailed)
    // window.NativeStorage.getItem('use_staging', changeToStaging, nativeStorageFailed)
    // window.NativeStorage.getItem('use_dev', changeToDev, nativeStorageFailed)
  } else {
    startApp()
    // loadEnvFile('./env.js')
  }
}

const changeEnvironment = (env) => {
  if (!ALLOW_ENV_CHANGING) {
    startApp()
    return
  }
  console.log('changeEnvironment: got data!', env)
  if (env && ['production', 'staging', 'dev'].includes(env)) {
    console.log('setting up environment ....')
    console.log('current: ', window.BOD_API_URL)
    if (env === 'staging') {
      if (window.BOD_STAGING_BASE_URL) {window.BOD_BASE_URL = window.BOD_STAGING_BASE_URL}
      if (window.BOD_STAGING_API_URL) {window.BOD_API_URL = window.BOD_STAGING_API_URL}
      if (window.BOD_STAGING_ENV) {window.BOD_ENV = window.BOD_STAGING_ENV}
    } else if (env === 'dev') {
      if (window.BOD_DEV_BASE_URL) {window.BOD_BASE_URL = window.BOD_DEV_BASE_URL}
      if (window.BOD_DEV_API_URL) {window.BOD_API_URL = window.BOD_DEV_API_URL}
      if (window.BOD_DEV_ENV) {window.BOD_ENV = window.BOD_DEV_ENV}
    } else {
      if (window.BOD_PROD_BASE_URL) {window.BOD_BASE_URL = window.BOD_PROD_BASE_URL}
      if (window.BOD_PROD_API_URL) {window.BOD_API_URL = window.BOD_PROD_API_URL}
      if (window.BOD_PROD_ENV) {window.BOD_ENV = window.BOD_PROD_ENV}
    }
    console.log('API_URL now: ', window.BOD_API_URL)
    console.log('BASE_URL now: ', window.BOD_BASE_URL)
  } else {
    console.log('using production env')

    // loadEnvFile('./env.js')
  }

  startApp()
}

// const loadEnvFile = (filename) => {
//   const script = document.createElement('script')
//   script.src = filename
//   document.body.appendChild(script)
//
//   // setTimeout(startApp, 500)
//   // startApp()
// }

const nativeStorageFailed = (data) => {
  console.log('failed to get data!', data)
  // loadEnvFile('./env.js')
  startApp()
}

function startApp () {
  ReactDOM.render(<App />, document.getElementById('root'))
}

if (window.cordova) {
  document.addEventListener('deviceready', preStart, false)
} else {
  preStart()
  // startApp()
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
