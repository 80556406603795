// @flow
import React, { PureComponent } from 'react'
import Help from '../../components/Page/Help'

type Props = {}
type State = {}

class HelpContainer extends PureComponent<Props, State> {
  static defaultProps = {}

  render () {
    return (
      <Help />
    )
  }
}

export default HelpContainer
