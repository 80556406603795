// @flow
import React, { PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'

import { Button } from '../Button'
import { Email, Help as HelpIcon } from '../../styles/icons'
import HelpContent from './HelpContent'

function Transition (props) {
  return <Slide direction="up" {...props} />
}

const styles = theme => ({
  help: {
    display: 'inline-block',

    // [theme.breakpoints.down('xs')]: {
    //   position: 'absolute',
    //   top: -50,
    //   right: 10,
    // },
  },
  wrapper: {
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    border: '1px solid #ddd',
    overflowY: 'scroll',

    '&::-webkit-scrollbar': {
      width: 5,
      height: 5,
    },

    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 3px rgba(0,0,0,0.3)',
      borderRadius: 20,
    },

    '&::-webkit-scrollbar-thumb': {
      borderRadius: 20,
      backgroundColor: 'rgba(0, 0, 0, .3)',
      '-webkit-box-shadow': 'inset 0 0 3px rgba(0,0,0,0.5)',
    },

  },
  content: {},
  buttonIcon: {
    marginRight: theme.spacing.unit,
  },
})

type Props = {}
type State = {
  helpShowing: boolean,
}

class HelpButton extends PureComponent<Props, State> {
  static defaultProps = {}
  state = {
    helpShowing: false,
  }

  toggleHelp = () => {
    this.setState(prevState => ({ helpShowing: !prevState.helpShowing }))
  }

  render () {
    const { classes } = this.props
    return (
      <div className={classes.help}>
        <IconButton onClick={this.toggleHelp}>
          <HelpIcon color="primary" />
        </IconButton>
        <Dialog
          open={this.state.helpShowing}
          scroll="paper"
          TransitionComponent={Transition}
          keepMounted
          onClose={this.toggleHelp}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            Helpful Information
          </DialogTitle>
          <DialogContent className={classes.wrapper}>

            <HelpContent />

          </DialogContent>
          <DialogActions>
            <Button component='a' href="mailto:help@balanceondemand.com" color="secondary">
              <Email className={classes.buttonIcon} />
              Email support
            </Button>
            <Button onClick={this.toggleHelp} color="primary">
              Got it!
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default withStyles(styles)(HelpButton)
