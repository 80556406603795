import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Formik, Form, getIn } from 'formik'
import { withStyles } from '@material-ui/core'

import get from 'lodash/get'
import { transform } from '@balance/lib/api/errors'
import { trimAll } from '@balance/lib/utils'
import { resetPasswordSchema } from '@balance/lib/validations'

import { FormField, FormGroup, ActionBar } from '../Form'
import { ButtonLink, Button } from '../Button'
import PageTitle from '../PageTitle/PageTitle'
import { Lock } from '../../styles/icons'

import styles from '../../styles/forms'

// const IS_DEV = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

export class ResetPassword extends Component {
  state = {
    password: '',
    passwordConfirmation: '',
    showPassword: false,
    error: this.props.error || '',
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }

  handleMouseDownPassword = event => {
    event.preventDefault()
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.currentTarget.value,
      error: '',
    })
  }

  handleSubmit = async (values, actions) => {
    // console.log('ResetPassword.onSubmit', { values, props: this.props, state: this.state })
    const { password, passwordConfirmation } = values
    if (!password || !passwordConfirmation || password !== passwordConfirmation) {
      this.setState({
        error: 'Passwords must match',
      })
      return
    }

    try {
      const result = await this.props.onSubmit({ ...trimAll(values) })
      // console.log('done?', result)
      const success = get(result, 'data.resetPassword.success')
      if (success) {
        // console.log('success!', this.props.history)
        this.props.onSuccess()
        // this.props.history.replace('/login')
        // window.location.replace('/login')
        return
      } else {
        const errors = transform(get(result, 'errors'))
        // console.log('errors?', errors, get(result, 'errors'))
        if (errors) {
          actions.setErrors(errors)
          if (errors.base) {
            let msg = errors.base.message
            if (msg.match(/invalid token/i)) {
              msg = <span>{msg} <Link to="/account/help">Request new token?</Link></span>
            }
            this.setState({ error: msg })
          }
        }
      }
    } catch (err) {
      // console.error('unable to submit data!', err)
    } finally {
      actions.setSubmitting(false)
    }
  }

  render () {
    const { classes } = this.props
    // const {error} = this.state

    // console.log('ResetPassword.render', this.state)

    const profile = {
      password: '',
      passwordConfirmation: '',
    }
    return (

      <Formik
        onSubmit={this.handleSubmit}
        isInitialValid={false}
        initialValues={profile}
        enableReinitialize
        validateOnChange={true}
        validationSchema={resetPasswordSchema}>
        {({ values, errors, touched, isSubmitting, isValid, handleSubmit, onSubmit, handleChange, handleBlur }) => {

          // console.log('reset password form', values, handleSubmit, onSubmit, errors, touched, isSubmitting, isValid)
          const validationProps = name => { return { touched: getIn(touched, name), error: getIn(errors, name) } }
          const changeProps = { onChange: handleChange, onBlur: handleBlur }

          return (

            <div>

              <Form className={classes.container}>
                <PageTitle noIcon title="Set your new password"/>

                <div className={classes.content}>

                  <FormGroup icon={<Lock/>}>

                    <FormField name="password" label="Password"
                               value={values.password}
                               type={this.state.showPassword ? 'text' : 'password'}
                               onChange={this.handleChange}
                               {...changeProps} {...validationProps('password')}
                    />

                    <FormField name="passwordConfirmation" label="Confirm Password"
                               value={values.passwordConfirmation}
                               type={this.state.showPassword ? 'text' : 'password'}
                               onChange={this.handleChange}
                               {...changeProps} {...validationProps('passwordConfirmation')}
                    />

                    <p className={classes.error}>{this.state.error}</p>
                  </FormGroup>

                  <FormGroup>
                    <ActionBar>
                      <Button type="submit" onClick={handleSubmit} disabled={isSubmitting} color="primary"
                              variant="contained"> Save </Button>
                      <ButtonLink variant="contained" to="/"> Cancel </ButtonLink>
                    </ActionBar>
                  </FormGroup>
                </div>
              </Form>
            </div>
          )
        }}
      </Formik>
    )
  }
}

export default withStyles(styles)(ResetPassword)
