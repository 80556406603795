// @flow
import React, { PureComponent } from 'react'
import classnames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import CoreModal from '@material-ui/core/Modal'

import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { Cancel } from '../../styles/icons'

type Props = {
  classes: Object,
  children?: any,
  title?: string,
  text?: string,
  open: boolean,
  onClose?: Function,
  showCloseButton: boolean,
  wrapperClassName: any,
  noPadding: boolean,
  transparent: boolean,
  pageCentered: boolean,
  hideBackdrop: boolean, // legacy
}

type State = {
  open: boolean,
}

function getModalStyle (includeWrapper: boolean = false) {
  const top = includeWrapper ? 45 : 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${left}%, -${top}%)`,
  }
}

const SMALL_BREAK = 600

const styles = theme => ({
  wrapWrapper: {
    outline: 'none !important',
  },
  wrapper: {
    position: 'absolute',
    width: '90vw',
    minWidth: 300,
    maxWidth: 700,
    maxHeight: '90vh',
    height: '100%',
    overflow: 'hidden',
    // outline: '1px dotted yellow',
    outline: 'none',

    [theme.breakpoints.up(SMALL_BREAK)]: {
      width: '75vw',
      outline: 'none',
    },

    '&:focus': {
      outline: 'none'
    }
  },
  hasClose: {
    padding: theme.spacing.unit * 2,
    outline: 'none',
  },
  close: {
    marginRight: 0 - (theme.spacing.unit * 1.5),
    textAlign: 'right',
    color: 'white',

    '& a': {
      color: 'white',
      textDecoration: 'none',
    },
    '& button': {
      background: 'transparent',
      border: 'none',
      color: 'white',
      outline: 'none',
    },
  },
  closeButton: {
    color: 'white',
  },
  paper: {
    height: '100%',
    overflow: 'scroll',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: [[theme.spacing.unit * 2, theme.spacing.unit * 2, theme.spacing.unit * 4]],
  },
  transparentPaper: {
    backgroundColor: 'transparent',
  },
  paperHasClose: {
    height: `calc(100% - ${theme.spacing.unit * 6}px)`,
  },
  pageCentered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noPadding: {
    padding: 0,
  },
  title: {
    paddingRight: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
  },
  text: {
    paddingRight: theme.spacing.unit * 2,
  },
  buttons: {
    textAlign: 'right',
  },
})

class Modal extends PureComponent<Props, State> {
  static defaultProps = {
    open: false,
    showCloseButton: false,
    noPadding: false,
  }
  state = {
    open: this.props.open,
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClose = (e) => {
    e.preventDefault()
    const { onClose } = this.props
    onClose && onClose()
    this.setState({ open: false })
  }

  render () {
    const { classes, children, showCloseButton, wrapperClassName, noPadding, hideBackdrop, pageCentered, transparent } = this.props
    // console.log('Modal Render', this.props.open, this.state.open)

    const wrapperClass = classnames(classes.wrapper, showCloseButton && classes.hasClose, wrapperClassName)

    return (
      <CoreModal
        open={this.props.open}
        onClose={this.handleClose}
        hideBackdrop={hideBackdrop}
      >
        <div className={classes.wrapWrapper}>
          <div style={getModalStyle(showCloseButton)} className={wrapperClass}>
            {showCloseButton && (
              <div onClick={this.handleClose} className={classes.close}>
                <button onClick={this.handleClose}>
                  <Typography component='span' display='inline' variant='body1' className={classes.closeButton}>
                    Close
                  </Typography>
                </button>
                <IconButton aria-label='Close' className={classes.closeButton} onClick={this.handleClose}>
                  <Cancel />
                </IconButton>
              </div>
            )}
            <div className={classnames(hideBackdrop || classes.paper, transparent && classes.transparentPaper,
              pageCentered && classes.pageCentered, showCloseButton && classes.paperHasClose,
              noPadding && classes.noPadding)}>
              {children}
            </div>
          </div>
        </div>
      </CoreModal>
    )
  }
}

// We need an intermediary variable for handling the recursive nesting.
const StyledModal = withStyles(styles)(Modal)

export default StyledModal
