import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'

import { CurrentUserConsumer } from '@balance/lib/contexts'
import { isComplete } from '@balance/lib/api/profile'
import {
  ADD_RECIPE_TO_MEAL, GET_MEAL_PLAN, REMOVE_MEAL, REPLACE_MEAL, REPLACE_MEAL_PLAN,
} from '@balance/lib/api/meal-plan'
import { SEARCH_RECIPES } from '@balance/lib/api/recipes'

import { MealPlanByDay } from '../../components/Meals'
import { ProfileNotComplete } from '../../components/Profile'

// import { HideErrorBoundary } from '../../components/Error'

class MealPlanContainer extends Component {
  state = {
    error: '',
    replacing: false,
  }

  render () {
    let { replacing } = this.state
    let week = get(this.props, 'location.search', '').replace('?week=', '')
    // console.log('week', week, this.props)
    if (!['last', 'this', 'next'].includes(week)) { week = '' }

    return (
      <CurrentUserConsumer>
        {({ state: user }) => {
          // console.log('cu', state)

          const profile = get(user, 'profile', {})
          if (!isComplete(profile)) {
            return <ProfileNotComplete profile={profile} />
          }

          return (
            <Query query={GET_MEAL_PLAN} variables={{ week }} notifyOnNetworkStatusChange>
              {({ data, loading, refetch, client, props }) => {
                // console.log('meal dashboard container props', loading, data)

                let plan = get(data, 'mealPlan', {})
                // console.log('meal plan container: meal plan', user, plan)

                const handleReplaceMealPlan = async (week) => {
                  // console.log('handleReplaceMealPlan', plan.id, week)
                  this.setState({ replacing: true })
                  const result = await client.mutate({
                    mutation: REPLACE_MEAL_PLAN,
                    variables: {
                      mealPlanId: plan.id,
                      week,
                    },
                  })
                  await refetch()
                  this.setState({ replacing: false })
                  // console.log('result', result)
                  return result
                }

                const handleReplaceMeal = async (dayNum, meal, all = false, withRecipeId = null) => {
                  // console.log('handleReplaceMeal', plan.id, { dayNum, meal, all, withRecipeId })
                  const result = await client.mutate({
                    mutation: REPLACE_MEAL,
                    variables: {
                      mealPlanId: plan.id,
                      dayNum,
                      mealNum: meal.number,
                      mealId: meal.id,
                      all,
                      withRecipeId,
                    },
                  })
                  // console.log('result', result)
                  return result
                }

                const handleRemoveMeal = async (meal) => {
                  // console.log('handleRemoveMeal', plan.id, meal)
                  const result = await client.mutate({
                    mutation: REMOVE_MEAL,
                    variables: {
                      mealPlanId: plan.id,
                      mealId: meal.id,
                    },
                  })
                  // console.log('result', result)
                  return result
                }

                const handleAddRecipe = async (recipe, dayNum, mealNum) => {
                  // console.log('handleAddRecipe', plan.id, recipe, dayNum, mealNum)
                  const result = await client.mutate({
                    mutation: ADD_RECIPE_TO_MEAL,
                    variables: {
                      mealPlanId: plan.id,
                      recipeId: recipe.id,
                      dayNum,
                      mealNum,
                    },
                  })
                  // console.log('result', result)
                  return result
                }

                const searchRecipes = async (query, type, params = {}) => {
                  // console.log('searchRecipes', query, type, params)
                  const { data } = await client.query({
                    query: SEARCH_RECIPES,
                    fetchPolicy: 'network-only',
                    variables: {
                      filter: {
                        ...params,
                        q: query,
                        type,
                      },
                    },
                  })
                  return get(data, 'items', [])
                }

                return (
                  <MealPlanByDay plan={plan} user={user} loading={loading || replacing} week={week}
                                 onReplaceMeal={handleReplaceMeal}
                                 onReplaceMealPlan={handleReplaceMealPlan}
                                 onRemoveMeal={handleRemoveMeal}
                                 onAddRecipe={handleAddRecipe}
                                 onSearchRecipe={searchRecipes}
                  />

                )
              }}
            </Query>
          )
        }}
      </CurrentUserConsumer>
    )
  }
}

export default withRouter(MealPlanContainer)
