// @flow

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import { withStyles } from '@material-ui/core/styles'

import { IS_DEV } from '@balance/lib/config/constants'

import { RequestPasswordReset } from './'
import styles from '../../styles/forms'
import PageTitle from '../PageTitle/PageTitle'

type Props = {
  resetPassword: Function,
  error?: ?Object,
}

type State = {
  email: String,
}

export class SignInHelp extends Component<Props, State> {
  state = {
    email: IS_DEV ? 'greg@bod.com' : '',
    error: this.props.error || '',
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.currentTarget.value.trim(),
      error: '',
    })
  }

  render () {
    // console.log('SignInHelp.render')
    const {classes, resetPassword} = this.props

    return (
      <div className={classes.container}>
        <PageTitle noIcon title="Need Help?" />
        <Grid container>
          <Grid item xs={12}>
            <RequestPasswordReset resetPassword={resetPassword} />
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <div className={classes.wrapper}>
              <Link className={classes.link} to="/login">Login</Link>
              <Link className={classes.link} to="/signup">Sign Up</Link><br />
            </div>
          </Grid>
        </Grid>

      </div>
    )
  }
}

export default withStyles(styles)(SignInHelp)
