import React, { Component } from 'react'
import { ApolloConsumer, Mutation, Query } from 'react-apollo'
import { Redirect, withRouter } from 'react-router-dom'
import get from 'lodash/get'

import { CurrentUserConsumer } from '@balance/lib/contexts'
import { trimAll } from '@balance/lib/utils'
import { isComplete, SAVE_PROFILE } from '@balance/lib/api/profile'
import { GET_CURRENT_USER, GET_SYSTEM_CONFIG } from '@balance/lib/api/user'
import { SEARCH_FOOD_NAMES } from '@balance/lib/api/foods'

import { ProfileWizard } from '../../components/Profile'

const profileProgression = ['demo', 'goals', 'activity', 'nutrition', 'notificationPreferences', 'targets']

class ProfileEditContainer extends Component {

  state = {
    error: '',
    redirectTo: '',
  }

  // componentWillReceiveProps = (nextProps) => {
  //   console.log('ProfileEditContainer.cWRP', nextProps, this.state, this.props)
  // }

  componentDidUpdate = () => {
    // console.log('ProfileEditContainer.dU')
    if (this.state.redirectTo) {
      this.setState({ redirectTo: '' })
    }
  }

  handlePrevious = () => {
    const step = get(this.props, 'match.params.step')
    const prevStepIndex = profileProgression.indexOf(step) - 1
    // console.log('prevStepIndex', prevStepIndex)
    if (prevStepIndex >= 0) {
      if (prevStepIndex < profileProgression.length) {
        const previousStep = profileProgression[prevStepIndex]
        // console.log('previousStep', previousStep)
        this.setState({ redirectTo: `/profile/${previousStep}` })
      }
    }
  }

  handleGoTo = (stepIndex) => {
    // console.log('handleGoTo', stepIndex)
    this.setState({ redirectTo: this._gotoStepIndex(stepIndex) })
  }

  _gotoStepIndex = (index) => {
    const nextStep = profileProgression[index]
    return `/profile/${nextStep}`
  }

  _nextStep = (step) => {
    const nextStepIndex = profileProgression.indexOf(step) + 1
    // console.log('nextStep', nextStepIndex)
    if (nextStepIndex > 0) {
      if (nextStepIndex < profileProgression.length) {
        const nextStep = profileProgression[nextStepIndex]
        return `/profile/${nextStep}`
      } else {
        // console.log('profile done!')
        return '/profile/results'
      }
    }
  }

  renderRedirect = (path) => {
    // const reload = path === '/profile/results' || path === '/profile/targets'
    return <Redirect to={{ pathname: path, state: { reload: true } }} />
  }

  render () {
    const step = get(this.props, 'match.params.step')
    // console.log('profile step', step, this.state)

    const onPrevious = profileProgression[0] === step ? null : this.handlePrevious
    const isLastStep = profileProgression[profileProgression.length - 1] === step
    return (
      <CurrentUserConsumer>
        {({ state, updateHeight }) => {
          // console.log('ProfileEditContainer.user consumer', state)

          // updateHeight && updateHeight() // for the dumb Tabs component
          document.dispatchEvent(new Event('resize'))

          const profile = get(state, 'profile', {})
          const profileLooksComplete = isComplete(profile)

          return (
            <Query query={GET_SYSTEM_CONFIG} fetchPolicy="cache-first">
              {({ data }) => {

                const systemConfig = get(data, 'systemConfig', {})
                const timeZones = get(data, 'systemConfig.timeZones', [])

                return (
                  <ApolloConsumer>
                    {client => {

                      const handleSearchFood = async (query) => {
                        // console.log('searching for food', query)
                        const { data } = await client.query({
                          query: SEARCH_FOOD_NAMES,
                          variables: {
                            filter: {
                              q: query,
                              apiCat: 'Generic foods'
                            }
                          },
                          fetchPolicy: 'network-only',
                        })

                        return get(data, 'items', [])
                      }
                      return (
                        <Mutation
                          mutation={SAVE_PROFILE}
                          skip={step !== 'demo'}
                          update={(cache, { data: { saveProfile } }) => {
                            // console.log('updating profile cache...', saveProfile)
                            const { currentUser } = cache.readQuery({ query: GET_CURRENT_USER })
                            // console.log('userData', currentUser)
                            const mergedProfile = {
                              ...currentUser.profile,
                              ...saveProfile.profile,
                            }
                            const updatedUser = {
                              ...currentUser,
                              profile: mergedProfile,
                            }
                            // console.log('updatedUser', updatedUser,  mergedProfile)
                            cache.writeQuery({
                              query: GET_CURRENT_USER,
                              data: { currentUser: updatedUser },
                            })
                          }}
                        >
                          {(saveProfile, { data, loading, error }, ...rest) => {
                            // console.log('signin container props', data)

                            const handleSubmit = async (values) => {
                              // console.log('profile wizard handleSubmit', values, { trimmed: trimAll(values) })
                              const result = await saveProfile(
                                { variables: { attributes: { step, ...trimAll(values) } } })
                              const success = get(result, 'data.saveProfile.success', false)
                              if (success) {
                                // refetch && await refetch()
                                this.setState({ redirectTo: this._nextStep(step) })
                              }
                              return result
                            }

                            if (this.state.redirectTo) {
                              return this.renderRedirect(this.state.redirectTo)
                            }

                            return (
                              <ProfileWizard profile={profile}
                                             step={step}
                                             systemConfig={systemConfig}
                                             timeZones={timeZones}
                                             onSubmit={handleSubmit}
                                             onGoTo={this.handleGoTo}
                                             onSearchFoods={handleSearchFood}
                                             showTabs={profileLooksComplete}
                                             onPrevious={onPrevious}
                                             isLastStep={isLastStep} />
                            )
                          }}

                        </Mutation>
                      )
                    }}
                  </ApolloConsumer>
                )
              }}
            </Query>
          )
        }}
      </CurrentUserConsumer>
    )
  }
}

export default withRouter(ProfileEditContainer)
