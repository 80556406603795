import React, { PureComponent } from 'react'
import { Mutation } from 'react-apollo'
import { withRouter } from 'react-router-dom'

import { LOGOUT } from '@balance/lib/api/user'

import { HideErrorBoundary } from '../../components/Error'

class SignOut extends PureComponent {
  componentDidMount (): void {
    this.onLoad()
  }

  onLoad = async () => {
    this.props.onLogout()
  }

  render () {
    return (
      <div><p>Logging you out ....</p></div>
    )
  }
}

class SignOutContainer extends PureComponent {

  doLogout = async () => {

  }

  render () {
    // console.log('signout.render')
    return (
      <HideErrorBoundary>
        <Mutation mutation={LOGOUT}
                  update={(cache, data) => {
                    // console.log('updating logout cache?', data)
                  }}>
          {(logout, { data, loading, error, called, client, ...rest }) => {

            const handleLogout = async () => {
              // console.log('doing logout...', data, called, logout, rest)
              if (!called) {
                try {
                  const result = await logout()
                  // console.log('logout result', result)
                  client.resetStore().then(r2 => {
                    this.props.history.replace('/login', { reloadUser: true, loggedIn: false })
                  })
                  return result
                } catch (err) {
                  // console.log('oh well, we tried')
                  this.props.history.replace('/login', { reloadUser: true, loggedIn: false })
                }
              }
            }

            return <SignOut onLogout={handleLogout} />
          }}
        </Mutation>
      </HideErrorBoundary>
    )
  }
}

export default withRouter(SignOutContainer)
