import React, { Component } from 'react'
import { Mutation } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'

import { CurrentUserConsumer } from '@balance/lib/contexts'
import { SAVE_PROFILE } from '@balance/lib/api/profile'
// import { trimAll } from '@balance/lib/utils'

import { ProfileResults } from '../../components/Profile'

class ProfileResultsContainer extends Component {
  state = {}

  // componentWillReceiveProps = (nextProps) => {
  //   console.log('ProfileResultsContainer.cWRP', nextProps, this.state, this.props)
  // }

  // componentDidUpdate = () => {
  //   console.log('ProfileResultsContainer.dU')
  // }

  render () {
    // const step = get(this.props, 'match.params.step')
    // console.log('profile step', step, this.state)

    return (
      <CurrentUserConsumer>
        {({state, refetch}) => {
          // console.log('ProfileResultsContainer.user consumer', state, refetch)

          refetch && refetch()

          const profile = get(state, 'profile', {})
          return (
            <Mutation mutation={SAVE_PROFILE}>
              {saveProfile => {

                const doSaveProfile = async (attrs) => {
                  // console.log('doSaveProfile', attrs)
                  const result = await saveProfile({
                    variables: {
                      attributes: {
                        ...attrs,
                        step: 'targets',
                      },
                    },
                  })
                  // console.log('saveProfile result', result)
                  return get(result, 'data.data.success')
                }
                return (
                  <ProfileResults profile={profile} saveProfile={doSaveProfile} />
                )
              }}
            </Mutation>
          )
        }}
      </CurrentUserConsumer>
    )
  }
}

export default withRouter(ProfileResultsContainer)
