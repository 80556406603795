// @flow
import React, { PureComponent } from 'react'
import get from 'lodash/get'
import { Link } from 'react-router-dom'
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import memoize from 'memoize-one'

import MUIDataTable from 'mui-datatables'

import { colors } from '@balance/lib/styles'

import { TypeChip } from '../Chip'

import { FAB } from '../Button'
import { Add } from '../../styles/icons'
import { Spinner } from '../Global'
import PageTitle from '../PageTitle/PageTitle'
import baseStyles from '../../styles/global'
// import { FullPageSpinner } from '../Global'

type Props = {
  recipes: Array<Object>,
  totalResultCount: number,
  page: number,
  perPage: number,
  filterStatus: Array<String>,
  filterType: Array<String>,
  loading: boolean,
  searchText?: ?string,
  viewRecipe: Function,
  fetchMoreRecipes: Function,
  searchOrFilterRecipes: Function,
  onFilterChange: Function,
  onChangePage: Function,
  onChangeRowsPerPage: Function,
}
type State = {
  term: ?String,
  recipeArray: Array<Array>,
}

const styles = theme => ({
  ...baseStyles(theme),
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  aside: {
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
  },
  item: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderBottom: `1px solid ${colors.divider}`,

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  typeGroup: {
    display: 'flex',
    flexDirection: 'row',
  },
  text: {
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  type: {
    backgroundColor: colors.yellow,
    color: 'black',
    textTransform: 'lowercase',
  },
  status: {
    backgroundColor: colors.darkGreen,
    color: 'white',
    textTransform: 'lowercase',
  },

})

const overrideTheme = (theme) => createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MUIDataTable: {
      root: {},
      responsiveScroll: {
        maxHeight: 'auto',
      },
    },
    MUIDataTableHead: {
      main: {
        display: 'none',
      },
    },
    MUIDataTableFilterList: {
      root: {
        marginBottom: theme.spacing.unit * 2,
      },
    },
    MUIDataTableHeadCell: {
      root: {
        borderWidth: 0,
      },
    },
    MUIDataTableBodyCell: {
      root: {},
      cellStacked: {
        height: 50,

        [theme.breakpoints.down('sm')]: {
          display: 'none',
          // display: 'inline-block',
          backgroundColor: theme.palette.background.paper,
          fontSize: '16px',
          height: 50,
          width: 'calc(50% - 80px)',
          whiteSpace: 'nowrap',
        },
      },
      responsiveStacked: {
        paddingRight: 12,

        [theme.breakpoints.down('sm')]: {
          borderWidth: 0,
          whiteSpace: 'normal',
          padding: theme.spacing.unit,
          display: 'block',
          height: 'auto',
          width: '100%',
        },
      },
    },
  },
})

function ItemLink (props) {
  return <Link style={{ textDecoration: 'none' }} {...props} />
}

const MyLink = props => <Link to="/recipes/new" {...props} />

const textLabelsLoading = {
  body: {
    noMatch: '',
  },
}

const textLabelsLoaded = {
  body: {
    noMatch: 'Sorry, no recipes matched.',
  },
}

class RecipeList extends PureComponent<Props, State> {
  static defaultProps = {
    recipes: [],
    totalResultCount: 0,
    loading: false,
    page: 0,
    perPage: 25,
    filterStatus: [],
    filterType: [],
  }

  state = {
    term: '',
    recipeArray: [],
  }

  recipeArray = memoize((recipes) => {
    // console.log('making recipe array', this.props)
    const arr = recipes.map(r => [r, r.status, r.type, r])
    // console.log('as array', arr)
    return arr
  })

  tableOptions = memoize((props = this.props) => {
    const { page, perPage, viewRecipe, totalResultCount, loading, searchText } = props

    const textLabels = loading ? textLabelsLoading : textLabelsLoaded
    // console.log('tableOptions updating...', loading, textLabels)
    return {
      page: page,
      count: totalResultCount,
      serverSide: true,
      filterType: 'dropdown',
      textLabels,
      selectableRows: 'none',
      elevation: 2,
      responsive: 'stacked',
      rowsPerPage: perPage,
      rowsPerPageOptions: [10, 25, 50],
      fixedHeader: false,
      sort: false,
      filter: true,
      searchText,
      print: false,
      download: false,
      viewColumns: false,
      onRowClick: (rowData, rowMeta) => {
        // console.log('onRowClick', rowData, rowMeta)
        const r = get(props, `recipes.${rowMeta.rowIndex}`)
        // console.log('r', r)
        if (r && r.id) {
          viewRecipe(r.id)
        }
      },
      onChangePage: props.onChangePage,
      onChangeRowsPerPage: props.onChangeRowsPerPage,
      onSearchChange: props.onSearchChange,
      onFilterChange: props.onFilterChange,
      onTableChange: (action, tableState) => {

        // console.log(action, tableState)

        const { searchText } = tableState

        switch (action) {
          case 'search':
            // console.log('search change?', searchText)
            if (searchText === '' || searchText === null) { // seems to be an oversight in the DT API
              props.onSearchChange('')
            }
            break
          default:
            // console.log('not doing anything with', action)
            break
        }
      },
    }
  })

  componentDidUpdate (prevProps) {
    // console.log('cDU', prevProps, this.props)
    if (prevProps.recipes !== this.props.recipes || this.state.recipeArray.length === 0) {
      this.updateRecipeList(this.props.recipes)
      // } else {
      //   console.log('no need to update recipe array?')
    }
  }

  updateRecipeList = (recipes) => {
    this.setState({
      recipeArray: this.recipeArray(recipes),
    })
  }

  tableColumns = () => {
    const { classes, filterStatus, filterType } = this.props
    return [
      {
        name: 'Combo',
        options: {
          filter: false,
          customBodyRender: (recipe, tableMeta, updateValue) => {
            // console.log('recipe column', recipe, tableMeta)
            const statusLabel = (recipe && recipe.public !== undefined) ? (recipe.public ? 'public' : 'personal') : ''

            return (
              <div className={classes.typeGroup}>
                <TypeChip label={statusLabel} fixed className={classes.status} />
                <TypeChip label={recipe.shortTypeLabel} fixed className={classes.type} />
              </div>
            )
          },
        },
      },
      {
        name: 'Access',
        options: {
          display: false,
          filter: true,
          filterList: filterStatus,
          filterOptions: { names: ['public', 'personal'] },
        },
      },
      {
        name: 'Type',
        options: {
          display: false,
          filter: true,
          filterList: filterType,
          filterOptions: { names: ['breakfast', 'meal', 'snack'] },
        },
      },
      {
        name: 'Name',
        options: {
          filter: false,
          customBodyRender: (recipe, tableMeta, updateValue) => {
            return (
              <ItemLink to={`/recipes/${recipe.id}`} className={classes.item}>
                <Typography className={classes.text}>{recipe.name || recipe.id}</Typography>
              </ItemLink>
            )
          },
        },
      },
    ]
  }

  render () {
    const { classes, loading, totalResultCount } = this.props
    const { recipeArray } = this.state
    // console.log('render', recipeArray, this.props)
    // console.log('render tableColumns()', this.tableColumns())
    // console.log('render tableOptions()', this.tableOptions(this.props))

    // if (!recipeArray || recipeArray.length === 0) {
    //   return null
    // }

    // console.log('recipe array', recipeArray)
    const aside = (
      <span className={classes.aside}>
       {loading && <Spinner />}
        <span style={{ marginLeft: 4 }}>Total: {totalResultCount}</span>
      </span>
    )

    return (
      <div className={classes.container}>
        <PageTitle title="Recipes" noIcon aside={aside} />

        <MuiThemeProvider theme={overrideTheme}>
          <MUIDataTable data={recipeArray}
                        columns={this.tableColumns()}
                        options={this.tableOptions(this.props)} />
        </MuiThemeProvider>

        <FAB extended icon={<Add />} label="Create" component={MyLink} />

        <Typography>
          &nbsp;
        </Typography>

        {/*<FullPageSpinner open={loading} hideBackdrop />*/}
      </div>
    )
  }
}

export default withStyles(styles)(RecipeList)
