"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transform = void 0;

var _get = _interopRequireDefault(require("lodash/get"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var transform = function transform(errors) {
  // console.log('transforming errors', errors)
  var transformed = {};
  var message = (0, _get.default)(errors, '0.message', '');
  var problems = (0, _get.default)(errors, '0.problems');

  if (problems) {
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = problems[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var p = _step.value; // console.log('p', p, p.path)

        var path = p.path.join('.');
        var m = (0, _get.default)(transformed, path, '');
        m += p.explanation + '. ';
        transformed[path] = m;
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return != null) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }
  } else {
    var fields = (0, _get.default)(errors, '0.fields'); // console.log('fields', fields)

    if (fields) {
      for (var f in fields) {
        // console.log('field', f, fields[f])
        if (fields[f] && fields[f].join) {
          message += fields[f].join('. ');
        }

        transformed[f] = message;
      }
    } else {
      if (!message) {
        message = (0, _get.default)(errors, '0');
      }
    }
  }

  if (message) {
    transformed['base'] = {
      message: message
    };
  }

  return transformed;
};

exports.transform = transform;