import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import CircularProgress from '@material-ui/core/CircularProgress'

const styles = theme => ({
  progress: {
    width: '1em',
    height: '1em',
    margin: 4,
  },
})

const Spinner = withStyles(styles)(({ classes, ...props }) => {

  return (
    <CircularProgress className={classes.progress}
                      size={28} color="primary"
                      thickness={2}
                      {...props}
    />
  )
})

export default Spinner
