// @flow
import React, { Fragment } from 'react'
import compact from 'lodash/compact'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableFooter from '@material-ui/core/TableFooter'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'

import { asPct } from '@balance/lib/utils'
import { IS_DEV } from '@balance/lib/config/constants'

import { Link as LinkIcon } from '../../styles/icons'

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    // minWidth: '100%',
    '& th, & td': {
      padding: [[theme.spacing.unit, theme.spacing.unit]],
    },
  },
  thead: {
    color: '#3d3d3d',
    fontSize: 10,
    fontWeight: '500',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
  },
  parent: {
    '& > *': {
      borderBottom: 'none',
      paddingBottom: 10,
    },
  },
  sub: {
    paddingBottom: 10,
  },
  name: {
    minWidth: 150,
    paddingRight: '0.5rem',

    '& span': {
      verticalAlign: 'middle',
      display: 'flex',
      alignItems: 'center',
    },
    '& span svg': {
      marginRight: 5,
    },
  },
})

const PerGramToolTip = ({ amount, children }) => (
  <Tooltip title={amount ? `${amount} per gram` : ''} enterDelay={1500} leaveDelay={200}>
    <span>{children}</span>
  </Tooltip>
)

const IngredientTable = ({ classes, ingredients, recipe, showCalTarget }) => {

  const numericLabels = [
    'Calories',
    'Protein (g)',
    'Fat (g)',
    'Carbs (g)',
    'Fiber (g)',
    'Sugar (g)',
    'Sodium (mg)',
  ]

  const ingredientsGrouped = compact(ingredients.map(ingr => {
    return ingr.goesWithId ? null : {
      ...ingr,
      sub: ingredients.filter(ingrSub => ingrSub.goesWithId === ingr.id),
    }
  }))
  //console.log('ingredientsGrouped', ingredients, ingredientsGrouped)

  const row = (ingr, prefix = null) => {
    // console.log('row', ingr, prefix)
    let qty = ''
    if (ingr.foodFixed) {
      const wholeQty = parseFloat(ingr.qty)
      const fixedQty = parseFloat(ingr.fixedQty)
      const qtyMain = (fixedQty && ingr.fixedUnit) ? `${fixedQty} ${ingr.fixedUnit}${wholeQty !== 1 ? ' ea' : ''}` : ''
      qty = `, ${wholeQty} whole (${qtyMain})`
    } else {
      const qtyG = `${ingr.qtyG} g`
      const qtyOz = ingr.qtyOz ? `${ingr.qtyOz} oz` : ''
      const qtyOzPlusG = qtyOz ? `${qtyOz} (${ingr.qtyG} g)` : ''
      const qtyMain = (ingr.qty && ingr.unit && ingr.unit !== 'g') ? `${ingr.qty} ${ingr.unit}` : ''
      qty = qtyMain ? `, ${qtyMain}` : ingr.qtyOz ? `, ${qtyOzPlusG}` : ingr.qtyG ? qtyG : ''
    }
    const suffix = (qty && ingr.fixed) ? ' (f)' : ''
    const hasSub = ingr.sub && ingr.sub.length > 0
    const rowClass = hasSub ? classes.parent : null
    const rowProps = IS_DEV ? { 'data-id': ingr.id } : {}
    return (
      <Fragment key={`${ingr.id}-row`}>
        <TableRow className={rowClass} {...rowProps}>
          <TableCell component="th" scope="row" className={classes.name}>
            <span>
              {prefix}{ingr.name}{qty}{suffix}
            </span>
          </TableCell>
          <TableCell align="right">{ingr.kcal}</TableCell>
          <TableCell align="right"><PerGramToolTip amount={ingr.proteinG}>{ingr.protein}</PerGramToolTip></TableCell>
          <TableCell align="right"><PerGramToolTip amount={ingr.fatG}>{ingr.fat}</PerGramToolTip></TableCell>
          <TableCell align="right"><PerGramToolTip amount={ingr.carbsG}>{ingr.carbs}</PerGramToolTip></TableCell>
          <TableCell align="right"><PerGramToolTip amount={ingr.fiberG}>{ingr.fiber}</PerGramToolTip></TableCell>
          <TableCell align="right"><PerGramToolTip amount={ingr.sugarG}>{ingr.sugar}</PerGramToolTip></TableCell>
          <TableCell align="right"><PerGramToolTip amount={ingr.sodiumG}>{ingr.sodium}</PerGramToolTip></TableCell>
        </TableRow>
        {ingr.sub && ingr.sub.map(ingrSub => row(ingrSub, <LinkIcon />))}
      </Fragment>
    )
  }

  const kcalTargetLabel = showCalTarget && recipe.kcalTarget ? `(${recipe.kcalTarget})` : ''
  return (
    <Paper className={classes.root} elevation={0}>
      <Table className={classes.table} padding="dense">
        <TableHead>
          <TableRow>
            <TableCell className={classes.thead}>Ingredient</TableCell>
            {numericLabels.map((col, i) => (
              <TableCell key={i} align="right" className={classes.thead}>{col}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {ingredientsGrouped.map(ingr => row(ingr))}
        </TableBody>
        <TableFooter>
          <TableRow key={`${recipe.id}-footer`}>
            <TableCell component="th" scope="meal">
              Total {kcalTargetLabel ? '(Target)' : ''}
            </TableCell>
            <TableCell align="right">{recipe.kcal} {kcalTargetLabel} </TableCell>
            <TableCell align="right">{recipe.protein} ({asPct(recipe.proteinPct, 1)}%) </TableCell>
            <TableCell align="right">{recipe.fat} ({asPct(recipe.fatPct, 1)}%)</TableCell>
            <TableCell align="right">{recipe.carbs} ({asPct(recipe.carbsPct, 1)}%)</TableCell>
            <TableCell align="right">{recipe.fiber}</TableCell>
            <TableCell align="right">{recipe.sugar}</TableCell>
            <TableCell align="right">{recipe.sodium}</TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </Paper>
  )
}

export default withStyles(styles)(IngredientTable)
