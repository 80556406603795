import React, { Component } from 'react'
import { BrowserRouter, HashRouter } from 'react-router-dom'

import { ApolloProvider } from 'react-apollo'

import './styles/global.css'

import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiThemeProvider } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import { getClient } from '@balance/lib/config/apollo'
import { theme } from './styles'

import AppRouter from './containers/AppRouter'
import { ErrorBoundary } from './components/Error'

const ReactRouter = window.cordova ? HashRouter : BrowserRouter

class App extends Component {

  client = null

  constructor () {super(); this.client = getClient()}

  render () {
    return (
      <ApolloProvider client={this.client}>
        <ReactRouter basename={window.cordova ? null : '/'}>
          <MuiThemeProvider theme={theme}>
            <CssBaseline>
              <Typography component="div">
                <ErrorBoundary>
                  <AppRouter />
                </ErrorBoundary>
              </Typography>
            </CssBaseline>
          </MuiThemeProvider>
        </ReactRouter>
      </ApolloProvider>
    )
  }
}

export default App
