// @flow
import React, { PureComponent } from 'react'
import classnames from 'classnames'
import get from 'lodash/get'
import Autosuggest from 'react-autosuggest'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'

import { FieldArray, Form } from 'formik'

import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'

import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'

import { Spinner } from '../Global'
import { ButtonLink, SaveButton } from '../Button'
import { FormField, Option, RadioGroup, Switch } from '../Form'
import IngredientEditList from './IngredientEditList'
import baseStyles from '../../styles/forms'

type Props = {
  recipe: Object,
  isAdmin: boolean,
  isValid: boolean,
  isSubmitting: boolean,
  errors: Object,
  validationProps: Object,
  onChange: Function,
  onBlur: Function,
  searchFoods: Function,
  onAddIngredient: Function,
  onUpdateIngredient: Function,
  onRemoveIngredient: Function,

  classes: Object,
}

type State = {
  query: String,
  querySource: String,
  searching: boolean,
  suggestions: Array<Object>,
}

const styles = theme => {
  const base = baseStyles(theme)
  return {
    ...base,
    root: {
      height: 250,
      flexGrow: 1,
    },
    wrapper: {
      ...base.wrapper,
      borderTop: '1px solid #ccc',
    },
    card: {
      overflow: 'visible',
    },
    suggestionContainer: {
      position: 'relative',
      margin: [[theme.spacing.unit * 2, 0]],
      // padding: [[theme.spacing.unit * 2, theme.spacing.unit * 3]],
    },
    suggestionsContainerOpen: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing.unit,
      left: 0,
      right: 0,
    },
    suggestion: {
      display: 'block',
    },
    suggestionsList: {
      margin: 0,
      padding: 0,
      listStyleType: 'none',
    },
    divider: {
      height: theme.spacing.unit * 2,
    },
    textField: {
      marginLeft: 0,
      marginRight: 0,

      '& label': {
        left: theme.spacing.unit,
      },
      '& input': {
        paddingLeft: theme.spacing.unit,
      },
      '& p': {
        backgroundColor: 'white',
        paddingLeft: theme.spacing.unit,
      },
    },
  }
}

const DIETS = [
  { key: 'KETO', label: 'Ketogenic' },
  // {key: 'SOUTHBEACH', label: 'South Beach'},
  // {key: '2019', label: '2019 Fad'},
]

const renderSuggestion = (suggestion, { query, isHighlighted }) => {
  const matches = match(suggestion.name, query)
  const parts = parse(suggestion.name, matches)

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {suggestion.brand ? <span>{suggestion.brand} </span> : ''}
        {parts.map((part, index) => {
          return part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </strong>
          )
        })}
      </div>
    </MenuItem>
  )
}
const shouldRenderSuggestions = (value) => {
  return value.trim().length > 2
}

const getSuggestionValue = (suggestion) => {
  return suggestion.name
}

class RecipeForm extends PureComponent<Props, State> {
  static defaultProps = {
    recipe: {},
  }
  state = {
    query: '',
    querySource: 'local',
    searching: false,
    suggestions: [],
  }

  handleAddFoodFromApi = () => {
    console.log('searching api for food', this.state)
    this.setState({ querySource: 'api' })
  }

  handleSuggestionsFetchRequested = async ({ value }) => {
    // console.log('handleSuggestionsFetchRequested', value)
    this.setState({ searching: true })
    const resultsLocal = await this.props.searchFoods(value, 'local')
    const resultsRemote = await this.props.searchFoods(value, 'api')
    const results = [
      {
        title: null,
        suggestions: resultsLocal,
      },
      {
        title: '-',
        suggestions: resultsRemote,
      },
    ]
    // console.log('results', results)
    this.setState({ suggestions: results, searching: false })
  }

  handleSuggestionsClearRequested = () => {
    // console.log('handleSuggestionsClearRequested')
    this.setState({
      querySource: 'local',
      searching: false,
      suggestions: [],
    })
  }

  handleSuggestionSelected = (event, { suggestion, suggestionValue }) => {
    // console.log('suggestion selected!', event, suggestion, suggestionValue)
    this.setState(prevState => {
      const unit = suggestion.fixed ? 'whole' : 'oz'
      this.setState({ query: '' }, () => {
        this.props.onAddIngredient(
          { ...suggestion, qty: 1, unit: unit, newItem: true, foodFixed: suggestion.fixed, foodId: suggestion.id },
        )
      })
    })
  }

  renderInputComponent = (inputProps) => {
    const { searching } = this.state
    const { classes, inputRef = () => {}, ref, ...other } = inputProps
    // console.log('auto suggest input', searching, other)

    return (
      <TextField
        fullWidth
        variant="outlined"
        InputProps={{
          inputRef: node => {
            ref(node)
            inputRef(node)
          },
          classes: {
            input: classes.input,
          },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton className={classes.endIconBtn}>
                {searching && (<Spinner />)}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...other}
      />
    )
  }

  renderSuggestionsContainer = ({ containerProps, children, query, ...rest }) => {
    const { classes } = this.props
    // console.log('suggestion container', containerProps, children, query, rest)
    const notice = false //  shouldRenderSuggestions(query)
    return (
      <Paper {...containerProps} square>
        {children}
        {notice && (
          <div className={classes.wrapper}>
            Not seeing what you're looking for? <button onClick={this.handleAddFoodFromApi}>Search deeper!</button>
          </div>
        )}
      </Paper>
    )
  }

  renderAutoSuggest = () => {
    const { classes } = this.props

    const autosuggestProps = {
      renderInputComponent: this.renderInputComponent,
      suggestions: this.state.suggestions,
      onSuggestionSelected: this.handleSuggestionSelected,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue,
      renderSuggestion,
      shouldRenderSuggestions,
      multiSection: true,
      renderSectionTitle: (section) => {
        // console.log('section title?', section)
        // return <strong>{section.title}</strong>
        if (section.title === '-') {
          return <strong>
            <hr />
          </strong>
        }
        return null
      },
      getSectionSuggestions: (section) => {
        // console.log('get section suggestions', section)
        return section.suggestions
      },
    }

    return (
      <Autosuggest
        {...autosuggestProps}
        inputProps={{
          classes,
          placeholder: 'Search for an ingredient',
          value: this.state.query,
          onChange: (_, { newValue }) => this.setState({ query: newValue }),
        }}
        theme={{
          container: classes.suggestionContainer,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderSuggestionsContainer={this.renderSuggestionsContainer}
      />
    )
  }

  render () {
    console.log('RecipeForm.render', this.props)
    const {
            classes, recipe, onChange, onBlur, errors, isAdmin, isValid, isSubmitting,
            onUpdateIngredient, onRemoveIngredient, validationProps,
          } = this.props

    // return <div><span>TYPE: {recipe.type} </span> <p>{JSON.stringify(recipe)} </p></div>

    if (!recipe) { return null }
    const canAdjustServingSize = !Boolean(recipe.id) || recipe.mealCount === 0

    const selectedDiets = recipe.dietNames || []
    const warning = `Note: this recipe is part of ${recipe.mealCount} meal plans, so any ingredient changes will result in a new recipe.`
    const note = recipe.mealCount > 0 ? <p><em>{warning}</em></p> : null
    const rType = get(recipe, 'type')
    return (
      <Form>
        <RadioGroup name="type" label="Recipe Type" value={rType} horizontal>
          <Option label="Meal (Lunch/Dinner)" value="meal" onChange={onChange} />
          <Option label="Breakfast" value="breakfast" onChange={onChange} />
          <Option label="Meal (Breakfast/Lunch/Dinner)" value="anymeal" onChange={onChange} />
          <Option label="Snack" value="snack" onChange={onChange} />
        </RadioGroup>
        {errors.type && (
          <p className={classes.error}>{errors.type}</p>
        )}

        <div>
          {isAdmin && (
            <Switch label="Public?"
                    name="public"
                    value={recipe.public}
                    checked={!!recipe.public}
                    onChange={onChange} />
          )}
        </div>

        <div>
          <FormField name="instructions" label="Instructions (Optional)"
                     defaultValue={recipe.instructions} value={recipe.instructions}
                     className={classes.textarea}
                     multiline
                     InputProps={{ disableUnderline: true }}
                     onChange={onChange} onBlur={onBlur} />
        </div>

        {DIETS.length > 0 && (
          <div>
            <p>Is this recipe associated with {DIETS.length === 1 ? 'this diet' : 'one of these diets'}?</p>
            <FieldArray name="dietNames"
                        validateOnChange={false}
                        render={({ replace }) => (
                          <FormControl component="fieldset">
                            {DIETS.map((diet, i) => (
                              <label key={diet.key}
                                     className={classnames(classes.listItem, classes.checkboxListItem)}>
                                <Checkbox
                                  checked={selectedDiets.indexOf(diet.key) >= 0}
                                  className={classes.checkbox}
                                  onChange={(e) => {
                                    // console.log('onDietOptionChange', e.target.name, e.currentTarget.checked,
                                    //   e.currentTarget.value, e.target.checked,
                                    //   e.target.value, recipe.dietNames)
                                    replace(i, e.currentTarget.checked ? diet.key : '')
                                    // console.log('onDietOptionChange after', recipe.dietNames)
                                    // onChange(e)
                                  }}
                                  value={diet.key}
                                  name={`dietNames.${i}`}
                                  aria-label={diet.label}
                                />
                                <span>
                                          {diet.label}
                                        </span>
                              </label>
                            ))}
                          </FormControl>
                        )}
            />
          </div>
        )}

        {canAdjustServingSize && (
          <div>
            <FormField name="servingSize" label="Serving Size"
                       helperText="Will be used to adjust the entered ingredient quantities to a per-serving recipe (except whole items)"
                       defaultValue={recipe.servingSize} value={recipe.servingSize}
                       className={classes.textField}
                       {...validationProps('servingSize')}
                       InputProps={{ disableUnderline: true }}
                       onChange={onChange} onBlur={onBlur} />
          </div>
        )}

        {note}
        <IngredientEditList items={recipe.ingredients}
                            onUpdate={onUpdateIngredient}
                            onDelete={onRemoveIngredient}
                            errors={errors.ingredients} />
        {errors.ingredients && !Array.isArray(errors.ingredients) && (
          <p className={classes.error}>{errors.ingredients}</p>
        )}

        {this.renderAutoSuggest()}

        {note}
        <SaveButton variant="contained" color="primary" disabled={!isValid || isSubmitting} type="submit" />
        <ButtonLink to={recipe.id ? `/recipes/${recipe.id}` : '/recipes'}>Cancel</ButtonLink>
      </Form>
    )
  }
}

export default withStyles(styles)(RecipeForm)
