// @flow
import React, { PureComponent } from 'react'
import { withStyles } from '@material-ui/core'
import get from 'lodash/get'
import classnames from 'classnames'

import { Formik, Form, getIn } from 'formik'
import FormControl from '@material-ui/core/FormControl'

import { transform } from '@balance/lib/api/errors'
import { profileMealsSchema } from '@balance/lib/validations'

import { FormGroup, RadioGroup, Option } from '../Form'
import PageTitle from '../PageTitle/PageTitle'
import { Button, ButtonLink } from '../Button'
import ActionBar from '../Form/ActionBar'

import { RestaurantMenu } from '../../styles/icons'
import styles from '../../styles/forms'

type Props = {}
type State = {}

const daysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

const mealSplits = [
  { key: 1, label: '3 meals, 3 snacks' },
  { key: 2, label: '3 meals, 2 snacks' },
  { key: 3, label: '3 meals, 1 snack' },
  { key: 4, label: '3 meals, no snacks' },
  { key: 5, label: '4 meals, 1 snack' },
  { key: 6, label: '4 meals, no snacks' },
]

class ProfileMeals extends PureComponent<Props, State> {
  static defaultProps = {}

  handleSubmit = async (values, actions) => {
    // console.log('ProfileMeals.onSubmit', { values, props: this.props, state: this.state })

    let numerics = ['mealDays', 'mealVariety', 'mealSplit', 'mealPlanStart']
    let normalized = {
      regenerateThisWeeksPlan: values.regenerateThisWeeksPlan === 'true'
    }
    numerics.forEach(v => normalized[v] = parseInt(values[v], 10))

    try {
      const result = await this.props.onSubmit(normalized)
      // console.log('profile meals done?', result)
      const success = get(result, 'data.saveProfile.success', false)
      if (!success) {
        const errors = transform(get(result, 'errors'))
        if (errors) {
          actions.setErrors(errors)
          if (errors.base) {
            this.setState({ error: errors.base.message })
          }
        }
      }
    } catch (err) {
      console.error('unable to submit data!', err)
    } finally {
      actions.setSubmitting(false)
    }
  }

  render () {
    const { classes, profile } = this.props

    // console.log('ProfileMeals.render', profile)

    const showResetWarning = get(profile, 'hasMealPlans', false)

    const profileWithDefaults = {
      mealDays: '5', // FormControlLabel requires strings :-|
      mealVariety: '3',
      mealSplit: 1,
      mealPlanStart: 0,
      regenerateThisWeeksPlan: 'false',
      ...profile,
    }
    return (
      <Formik
        onSubmit={this.handleSubmit}
        isInitialValid={false}
        initialValues={profileWithDefaults}
        enableReinitialize
        validateOnChange={true}
        validationSchema={profileMealsSchema}>
        {({ values, errors, touched, isSubmitting, isValid, handleSubmit, onSubmit, handleChange, handleBlur }) => {

          // console.log('profile wizard form', values, errors, touched, isSubmitting, isValid)
          // const validationProps = name => { return {touched: getIn(touched, name), error: getIn(errors, name)} }
          const validationProps = name => {
            const hasError = Boolean(getIn(errors, name))
            const hasValue = getIn(values, name) !== undefined
            return {
              touched: hasValue || hasError,
              error: getIn(errors, name),
            }
          }
          //const changeProps = {onChange: handleChange, onBlur: handleBlur}

          // console.log('ProfileActivityLevel()', values.activityLevel)
          const split = `${values.mealSplit}` // force string // parseInt(values.mealSplit, 10)

          return (
            <div>
              <Form className={classes.container}>
                <PageTitle noIcon title="Meal & Snacks" />

                <div className={classes.content}>
                  <FormGroup icon={<RestaurantMenu />} title="How many days per week shall we prepare?">
                    <RadioGroup name="mealDays" label={false} value={`${values.mealDays}`} horizontal
                                onChange={handleChange} {...validationProps('mealDays')}>
                      <Option label="4" value="4" onChange={handleChange} />
                      <Option label="5" value="5" onChange={handleChange} />
                      <Option label="6" value="6" onChange={handleChange} />
                      <Option label="7" value="7" onChange={handleChange} />
                    </RadioGroup>
                  </FormGroup>

                  <FormGroup title="On which day do you want to start each meal plan?">
                    <RadioGroup name="mealPlanStart" label={false} value={`${values.mealPlanStart}`} horizontal
                                onChange={handleChange} {...validationProps('mealPlanStart')}>
                      {daysOfWeek.map((dow, index) => (
                        <Option key={index} label={dow} value={`${index}`} onChange={handleChange} />
                      ))}
                    </RadioGroup>
                  </FormGroup>

                  <FormGroup title="How much variety do you want?">
                    <RadioGroup name="mealVariety" label={false} value={`${values.mealVariety}`} horizontal={false}
                                onChange={handleChange} {...validationProps('mealVariety')}>
                      <Option label="None" value="1" onChange={handleChange} description="The same meal plan every day" />
                      <Option label="A little" value="2" onChange={handleChange}
                              description="Two different meal plans per week" />
                      <Option label="Some" value="3" onChange={handleChange}
                              description="Three different meal plans per week" />
                      <Option label="A lot" value="4" onChange={handleChange}
                              description="A different meal plan every day" />
                    </RadioGroup>
                  </FormGroup>

                  <FormGroup title="How do you want to spread out your meals each day?">
                    <FormControl component="fieldset">

                      <RadioGroup name="mealSplit" label={false} value={split} horizontal={false}
                                  onChange={handleChange} {...validationProps('mealSplit')}>
                        {mealSplits.map((al, i) => (
                          <Option key={al.key} aria-label={al.label} checked={split === al.key} label={al.label}
                                  value={`${al.key}`} onChange={handleChange} />
                        ))}
                      </RadioGroup>

                    </FormControl>
                  </FormGroup>

                  {showResetWarning && (
                    <FormGroup>

                      <p className={classnames(classes.error, classes.overrideWarning)}>
                        WARNING! Changes to these settings will impact meal plans starting next week.
                        You can choose to also reset this week's plan.
                      </p>

                      <RadioGroup name="regenerateThisWeeksPlan" label={false} value={values.regenerateThisWeeksPlan} horizontal
                                  onChange={handleChange} {...validationProps('regenerateThisWeeksPlan')}>
                        <Option label="Keep this week's plan" value="false" onChange={handleChange} />
                        <Option label="Reset this week's plan" value="true" onChange={handleChange} />
                      </RadioGroup>

                    </FormGroup>
                  )}

                  <FormGroup>
                    <ActionBar>
                      <Button type="submit" onClick={handleSubmit} disabled={isSubmitting} color="primary"
                              variant="contained"> Save </Button>
                      <ButtonLink secondary variant="outlined" to="/"> Cancel </ButtonLink>
                    </ActionBar>
                  </FormGroup>

                </div>
              </Form>


            </div>
          )
        }}
      </Formik>
    )
  }
}

export default withStyles(styles)(ProfileMeals)
