"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.DELETE_RECIPE = exports.SAVE_RECIPE = exports.UPDATE_RECIPE = exports.CREATE_RECIPE = exports.GET_RECIPE = exports.SEARCH_RECIPES = exports.GET_RECIPES = exports.INGREDIENT_FRAGMENT = void 0;

var _graphqlTag = _interopRequireDefault(require("graphql-tag"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n    mutation deleteRecipe($id: ID!) {\n        data: deleteRecipe(id: $id) {\n            id\n        }\n    }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n    mutation saveRecipe($id: ID, $type: String!, $public: Boolean, $clone: Boolean,\n        $instructions: String, $servingSize: Decimal,\n        $dietNames: [String!], $ingredients: [IngredientInput!]!) {\n        data: saveRecipe(id: $id, type: $type, public: $public, clone: $clone,\n            instructions: $instructions, servingSize: $servingSize,\n            dietNames: $dietNames, ingredients: $ingredients) {\n            id\n            type\n            typeLabel\n            shortTypeLabel\n            name\n            public\n            totalG\n            kcal\n            protein\n            fat\n            carbs\n            fiber\n            sugar\n            sodium\n            proteinPct\n            fatPct\n            carbsPct\n            labelNames\n            dietNames\n            mealCount\n            ingredients {\n                ...IngredientDetail\n            }\n            instructions\n            updatedAt\n        }\n    }\n    ", "\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n    mutation saveRecipe($id: ID!, $type: String!, $public: Boolean, $clone: Boolean,\n        $instructions: String,\n        $dietNames: [String!], $ingredients: [IngredientInput!]!) {\n        data: saveRecipe(id: $id, type: $type, public: $public, clone: $clone,\n            instructions: $instructions,\n            dietNames: $dietNames, ingredients: $ingredients) {\n            id\n        }\n    }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n    mutation createRecipe($type: String!, $public: Boolean, $dietNames: [String!],\n        $instructions: String,\n        $ingredients: [IngredientInput!]!) {\n        data: createRecipe(type: $type, public: $public, dietNames: $dietNames,\n            instructions: $instructions,\n            ingredients: $ingredients) {\n            id\n        }\n    }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n    query Recipe($id: ID!) {\n        data: Recipe(id: $id) {\n            id\n            type\n            typeLabel\n            shortTypeLabel\n            name\n            public\n            totalG\n            kcal\n            protein\n            fat\n            carbs\n            fiber\n            sugar\n            sodium\n            proteinPct\n            fatPct\n            carbsPct\n            labelNames\n            dietNames\n            mealCount\n            ingredients {\n                ...IngredientDetail\n            }\n            instructions\n            updatedAt\n        }\n    }\n    ", "\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    query allRecipes($page: Int, $perPage: Int, $filter: RecipeFilter) {\n        items: allRecipes(page: $page, perPage: $perPage, filter: $filter) {\n            id\n            type\n            typeLabel\n            shortTypeLabel\n            name\n            public\n            status\n            totalG\n            kcal\n            proteinPct\n            fatPct\n            carbsPct\n            ingredients {\n                id\n                name\n            }\n            __typename\n        }\n        total: _allRecipesMeta(page: $page, perPage: $perPage, filter: $filter) {\n            count\n            __typename\n        }\n    }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    query allRecipes($page: Int, $perPage: Int, $sortField: String, $sortOrder: String, $filter: RecipeFilter) {\n        items: allRecipes(page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder, filter: $filter) {\n            id\n            type\n            typeLabel\n            shortTypeLabel\n            name\n            public\n            status\n            ingredients {\n                id\n                foodId\n                name\n                apiRef\n            }\n            __typename\n        }\n        total: _allRecipesMeta(page: $page, perPage: $perPage, filter: $filter) {\n            count\n            __typename\n        }\n    }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n    fragment IngredientDetail on Ingredient {\n        id\n        foodId\n        name\n        fixed\n        foodFixed\n        fixedQty\n        fixedUnit\n        apiRef\n        qty\n        unit\n        qtyG\n        kcal\n        protein\n        fat\n        carbs\n        fiber\n        sugar\n        sodium\n        kcalG\n        proteinG\n        fatG\n        carbsG\n        fiberG\n        sugarG\n        sodiumG\n        goesWithId\n        goesWith {\n            id\n            name\n        }\n    }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

var INGREDIENT_FRAGMENT = (0, _graphqlTag.default)(_templateObject());
exports.INGREDIENT_FRAGMENT = INGREDIENT_FRAGMENT;
var GET_RECIPES = (0, _graphqlTag.default)(_templateObject2());
exports.GET_RECIPES = GET_RECIPES;
var SEARCH_RECIPES = (0, _graphqlTag.default)(_templateObject3());
exports.SEARCH_RECIPES = SEARCH_RECIPES;
var GET_RECIPE = (0, _graphqlTag.default)(_templateObject4(), INGREDIENT_FRAGMENT);
exports.GET_RECIPE = GET_RECIPE;
var CREATE_RECIPE = (0, _graphqlTag.default)(_templateObject5());
exports.CREATE_RECIPE = CREATE_RECIPE;
var UPDATE_RECIPE = (0, _graphqlTag.default)(_templateObject6());
exports.UPDATE_RECIPE = UPDATE_RECIPE;
var SAVE_RECIPE = (0, _graphqlTag.default)(_templateObject7(), INGREDIENT_FRAGMENT);
exports.SAVE_RECIPE = SAVE_RECIPE;
var DELETE_RECIPE = (0, _graphqlTag.default)(_templateObject8());
exports.DELETE_RECIPE = DELETE_RECIPE;
var _default = {
  GET_LIST: GET_RECIPES,
  GET_ONE: GET_RECIPE,
  CREATE: CREATE_RECIPE,
  UPDATE: UPDATE_RECIPE // DELETE: deleteFood,

};
exports.default = _default;