import React, { cloneElement, Fragment, PureComponent } from 'react'
import classnames from 'classnames'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  line: {},
  title: {
    margin: [[theme.spacing.unit, 0, theme.spacing.unit * 3]],
    lineHeight: 1.1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '1.5rem',

    [theme.breakpoints.up('sm')]: {
      lineHeight: 0.9,
      fontSize: '2.25rem',
    },

    [theme.breakpoints.up('md')]: {
      lineHeight: 0.9,
      fontSize: '2.75rem',
    },

    '@media print': {
      fontSize: 32,
      margin: 0,
    },

  },
  iconCol: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  aside: {
    textAlign: 'right',

    '@media print': {
      display: 'none',
    },
  },
  condensed: {
    marginBottom: theme.spacing.unit,
  },
})

/**
 @param icon Component - allows passing of an icon, or if none passed, will reserve the space
 @param noIcon boolean - removes the spacing for the icon

 */
class PageTitle extends PureComponent {

  render () {
    const { title, children, icon, noIcon, condensed, aside, classes } = this.props
    // console.log('PageTitle', title, aside)
    const mainColSize = noIcon ? 12 : 10
    const titleClassNames = classnames(classes.title, condensed && classes.condensed)
    const theTitle = children || title || 'Page Title'
    return (
      <Fragment>
        <Grid container className={classes.line}>
          {noIcon ? null : (
            <Grid item xs={2} className={classes.iconCol}>
              {icon && (
                <React.Fragment>
                  {cloneElement(icon, { color: 'primary', style: { fontSize: 40 } })}
                </React.Fragment>
              )}
            </Grid>
          )}
          <Grid item xs={mainColSize}>
            <Typography variant="h2" color="primary" className={titleClassNames}>
              <span>{theTitle}</span>
              {aside && (
                <div className={classes.aside}>
                  {aside}
                </div>
              )}
            </Typography>
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}

export default withStyles(styles)(PageTitle)
