"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CurrentUserConsumer = exports.CurrentUserProvider = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var CurrentUserContext = _react.default.createContext({
  isLoggedIn: false,
  state: {},
  actions: {}
});

var CurrentUserProvider = CurrentUserContext.Provider;
exports.CurrentUserProvider = CurrentUserProvider;
var CurrentUserConsumer = CurrentUserContext.Consumer;
exports.CurrentUserConsumer = CurrentUserConsumer;