import React, { Component } from 'react'
import { Mutation } from 'react-apollo'
import { withRouter, Redirect } from 'react-router-dom'
import get from 'lodash/get'

import { CurrentUserConsumer } from '@balance/lib/contexts'
import { trimAll } from '@balance/lib/utils'
import { SAVE_PROFILE } from '@balance/lib/api/profile'

import { ProfileMeals } from '../../components/Profile'

class ProfileMealsContainer extends Component {

  state = {
    error: '',
    redirectTo: '',
  }

  // componentWillReceiveProps = (nextProps) => {
  //   console.log('ProfileMealsContainer.cWRP', nextProps, this.state, this.props)
  // }

  componentDidUpdate = () => {
    // console.log('ProfileMealsContainer.dU')
    if (this.state.redirectTo) {
      this.setState({redirectTo: ''})
    }
  }

  render () {
    // console.log('profile meals', this.props, this.state)
    const step = 'meals'

    return (
      <CurrentUserConsumer>
        {({state}) => {
          // console.log('ProfileMealsContainer.user consumer', state)
          const profile = get(state, 'profile', {})
          return (
            <Mutation mutation={SAVE_PROFILE}>
              {(saveProfile, {data, loading, error}, ...rest) => {
                // console.log('signin container props', data)

                const handleSubmit = async (values) => {
                  // console.log('profile meals handleSubmit', values, {trimmed: trimAll(values)})
                  const result = await saveProfile({variables: {attributes: {step, ...trimAll(values)}}})
                  const success = get(result, 'data.saveProfile.success', false)
                  if (success) {
                    // refetch && await refetch()
                    this.setState({redirectTo: '/'})
                  }
                  return result
                }

                if (this.state.redirectTo) {
                  return <Redirect to={this.state.redirectTo} />
                }

                return (
                  <ProfileMeals profile={profile} onSubmit={handleSubmit} />
                )
              }}

            </Mutation>
          )
        }}
      </CurrentUserConsumer>
    )
  }
}

export default withRouter(ProfileMealsContainer)
