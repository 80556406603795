import React, { Component } from 'react'
import { Mutation } from 'react-apollo'
import { withRouter, Redirect } from 'react-router-dom'
import get from 'lodash/get'

import { LOGIN } from '@balance/lib/api/user'

import SignIn from '../../components/User/SignIn'

class SignInContainer extends Component {

  state = {
    error: '',
  }

  render () {
    return (
      <Mutation mutation={LOGIN}>
        {(login, { data, loading, error }, ...rest) => {
          // console.log('signin container props', data, loading, error, rest)

          const emailCheck = get(data, 'login.email')
          if (emailCheck) {
            // console.log('signed in, redirecting to front')
            return <Redirect to={{ pathname: '/', state: { reloadUser: true } }} />
          }

          const doLogin = async ({ email, password }) => {
            // console.log('onLogin start', email)
            const result = await login({ variables: { email, password } })
            // console.log('doLogin result', result)
            const emailCheck = get(result, 'data.login.email')
            const loggedIn = emailCheck && emailCheck === email
            // console.log('loggedIn', loggedIn)
            if (loggedIn) {
              // await history.replace('/', { reload: true, loggedIn: true })
              // window.location.replace('/')
              return true
            } else {
              const error = get(result, 'errors.0.message', 'Unable to log you in.')
              this.setState({ error })
              return false
            }
          }
          return (
            <SignIn error={this.state.error} onLogin={doLogin} />
          )
        }}
      </Mutation>
    )

  }
}

export default withRouter(SignInContainer)
