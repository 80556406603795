// @flow
import React, { PureComponent } from 'react'
import get from 'lodash/get'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'

import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'

import { Alert } from '../Modal'
import { Button, ButtonLink } from '../Button'
import { IngredientTable } from '../Table'
import { TypeChip } from '../Chip'

import { colors } from '@balance/lib/styles'
import { Edit, Delete } from '../../styles/icons'

type Props = {
  classes: Object,
  recipe: Object,
  deleteRecipe: Function,
}
type State = {
  alertOpen: boolean,
}

const styles = {
  card: {
    // minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
    textTransform: 'lowercase',
  },
  pos: {
    marginBottom: 12,
  },
  delete: {
    marginLeft: '4rem',
    borderColor: colors.danger,
    color: colors.danger,
  },
  sectionHeader: {
    color: colors.midGray,
    textTransform: 'uppercase',
    margin: 0,
  },
  textarea: {
    width: '100%',
  },
}

class RecipeDetail extends PureComponent<Props, State> {
  static defaultProps = {
    recipe: {},
  }
  state = {
    alertOpen: false,
  }

  handleDeleteConfirmed = async () => {
    const { deleteRecipe } = this.props
    deleteRecipe && await deleteRecipe()
    this.handleClose()
  }

  handleDelete = () => {
    this.setState({ alertOpen: true })
  }

  handleClose = () => {
    this.setState({ alertOpen: false })
  }

  render () {
    const { classes, recipe } = this.props
    // console.log('recipe detail render', recipe)
    const ingredients = get(recipe, 'ingredients', [])
    if (!recipe) {
      return null
    }

    const labels = get(recipe, 'labelNames', [])
    const statusLabel = (recipe && recipe.public !== undefined) ? (recipe.public ? 'public' : 'personal') : ''

    return (
      <div>
        <Card className={classes.card}>
          <CardContent>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              {statusLabel} {recipe.typeLabel}
            </Typography>
            <Typography variant="h5" component="h2">
              {recipe.name || recipe.id}
            </Typography>
            <Typography color="textSecondary">
              Meal Plans Using: {recipe.mealCount}
            </Typography>
            <Typography className={classes.pos} color="textSecondary">
              {recipe.name ? recipe.id : ''}
            </Typography>
            {recipe.instructions && (
              <Typography component="div">
                <TextField disabled label="Instructions" variant="outlined" margin="normal"
                           multiline className={classes.textarea}
                           defaultValue={recipe.instructions} />
              </Typography>
            )}
            <Typography component="div">
              <IngredientTable ingredients={ingredients} recipe={recipe} />
            </Typography>

            {labels && (
              <div>
                <Typography className={classes.pos} color="textSecondary">
                  <strong>Dietary Labels</strong> (based on ingredients)
                </Typography>
                {labels.map((label, i) => <TypeChip key={i} label={label.toLowerCase()} spaced />)}
              </div>
            )}
          </CardContent>
          <CardActions>
            <ButtonLink primary to={`/recipes/${recipe.id}/edit`} size="small">
              <Edit />
              Change
            </ButtonLink>
            <ButtonLink secondary to={`/recipes`} size="small">
              Back
            </ButtonLink>
            <Button onClick={this.handleDelete} size="small" className={classes.delete}>
              <Delete />
              Delete
            </Button>
          </CardActions>
        </Card>
        <Alert onCancel={this.handleClose}
               onProceed={this.handleDeleteConfirmed}
               dangerous
               open={this.state.alertOpen}
               title="Confirm Deletion"
               text="Are you sure?" />
      </div>
    )
  }
}

export default withStyles(styles)(RecipeDetail)
