import React from 'react'
import { withStyles, Typography } from '@material-ui/core'
import classNames from 'classnames'

const styles = theme => ({
  inline: {
    display: 'inline',
    border: 'none',
    margin: 0,
    padding: 0,
    backgroundColor: 'transparent',
    outline: 'none',
    cursor: 'pointer',
  },
})

const InlineButton = ({classes, children, className, ...rest}) => {
  return (
    <Typography color="primary" component="button" {...rest} className={classNames([classes.inline, className])}>
      {children}
    </Typography>
  )
}

export default withStyles(styles)(InlineButton)
