// @flow
import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core'
import PageTitle from '../PageTitle/PageTitle'
import styles from '../../styles/global'

type Props = {
  user?: Object,
}
type State = {}

class InactiveAccount extends PureComponent<Props, State> {
  static defaultProps = {}

  render () {
    const {classes} = this.props

    return (
      <div className={classes.container}>
        <PageTitle noIcon title="Account Inactive" />

        <p>
          Your account is not currently active. <Link to="/profile/payment">Upgrade now</Link> to
          start using our platform.
        </p>

      </div>
    )
  }
}

export default withStyles(styles)(InactiveAccount)
