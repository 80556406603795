import React, { Component } from 'react'
import { Mutation, Query } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'
import SignUp from '../../components/User/SignUp'
import { GET_TIME_ZONES, SIGNUP } from '@balance/lib/api/user'

class SignUpContainer extends Component {
  state = {
    error: '',
  }

  render () {
    console.log('sign up container', this.props)
    return (
      <Query query={GET_TIME_ZONES} fetchPolicy="cache-first">
        {({ data }) => {

          const timeZones = get(data, 'systemConfig.timeZones', [])

          return (
            <Mutation mutation={SIGNUP}>
              {(signup) => {
                return (
                  <SignUp
                    error={this.state.error}
                    history={this.props.history}
                    timeZones={timeZones}
                    onSubmit={async (attributes) => {
                      // console.log('SignUpContainer.onSubmit', attributes)
                      const result = await signup({
                        variables: { attributes },
                      })
                      // console.log('signup onSubmit result', result)
                      if (get(result, 'data.signup.id')) {
                        // console.log('redirecting to home ...', this.props.history)
                        this.props.history.push('/signup-complete', { reloadUser: true, loggedIn: true })
                      }
                      return result
                    }}
                  />
                )
              }}
            </Mutation>
          )
        }}
      </Query>
    )
  }
}

export default withRouter(SignUpContainer)
