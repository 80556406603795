import React, { Component } from 'react'
import { Query, ApolloConsumer } from 'react-apollo'
import { withRouter, Redirect } from 'react-router-dom'
import get from 'lodash/get'

import { CurrentUserConsumer } from '@balance/lib/contexts'
import { GET_RECIPE, SAVE_RECIPE } from '@balance/lib/api/recipes'
import { GET_FOODS } from '@balance/lib/api/foods'

import { RecipeEdit } from '../../components/Recipe'

class RecipeEditContainer extends Component {

  constructor (props, ctx) {
    super(props, ctx)

    this.state = {
      error: '',
      redirectBackTo: null,
      haveRecipeId: Boolean(get(this.props, 'match.params.recipeId')),
    }
  }

  componentDidUpdate (): void {
    // console.log('RecipeEditContainer.cDU', this.props)
    const recipeId = get(this.props, 'match.params.recipeId')
    if (Boolean(recipeId) !== this.state.haveRecipeId) {
      this.setState({ haveRecipeId: Boolean(recipeId) })
    }
  }

  render () {
    // console.log('recipe edit container render', this.props)
    const recipeId = get(this.props, 'match.params.recipeId')
    // console.log('recipeId', recipeId)
    if (this.state.redirectBackTo) {
      return (
        <Redirect to={{ pathname: `/recipes/${this.state.redirectBackTo}`, state: { reload: true } }} />
      )
    }

    return (
      <CurrentUserConsumer>
        {({ state }) => {
          // console.log('cu', state)
          // console.log('haveRecipeId?', this.state.haveRecipeId)
          return (
            <Query query={GET_RECIPE} variables={{ id: recipeId }} skip={!this.state.haveRecipeId}>
              {({ data, refetch, ...props }) => {
                // console.log('recipe edit container props', data, props)

                const recipe = get(data, 'data', {})

                return (
                  <ApolloConsumer>
                    {(client, ...rest) => {
                      // console.log('RecipeEditContainer.props', client, rest)

                      const searchFoods = async (queryTerm, source = 'local') => {
                        // console.log('searchFoods', queryTerm, source)

                        let filter = {
                          q: queryTerm,
                          source: source || 'local',
                        }

                        const { data } = await client.query({
                          query: GET_FOODS,
                          fetchPolicy: 'network-only',
                          variables: { filter },
                        })
                        // console.log('search data', data)
                        return get(data, 'items', [])
                      }

                      const saveRecipe = async (attributes) => {
                        // console.log('saveRecipe', recipe)
                        const { data } = await client.mutate({
                          mutation: SAVE_RECIPE,
                          variables: attributes,
                        })
                        const result = get(data, 'data', {})
                        // console.log('result', result)
                        if (result && result.id) {
                          if (recipeId) { // only refetch from existing recipe
                            // console.log('refetching', result.id)
                            this.setState({ haveRecipeId: true }, async () => {
                              // console.log('really fetching now ...')
                              await refetch({ id: result.id })
                              this.setState({ redirectBackTo: result.id })
                            })
                          } else {
                            // console.log('done! now redirecting....')
                            this.setState({ redirectBackTo: result.id })
                          }
                        }
                        return result
                      }
                      return (
                        <RecipeEdit initialRecipe={recipe}
                                    user={state}
                                    searchFoods={searchFoods}
                                    saveRecipe={saveRecipe}
                        />
                      )
                    }}
                  </ApolloConsumer>
                )
              }}
            </Query>
          )
        }}
      </CurrentUserConsumer>
    )
  }
}

export default withRouter(RecipeEditContainer)
