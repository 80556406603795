import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import CoreButton from '@material-ui/core/Button'
import classNames from 'classnames'

// import { colors } from '@balance/lib/styles'

const styles = theme => ({
  button: {
    marginRight: theme.spacing.unit,
  },
  primary: {
    // color: colors.yellow,
    //   color: 'black',
    //
    //   '&:hover, &:active': {
    //     backgroundColor: '#e2d96e',
    //   },
  },
  secondary: {
    // backgroundColor: colors.yellow,
    // borderColor: colors.yellow,
    // color: 'black',
    //
    // '&:hover, &:active': {
    //   backgroundColor: '#e2d96e',
    //   borderColor: '#e2d96e',
    // },
  },
})

export const Button = ({ classes, className, primary, secondary, ...rest }) => {
  const isPrimary = primary || (rest.color && rest.color === 'primary')
  const isSecondary = secondary || (rest.color && rest.color === 'secondary')
  const myProps = {
    color: primary ? 'primary' : secondary ? 'secondary' : rest.color,
    variant: primary ? 'contained' : 'outlined',
  }
  const buttonClasses = classNames(
    [classes.button, isPrimary && classes.primary, isSecondary && classes.secondary, className])
  return (
    <CoreButton  {...myProps} {...rest} className={buttonClasses} />
  )
}

export default withStyles(styles)(Button)
