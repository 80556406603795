// @flow
import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import classNames from 'classnames'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import IconButton from '@material-ui/core/IconButton'
import Checkbox from '@material-ui/core/Checkbox'
import Radio from '@material-ui/core/Radio'
import DisplayField from './DisplayField'

import { Delete } from '../../styles/icons'

type Props = {
  classes: Object,
  fieldProps: Object,
  children: any,
  name: string,
  value: string | number | any,
  label: string,
  required: boolean,
  editable: boolean,
  half: boolean,
  select: boolean,
  checkbox: boolean,
  radio: boolean,
  checked: boolean,
  deletable: boolean,
  onDelete: Function,
  touched: boolean,
  error: ?any,
}

const styles = theme => ({
  wrapper: {
    paddingRight: 4 * theme.spacing.unit,
  },
  textField: {
    backgroundColor: '#eee',
    // margin: theme.spacing.unit,
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    // marginRight: 2 * theme.spacing.unit,
    // width: '95%',
    // paddingRight: theme.spacing.unit,
    // maxWidth: 300,
  },
  inputField: {
    // backgroundColor: '#eee',
    // padding: [[0, theme.spacing.unit * 2, theme.spacing.unit]],
    alignItems: 'center',
  },
  inputLabel: {
    // left: 4
    '&:focus': {
      left: 10,
      backgroundColor: 'transparent',
    },
  },
  select: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  helperText: {
    marginTop: 0,
    padding: [[theme.spacing.unit, 0 * theme.spacing.unit * 2]],
    backgroundColor: theme.palette.background.default,
  },
  error: {
    color: theme.palette.error.main
  },
  deleteBtn: {
    width: 32,
    height: 32,
  },
  deleteIcon: {
    fontSize: 16,
  },
  checkbox: {
    width: '100%',
  },
})

const FormField = ({ children, classes, className, name, value, defaultValue, label, required, select, radio, checkbox, checked, deletable, editable, onDelete, touched, error, fieldProps, half, ...props }: Props) => {

  if (defaultValue === null) {
    defaultValue = ''
  }
  if (value === null) {
    value = defaultValue
  }
  // console.log('FormField.render', value, defaultValue)

  // if (select) {
  //   console.log('FormField', {label, value: props.value, touched, error, fieldProps, props})
  // }

  if (checkbox) {
    // console.log('checkbox', label, checked)
    return (
      <FormControlLabel
        className={classes.checkbox}
        name={name}
        value={value}
        control={
          <Checkbox color="default" checked={Boolean(checked)} {...props} />
        }
        label={label}
      />
    )
  }

  if (radio) {
    return (
      <FormControlLabel control={<Radio checked={props.checked} />} name={name} value={value} label={label} {...props} />
    )
  }

  let selectProps = {}
  if (select) {
    if (value === undefined) {
      value = ''
    }
    selectProps = {
      select: true,
      SelectProps: {
        SelectDisplayProps: {
          id: name,
        },
        // required: required,
        className: classNames(classes.inputField, classes.inputLabel, classes.select),
      },
      InputProps: {
        ...props.InputProps,
        // required: required,
        className: classNames(classes.inputField, classes.inputLabel, classes.select),
      },
    }
  }
  // console.log('select', selectProps)

  if (!editable) {
    return (
      <DisplayField children={children} label={label} value={value} />
    )
  }

  const inputProps = { className: classes.inputField, ...fieldProps }
  if (deletable) {
    inputProps['endAdornment'] = (
      <IconButton className={classes.deleteBtn} onClick={onDelete}>
        <Delete className={classes.deleteIcon} />
      </IconButton>
    )
  }

  const helperText = (touched && error) ? error : props.helperText

  const inputLabelProps = props.multiline ? {
    shrink: (Boolean(defaultValue && defaultValue.length > 0)),
  } : {}
  return (
    <TextField
      name={name}
      value={value}
      label={label}
      error={touched && !!error}
      required={required}
      fullWidth={true}
      className={classNames(classes.textField, className)}
      InputLabelProps={{ className: classNames(classes.inputField, classes.inputLabel), ...inputLabelProps }}
      InputProps={inputProps}
      FormHelperTextProps={{ className: classes.helperText }}
      {...props}
      {...selectProps}
      helperText={helperText}
    >
      {children}
    </TextField>
  )
}

FormField.defaultProps = {
  editable: true,
}

export default withStyles(styles)(FormField)
