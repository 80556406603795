"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_GROCERY_LIST = exports.ADD_RECIPE_TO_MEAL = exports.REMOVE_MEAL = exports.REPLACE_MEAL_PLAN = exports.REPLACE_MEAL = exports.GET_MEAL_PREP = exports.GET_MEAL_PLAN = exports.MEAL_PLAN_FRAGMENT = void 0;

var _graphqlTag = _interopRequireDefault(require("graphql-tag"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n    query getGroceryList($week: String, $date: String) {\n        groceryList(week: $week, date: $date) {\n            id\n            weekOfShort\n            items {\n                foodId\n                name\n                uses\n                qty\n                unit\n                qtyG\n                packagedAs {\n                    name\n                    qty\n                    unit\n                }\n                fixed\n                fixedQty\n                fixedUnit\n            }\n        }\n    }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n    mutation addRecipeToMeal($mealPlanId: ID!, $recipeId: ID!, $mealNum: Int!, $dayNum: Int!) {\n        addRecipeToMeal(mealPlanId: $mealPlanId, recipeId: $recipeId, mealNum: $mealNum, dayNum: $dayNum) {\n            plan {\n                ...MealPlanDetail\n            }\n        }\n    }\n    ", "\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n    mutation removeMeal($mealPlanId: ID!, $mealId: ID!) {\n        removeMeal(mealPlanId: $mealPlanId, mealId: $mealId) {\n            plan {\n                ...MealPlanDetail\n            }\n        }\n    }\n    ", "\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n    mutation replaceMealPlan($mealPlanId: ID!, $week: String!) {\n        replaceMealPlan(mealPlanId: $mealPlanId, week: $week) {\n            plan {\n                ...MealPlanDetail\n            }\n        }\n    }\n    ", "\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n    mutation replaceMeal($mealPlanId: ID!, $mealId: ID!, $mealNum: Int!, $dayNum: Int!, $all: Boolean, $withRecipeId: ID) {\n        replaceMeal(mealPlanId: $mealPlanId, mealId: $mealId, mealNum: $mealNum, dayNum: $dayNum, all: $all, withRecipeId: $withRecipeId) {\n            plan {\n                ...MealPlanDetail\n            }\n        }\n    }\n    ", "\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    query getMealPrep($week: String, $date: String) {\n        mealPrep(week: $week, date: $date) {\n            id\n            weekOfShort\n            groupedRecipes : recipes {\n                id\n                count\n                name\n                instructions\n                type\n                ingredients {\n                    id\n                    foodId\n                    name\n                    qty\n                    fixed\n                    foodFixed\n                    fixedQty\n                    fixedUnit\n                    qtyG\n                    qtyOz\n                }\n            }\n        }\n    }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    query getMealPlan($week: String, $date: String) {\n        mealPlan(week: $week, date: $date) {\n            ...MealPlanDetail\n        }\n    }\n    ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n    fragment MealPlanDetail on MealPlan {\n        id\n        weekOfShort\n        dayCount\n        mismatch\n        days {\n            number\n            label\n            mealIds\n            totalG\n            kcal\n            kcalTarget\n            protein\n            fat\n            carbs\n            fiber\n            sugar\n            sodium\n            proteinPct\n            fatPct\n            carbsPct\n            message\n            mismatch\n        }\n        meals {\n            id\n            name\n            number\n            type\n            kcal\n            kcalTarget\n            protein\n            fat\n            carbs\n            fiber\n            sugar\n            sodium\n            proteinPct\n            fatPct\n            carbsPct\n            recipeIds\n            ingredients {\n                id\n                foodId\n                name\n                qty\n                fixed\n                foodFixed\n                fixedQty\n                fixedUnit\n                qtyG\n                qtyOz\n                kcal\n                protein\n                fat\n                carbs\n                fiber\n                sugar\n                sodium\n                goesWithId\n            }\n            message\n            mismatch\n        }\n        recipes {\n            id\n            type\n            name\n            instructions\n            ingredients {\n                id\n                foodId\n                name\n                kcalG\n                proteinG\n                fatG\n                carbsG\n            }\n            createdBy {\n                id\n                name\n            }\n        }\n    }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

var MEAL_PLAN_FRAGMENT = (0, _graphqlTag.default)(_templateObject());
exports.MEAL_PLAN_FRAGMENT = MEAL_PLAN_FRAGMENT;
var GET_MEAL_PLAN = (0, _graphqlTag.default)(_templateObject2(), MEAL_PLAN_FRAGMENT);
exports.GET_MEAL_PLAN = GET_MEAL_PLAN;
var GET_MEAL_PREP = (0, _graphqlTag.default)(_templateObject3());
exports.GET_MEAL_PREP = GET_MEAL_PREP;
var REPLACE_MEAL = (0, _graphqlTag.default)(_templateObject4(), MEAL_PLAN_FRAGMENT);
exports.REPLACE_MEAL = REPLACE_MEAL;
var REPLACE_MEAL_PLAN = (0, _graphqlTag.default)(_templateObject5(), MEAL_PLAN_FRAGMENT);
exports.REPLACE_MEAL_PLAN = REPLACE_MEAL_PLAN;
var REMOVE_MEAL = (0, _graphqlTag.default)(_templateObject6(), MEAL_PLAN_FRAGMENT);
exports.REMOVE_MEAL = REMOVE_MEAL;
var ADD_RECIPE_TO_MEAL = (0, _graphqlTag.default)(_templateObject7(), MEAL_PLAN_FRAGMENT);
exports.ADD_RECIPE_TO_MEAL = ADD_RECIPE_TO_MEAL;
var GET_GROCERY_LIST = (0, _graphqlTag.default)(_templateObject8());
exports.GET_GROCERY_LIST = GET_GROCERY_LIST;