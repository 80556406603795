// @flow
import React, { PureComponent } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'

import FormField from './FormField'
import { InlineButton } from '../Button'

type Props = {
  timeZones: Array<Object>,
  timezone: string,
  name: string,
  label: string,
  onChange: Function,
}
type State = {
  editing: boolean,
}

const styles = (theme) => ({})

class TimeZoneField extends PureComponent<Props, State> {
  static defaultProps = {
    timezone: '',
    timeZones: [],
  }

  state = {
    editing: false,
  }

  toggleEditing = (e) => {
    e.preventDefault()
    this.setState(prevState => ({ editing: !prevState.editing }))
  }

  renderForm = () => {
    const { timeZones, classes, onChange, timezone } = this.props

    return (
      <Typography component="div">
        <FormField select className={classes.unit}
                   InputProps={{ disableUnderline: false }}
                   onChange={onChange}
                   name="timezone"
                   label="Time Zone"
                   value={timezone}>
          {timeZones.map(option => (
            <MenuItem key={option.tzname} value={option.tzname}>
              {option.name}
            </MenuItem>
          ))}
        </FormField>
      </Typography>
    )
  }

  renderNonForm = () => {
    return (
      <Typography>
        Time Zone: {this.props.timezone} <InlineButton onClick={this.toggleEditing}>(Incorrect or Need to
        change?)</InlineButton>
      </Typography>
    )
  }

  render () {
    return this.state.editing ? this.renderForm() : this.renderNonForm()
  }
}

export default withStyles(styles)(TimeZoneField)
