// @flow
import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { withStyles, Typography } from '@material-ui/core'
import { hasProfile, hasMealSettings } from '@balance/lib/api/profile'

import PageTitle from '../PageTitle/PageTitle'
import styles from '../../styles/global'

type Props = {
  profile: Object,
}
type State = {}

class ProfileNotComplete extends PureComponent<Props, State> {
  static defaultProps = {}

  renderNoProfile = () => {
    return (
      <Typography>You haven't setup a profile yet! <Link to="/profile/demo">Please do so now.</Link></Typography>
    )
  }

  renderIncompleteProfile = () => {
    return (
      <Typography>You haven't finished setting up your profile yet! <Link to="/profile/demo">Please do so
        now.</Link></Typography>
    )

  }

  renderNoMealSettings = () => {
    return (
      <Typography>You haven't configured your meal settings yet! <Link to="/profile/meals">Please do so
        now.</Link></Typography>
    )
  }

  renderError = () => {
    return (
      <Typography>Your profile doesn't seem complete. <Link to="/profile/demo">Please update it now.</Link></Typography>
    )
  }

  render () {
    const {classes, profile} = this.props

    const noProfile = !Boolean(profile)
    const profileSetup = hasProfile(profile)
    const hasMeals = hasMealSettings(profile)

    return (
      <div className={classes.container}>
        <PageTitle noIcon title="Profile Incomplete" />

        {noProfile ? this.renderNoProfile() :
          !profileSetup ? this.renderIncompleteProfile() :
            !hasMeals ? this.renderNoMealSettings() : this.renderError()}

      </div>
    )
  }
}

export default withStyles(styles)(ProfileNotComplete)
