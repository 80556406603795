// import React from 'react'
import get from 'lodash/get'
// import { Platform } from 'react-native'

// import DeviceInfo from 'react-native-device-info'

// helper method for saving device token to server
export const info = () => {
  return {
    platform: get(window, 'device.platform'),
    platformType: get(window, 'device.model'),
    platformVersion: get(window, 'device.version'),
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  }
}
