import React from 'react'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import ButtonBase from '@material-ui/core/ButtonBase'

const styles = {}

export const ButtonLink = ({classes, ...rest}) => {
  return (
    <ButtonBase component={Link} {...rest} />
  )
}

export default withStyles(styles)(ButtonLink)
