"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IS_DEV_API = exports.IS_PROD_API = exports.IS_PROD_SITE = exports.IS_DEV = exports.IS_WEB = exports.getApiUrl = exports.API_URL = exports.apiUrl = exports.BASE_URL = exports.SESSION_KEY = exports.CSRF_TOKEN = exports.IMPERSONATION_ACTIVE = exports.IMPERSONATION_TOKEN = exports.ADMIN_AUTH_TOKEN = exports.AUTH_TOKEN = exports.checkIfProdApi = exports.isWeb = exports.getHost = void 0;

var _get = _interopRequireDefault(require("lodash/get"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var getHost = function getHost(w) {
  return (0, _get.default)(w, 'location.host');
};

exports.getHost = getHost;

var isWeb = function isWeb(w) {
  return getHost(w) !== null;
};

exports.isWeb = isWeb;

var checkIfProdApi = function checkIfProdApi() {};

exports.checkIfProdApi = checkIfProdApi;

var convertHttpToWs = function convertHttpToWs(string) {
  var matched = string.match(/^(https?):\/\/([^\/]+)/); // console.log('matched', matched)

  if (matched && matched.length === 3) {
    var prot = matched[1] === 'https' ? 'wss' : 'ws';
    return prot + '://' + matched[2];
  }

  return null;
};

var determineWsHost = function determineWsHost() {
  var env = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : process.env;
  var fallback = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ORIGIN;
  var ws = '';

  if (env.REACT_APP_WS_HOST) {
    ws = env.REACT_APP_WS_HOST;
  }

  if (!ws && env.REACT_APP_API_URL) {
    ws = convertHttpToWs(env.REACT_APP_API_URL);
  }

  if (!ws) {
    ws = convertHttpToWs(fallback);
  } //console.log('ws host', ws)


  return ws;
};

var AUTH_TOKEN = 'auth-token';
exports.AUTH_TOKEN = AUTH_TOKEN;
var ADMIN_AUTH_TOKEN = 'admin-auth-token';
exports.ADMIN_AUTH_TOKEN = ADMIN_AUTH_TOKEN;
var IMPERSONATION_TOKEN = 'impersonation-token';
exports.IMPERSONATION_TOKEN = IMPERSONATION_TOKEN;
var IMPERSONATION_ACTIVE = 'impersonation-active';
exports.IMPERSONATION_ACTIVE = IMPERSONATION_ACTIVE;
var CSRF_TOKEN = 'csrf-token';
exports.CSRF_TOKEN = CSRF_TOKEN;
var SESSION_KEY = 'session-key'; // export const apiUrl = window.cordova ? 'http://app.b.localhost:3001/api' : process.env.REACT_APP_API_URL

exports.SESSION_KEY = SESSION_KEY;
console.log('base url checks', window.BOD_BASE_URL, process.env.REACT_APP_BASE_URL, window.location.origin);
var baseUrlFromEnv = window.BOD_BASE_URL || process.env.REACT_APP_BASE_URL || window.location.origin;
var apiUrlFromEnv = window.BOD_API_URL || process.env.REACT_APP_API_URL;
var BASE_URL = baseUrlFromEnv || 'https://app.balanceondemand.com';
exports.BASE_URL = BASE_URL;
var apiUrl = apiUrlFromEnv || 'https://app.balanceondemand.com/api';
exports.apiUrl = apiUrl;
var API_URL = apiUrl || '/api';
exports.API_URL = API_URL;
console.log('apiUrl', {
  BASE_URL: BASE_URL,
  API_URL: API_URL,
  apiHost: apiUrl,
  apiUrlFromEnv: apiUrlFromEnv,
  cordova: window.cordova
}); // export const WEBSOCKET_HOST = determineWsHost(process.env)

var ORIGIN = (0, _get.default)(window, 'location.origin', 'https://app.balanceondemand.com/api');

var getApiUrl = function getApiUrl() {
  return window.BOD_API_URL || process.env.REACT_APP_API_URL || '/api';
};

exports.getApiUrl = getApiUrl;
var IS_WEB = !!isWeb(window);
exports.IS_WEB = IS_WEB;
var IS_DEV = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'; // export const IS_PROD_API: boolean = API_URL && (API_URL.match(/app.balanceondemand.com/) || (IS_WEB && API_URL === '/api'))

exports.IS_DEV = IS_DEV;
var IS_PROD_SITE = getHost(window) === 'app.balanceondemand.com';
exports.IS_PROD_SITE = IS_PROD_SITE;
var IS_PROD_API = API_URL && (API_URL.match(/app.balanceondemand.com/) || API_URL === '/api');
exports.IS_PROD_API = IS_PROD_API;
var IS_DEV_API = !IS_PROD_API;
exports.IS_DEV_API = IS_DEV_API;