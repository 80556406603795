import React, { PureComponent } from 'react'

export class NoMatch extends PureComponent {
  render () {
    return (
      <div>
        <p>Not Found!</p>
      </div>
    )
  }
}

export default NoMatch
