// @flow
import React, { PureComponent } from 'react'
import type { CallBackProps } from 'react-joyride'
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride'

import get from 'lodash/get'
import { colors } from '@balance/lib/styles'

type Props = {
  onboarded: ?Array<string>,
  path: string,
  onTourEnd: Function,
}
type State = {
  currentPathKey: string,
}

const commonStepParameters = {
  disableBeacon: true,
  hideCloseButton: true,
}

const DEBUG_ONBOARDING = false

class Onboarding extends PureComponent<Props, State> {
  static defaultProps = {
    onboarded: null,
    path: '',
  }

  state = {
    run: false,
    currentPath: '',
    currentPathKey: '',
    pathKeys: {
      '/meal-plan': 'meal-plan',
      '/profile/demo': 'profile-start',
      // '/profile/targets': 'profile-targets',
    },
    stepGroups: {
      'meal-plan': [
        {
          ...commonStepParameters,
          target: 'body',
          placement: 'center',
          content: <div><h2>Welcome to the meal planning screen for Balance on Demand!</h2><p>This is where you can view
            each day's meal plan,
            broken down based on your configured profile and meal settings.</p></div>,
        },
        {
          ...commonStepParameters,
          target: '[data-tour="meal-prep"]',
          placement: 'bottom-end',
          content: 'This will let you switch to a variation of your plan to aid in preparing your meals at once!',
        },
        {
          ...commonStepParameters,
          target: '[data-tour="grocery-list"]',
          placement: 'bottom-end',
          content: 'This will give you a consolidated list of ingredients so you can zip through the grocery store quickly!',
        },
        {
          ...commonStepParameters,
          target: '[data-tour="menu-button"]',
          placement: 'bottom-start',
          content: 'Access all screens here, including your profile and billing information.',
        },
        {
          ...commonStepParameters,
          target: '[data-tour="meal-plan-panel"]',
          content: 'You can tap on each line to view the day\'s meal.',
        },
        {
          ...commonStepParameters,
          target: '[data-tour="meal-plan-instructions"]',
          placement: 'bottom-end',
          content: 'You can view our instructions for preparing this meal using the given ingredients.',
        },
        {
          ...commonStepParameters,
          target: '[data-tour="meal-plan-actions"]',
          placement: 'top-end',
          content: 'You can replace this recipe for just this day or all times if it\'s repeated for other meals. You can also remove the meal and then search for a new recipe.',
        },
        {
          ...commonStepParameters,
          target: '[data-tour="menu-option-recipe-list"]',
          placement: 'right',
          content: 'And you can view all available recipes and add your own here.',
        },
        {
          ...commonStepParameters,
          hideCloseButton: false,
          target: 'body',
          placement: 'center',
          content: <div><h2>Happy Planning!</h2></div>,
        },
      ],
      'profile-start': [
        {
          ...commonStepParameters,
          target: 'body',
          placement: 'center',
          content: <div><h2>Welcome to Balance on Demand!</h2><p>First we need to gather some details to setup your
            profile.</p></div>,
        },
        {
          ...commonStepParameters,
          target: '[data-tour="profile-tabs"]',
          content: <p>These buttons will allow you to jump to the different profile sections. But don't skip ahead
            just yet! We'll walk you through each step this first time.</p>,
        },
        {
          ...commonStepParameters,
          target: '[data-tour="profile-tabs"] [data-tour="profile-tab-Targets"]',
          content: <p>Once you complete your profile and get here, we'll show you the target calorie and
            macro nutrient targets we've calculated for you. But of course you can fine-tune those
            further if you need to!</p>,
        },
      ],
      'profile-targets': [
        {
          ...commonStepParameters,
          target: 'body',
          placement: 'center',
          showProgress: false,
          content: <div><h2>Excellent!</h2><p>We've now calculated some initial calorie and macro nutrient goals for you
            based
            on the information you've provided. You can get started with these to see how they work for
            you, but can also come back anytime and fine-tune for more targeted results.</p></div>,
        },
      ],
    },
  }

  static getDerivedStateFromProps (props, state) {
    // console.log('getDerivedStateFromProps', state.currentPathKey, state.currentPath, props.path)
    const hasSeen = props.onboarded && props.onboarded.includes(props.path)
    // console.log('hasSeen?', hasSeen)
    if (props.path !== state.currentPath) {
      return {
        run: !hasSeen,
        stepIndex: 0,
        currentPath: props.path,
        currentPathKey: get(state.pathKeys, props.path),
      }
    }
    return null
  }

  handleJoyrideCallback = async (data: CallBackProps) => {
    const finishedStatuses: Array<string> = [STATUS.FINISHED, STATUS.SKIPPED]

    const { sidebarOpen } = this.state
    const { action, index, type, status } = data

    if (([STATUS.FINISHED, STATUS.SKIPPED]: Array<string>).includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      // console.log('resetting?')
      this.setState({ run: false, stepIndex: 0 })
      await this.props.onTourEnd && this.props.onTourEnd(this.state.pathKeys[this.props.path])
    } else if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND]: Array<string>).includes(type)) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1)

      if (sidebarOpen && index === 0) {
        setTimeout(() => {
          this.setState({ run: true })
        }, 400)
      } else if (sidebarOpen && index === 1) {
        this.setState(
          {
            run: false,
            sidebarOpen: false,
            stepIndex,
          },
          () => {
            setTimeout(() => {
              this.setState({ run: true })
            }, 400)
          },
        )
      } else if (index === 2 && action === ACTIONS.PREV) {
        this.setState(
          {
            run: false,
            sidebarOpen: true,
            stepIndex,
          },
          () => {
            setTimeout(() => {
              this.setState({ run: true })
            }, 400)
          },
        )
      } else {
        // Update state to advance the tour
        this.setState({
          sidebarOpen: false,
          stepIndex,
        })
      }
    }

    // tslint:disable:no-console
    console.groupCollapsed(type === EVENTS.TOUR_STATUS ? `${type}:${status}` : type)
    console.log(data)
    console.groupEnd()
    // tslint:enable:no-console

    ////////

    // console.log('tour callback', this.state.currentPathKey, data, status, type, action, index)
    if (finishedStatuses.includes(status) && type === EVENTS.TOUR_END) {
      console.log('all done! updating user profile ....')
      this.setState({ run: false })
      await this.props.onTourEnd && this.props.onTourEnd(this.state.pathKeys[this.props.path])
    } else if (this.state.currentPathKey === 'meal-plan') {
      if (action === 'update' && index === 5) {
        document.querySelector('[data-tour="meal-plan-panel"]:first-of-type [role="button"][aria-expanded="false"]')
          .click()
        // document.querySelector('[data-tour="meal-plan-panel"] [role="button"]:not([aria-expanded="true"]').click()
      } else if (action === 'update' && index === 6) {
        console.log('closing drawer ...')
        window.dispatchEvent(new Event('tour:closeDrawer'))
      } else if ((action === 'update' && index === 7) || (action === 'next' && index === 6) ||
        (action === 'prev' && index === 8)) {
        console.log('opening drawer ...')
        window.dispatchEvent(new Event('tour:openDrawer'))
      } else if (action === 'update' && index === 8) {
        console.log('closing drawer ...')
        window.dispatchEvent(new Event('tour:closeDrawer'))
        // document.querySelector('[data-tour="menu-button"]').click()
      }
    }
  }

  render () {
    // console.log('Onboarding.render', this.props, this.state)
    const { path, onboarded } = this.props
    const { run, stepIndex, stepGroups, pathKeys } = this.state

    const pathKey = pathKeys[path]
    // console.log('pathKey', pathKey, currentPathKey)
    if (onboarded && pathKey && onboarded.includes(pathKey)) {
      return null
    }
    const steps = stepGroups[pathKey]
    // console.log('steps', steps)
    if (!steps) {
      return null
    }

    return (
      <div>
        <Joyride steps={steps} debug={DEBUG_ONBOARDING}
                 run={run}
                 stepIndex={stepIndex}
                 continuous showProgress scrollToFirstStep
                 disableScrolling={false} disableOverlayClose disableCloseOnEsc
                 showSkipButton={false} callback={this.handleJoyrideCallback}
                 locale={{ last: 'Close' }}
                 styles={{
                   options: {
                     primaryColor: colors.darkGreen,
                     zIndex: 10000,
                   },
                 }} />
      </div>
    )
  }
}

export default Onboarding
