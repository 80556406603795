import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import CoreButton from '@material-ui/core/ButtonBase'

const styles = {}

export const ButtonSimple = ({classes, ...rest}) => {
  return (
    <CoreButton {...rest} />
  )
}

export default withStyles(styles)(ButtonSimple)
