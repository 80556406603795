
export const printStyles = theme => ({
  noPrint: {
    '@media print': {
      display: 'none',
    },
  },
  printOnly: {
    display: 'none',
    '@media print': {
      display: 'inline',
    },
  },
  printOneLine: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',

    '@media print': {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  printTitle: {
    '@media print': {
      margin: [[0, 0, theme.spacing.unit * 2]],
      borderBottom: '1px solid #999',
    },
  },
})

export default printStyles
