import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Modal } from '../Modal'
import Spinner from './Spinner'

const styles = theme => ({
  wrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  label: {
    fontSize: 24,
    marginLeft: theme.spacing.unit,
    color: theme.colors.yellow,
  },
  spinner: {
    color: theme.colors.yellow,
  },
  progress: {
    width: '1em',
    height: '1em',
    margin: 4,
  },
})

const FullPageSpinner = withStyles(styles)(({ classes, open, label = 'Loading ...', hideBackdrop, ...props }) => {
  return (
    <Modal open={open} transparent pageCentered disableBackdropClick disableEnforceFocus disableEscapeKeyDown
           hideBackdrop={hideBackdrop}>
      <div className={classes.wrap}>
        <Spinner className={classes.spinner} {...props} />
        <Typography className={classes.label} color="primary">{label}</Typography>
      </div>
    </Modal>
  )
})

export default FullPageSpinner
