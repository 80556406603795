// @flow
import React, { PureComponent, Fragment, cloneElement } from 'react'
import { withStyles } from '@material-ui/core/styles'
import get from 'lodash/get'
import compact from 'lodash/compact'

import Grid from '@material-ui/core/Grid'
import FormLabel from '@material-ui/core/FormLabel'
import Button from '@material-ui/core/Button'
import MuiList from '@material-ui/core/List'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import Collapse from '@material-ui/core/Collapse'

import { asFloat } from '@balance/lib/utils'

import { FormField, Switch } from '../Form'

import { Delete as DeleteIcon, ExpandLess, ExpandMore, Link as LinkIcon } from '../../styles/icons'

import baseStyles from '../../styles/forms'

type Props = {
  items: Array<Object>,
  errors: Array<Object>,
  onUpdate: Function,
  onDelete: Function,
}
type State = {
  open: string, // ID of item
}

const styles = theme => ({
  ...baseStyles(theme),
  line: {
    display: 'flex',
    alignItems: 'center',
  },
  qty: {
    width: 50,
    marginRight: theme.spacing.unit,
  },
  unit: {
    width: 100,
    marginRight: theme.spacing.unit,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  gridLine: {
    alignItems: 'center',
  },
  gridQty: {
    order: 1,
    display: 'inline-flex',
    flexWrap: 'nowrap',
  },
  gridLabel: {
    order: 0,
    [theme.breakpoints.up('sm')]: {
      order: 2,
    },
  },
  secAction: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
})

const UNITS = [
  'oz',
  'g',
  'kg',
  'lb',
  'tbsp',
  'tsp',
  // 'lb',
  // 'tsp',
  // 'c',
]

class IngredientEditList extends PureComponent<Props, State> {
  static defaultProps = {
    items: [],
  }
  state = {
    open: '',
  }

  togglePane = (item_id) => {
    this.setState(prevState => ({ open: prevState.open === item_id ? '' : item_id }))
  }

  handleUpdateIngredient = (item, name, value, parseAsFloat = false) => {
    // console.log('REL.handleUpdateIngredient', item)
    // console.log('REL.handleUpdateIngredient 2', item, name, value)
    const { onUpdate } = this.props
    const ingr = {
      ...item,
      [name]: parseAsFloat ? (value * 1) : value,
    }
    onUpdate(ingr)
  }

  handleToggleFixed = (item, value) => {
    // console.log('REL.handleToggleFixed', item, value)
    const { onUpdate } = this.props
    const ingr = {
      ...item,
      fixed: value,
      foodFixed: value,
      unit: value ? 'whole' : 'oz',
    }
    onUpdate(ingr)
  }

  renderSecondaryAction = (item) => {
    const { items, onDelete, classes } = this.props
    const icon = this.state.open === item.id ? <ExpandLess /> : <ExpandMore />

    return (
      <ListItemSecondaryAction className={classes.secAction}>
        {items.length > 1 && (
          <Button size="small" variant="text" onClick={() => this.togglePane(item.id)}>
            {cloneElement(icon, { color: 'disabled', size: 'small', style: { fontSize: 32 } })}
          </Button>
        )}
        {onDelete && (
          <Button size="small" onClick={() => onDelete(item)}>
            <DeleteIcon color="disabled" size="small" />
          </Button>
        )}
      </ListItemSecondaryAction>
    )
  }

  renderItemLink = (item) => {
    const { items } = this.props
    return (
      <ListItem dense divider>
        <FormField label="Linked with Ingredient" value={item.goesWithId} select
                   InputProps={{ disableUnderline: true }}
                   onChange={(e) => this.handleUpdateIngredient(item, 'goesWithId', e.target.value)}>
          <MenuItem value="">(None)</MenuItem>
          {items.map(itemLink => {
            if (item.id === itemLink.id || itemLink.goesWithId) { return null }
            return (
              <MenuItem key={`${itemLink.id}-link`} value={itemLink.id}>
                {itemLink.name}
              </MenuItem>
            )
          })}
        </FormField>
      </ListItem>
    )
  }

  renderItem = (item, sortIindex, prefix = null) => {
    const { classes, errors } = this.props
    const error = Object.values(get(errors, sortIindex, {})).join(', ')
    // console.log('item', item, i, error)
    const units = item.foodFixed ? ['whole'] : UNITS
    const newItem = !!item.newItem
    const hasSub = item.sub && item.sub.length > 0
    const isFixed = item.foodFixed || item.fixed
    const isOpen = this.state.open === item.id

    return (
      <Fragment key={`${item.id}-item`}>
        <ListItem divider={!isOpen}>
          <ListItemText primaryTypographyProps={{ className: classes.line }}
                        secondary={error} secondaryTypographyProps={{ className: classes.error }}>
            <Grid container className={classes.gridLine}>
              <Grid item xs={11} sm={6} md={5} className={classes.gridQty}>
                <FormField value={item.qty} className={classes.qty} InputProps={{ disableUnderline: false }}
                           onChange={(e) => this.handleUpdateIngredient(item, 'qty', e.target.value)}
                           onBlur={e => this.handleUpdateIngredient(item, 'qty', asFloat(e.target.value))} />
                <FormField value={item.unit} select className={classes.unit} InputProps={{ disableUnderline: false }}
                           onChange={(e) => this.handleUpdateIngredient(item, 'unit', e.target.value)}>
                  {units.map(option => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </FormField>
                <Switch label="Fixed?"
                        checked={Boolean(isFixed)}
                        value={isFixed ? isFixed : ''}
                        disabled={item.foodFixed}
                        onChange={(e) => this.handleUpdateIngredient(item, 'fixed', e.target.checked)}
                        labelPlacement="end" />
              </Grid>
              <Grid item xs={11} sm={5} md={6} className={classes.gridLabel}>
                <FormLabel className={classes.label}>
                  {prefix}<span>{item.name}</span>
                </FormLabel>
              </Grid>
            </Grid>
          </ListItemText>
          {this.renderSecondaryAction(item)}
        </ListItem>
        {!newItem ? (
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <List key={`${sortIindex}-expanded`}>

              {this.renderItemLink(item)}

            </List>
          </Collapse>
        ) : (
          <List key={`${sortIindex}-new-expanded`}>
            <ListItem dense divider>
              <Switch label="Whole serving size?"
                      checked={item.foodFixed}
                      value={item.foodFixed}
                      onChange={(e) => this.handleToggleFixed(item, e.target.checked)}
                      labelPlacement="end" />
              <FormField value={item.fixedQty} className={classes.qty} InputProps={{ disableUnderline: true }}
                         onChange={(e) => this.handleUpdateIngredient(item, 'fixedQty', e.target.value)}
                         onBlur={e => this.handleUpdateIngredient(item, 'fixedQty', asFloat(e.target.value))} />
              <FormField value={item.fixedUnit} select className={classes.unit}
                         InputProps={{ disableUnderline: true }}
                         onChange={(e) => this.handleUpdateIngredient(item, 'fixedUnit', e.target.value)}>
                {UNITS.map(option => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </FormField>
            </ListItem>
            {this.renderItemLink(item)}
          </List>
        )}
        {hasSub && item.sub.map(itemSub => this.renderItem(itemSub, sortIindex + 100, <LinkIcon />))}
      </Fragment>
    )
  }

  renderItems = (items) => {
    return items.map((item, index) => this.renderItem(item, index))
  }

  render () {
    const { items } = this.props
    // console.log('items', items)

    const itemsGrouped = compact(items.map(ingr => {
      return ingr.goesWithId ? null : {
        ...ingr,
        sub: items.filter(ingrSub => ingrSub.goesWithId === ingr.id),
      }
    }))

    return (
      <MuiList dense>
        <ListItem divider>
        </ListItem>
        {itemsGrouped && this.renderItems(itemsGrouped)}
      </MuiList>
    )
  }
}

export default withStyles(styles)(IngredientEditList)
