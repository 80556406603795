// @flow
import React, { PureComponent } from 'react'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { Query } from 'react-apollo'
import get from 'lodash/get'
import debounce from 'lodash/debounce'

import { GET_CURRENT_USER } from '@balance/lib/api/user'
import { CurrentUserProvider } from '@balance/lib/contexts'

import { NotificationMonitor } from './Notification'
import NoMatch from './NoMatch/NoMatch'
import SignInContainer from './User/SignInContainer'
import SignOutContainer from './User/SignOutContainer'
import SignUpContainer from './User/SignUpContainer'
import SignInHelpContainer from './User/SignInHelpContainer'
import ResetPasswordContainer from './User/ResetPasswordContainer'
import ConfirmAccountContainer from './User/ConfirmAccountContainer'
import DashboardContainer from './User/DashboardContainer'
import InactiveContainer from './User/InactiveContainer'
import OnboardingContainer from './User/OnboardingContainer'
// import AccessDeniedContainer from './User/AccessDeniedContainer'
import HelpContainer from './Generic/HelpContainer'

import ProfileEditContainer from './Profile/ProfileEditContainer'
import ProfileResultsContainer from './Profile/ProfileResultsContainer'
import PaymentEditContainer from './Payment/PaymentEditContainer'
import PaymentDetailContainer from './Payment/PaymentDetailContainer'
import ProfileMealsContainer from './Profile/ProfileMealsContainer'
import MealPlanContainer from './Meals/MealPlanContainer'
import MealPrepContainer from './Meals/MealPrepContainer'
import RecipeListContainer from './Recipe/RecipeListContainer'
import RecipeDetailContainer from './Recipe/RecipeDetailContainer'
import RecipeEditContainer from './Recipe/RecipeEditContainer'
import GroceryListContainer from './Meals/GroceryListContainer'
import PageContainer from './Generic/PageContainer'

import { Footer, Header } from '../components/Global'
import { Analytics } from '../components/Tracking'

import { ErrorBoundary } from '../components/Error'
import AccessDenied from '../components/User/AccessDenied'

type Props = {}
type State = {
  lastFetchedAt: number,
  // minContainerHeight: number,
}

const currentUserStatus = {
  status: '',
  authenticated: false,
  paid: false,
  onboarded: [],
}

// const UnauthenticatedOnlyRoute = ({ component: Component, user, ...rest }) => (
//   <Route {...rest} render={(props) => (
//     currentUserStatus.authenticated === false ? <Component {...props} /> : <Redirect to="/" />
//   )} />
// )

const AuthenticatedRoute = ({ component: Component, user, ...rest }) => (
  <Route {...rest} render={(props) => (
    currentUserStatus.authenticated === true ? <Component {...props} /> : <AccessDenied />
  )} />
)

const PaidRoute = ({ component: Component, user, ...rest }) => (
  <Route {...rest} render={(props) => (
    currentUserStatus.authenticated === true && currentUserStatus.paid === true
      ? <Component {...props} />
      : <AccessDenied status={currentUserStatus.status} />
  )} />
)

class AppRouter extends PureComponent<Props, State> {
  static defaultProps = {}
  state = {
    orientation: 'portrait',
    lastFetchedAt: 0,
    // minContainerHeight: 400, // set a sane default
  }

  goto = (url) => {
    if (url) {
      this.props.history.replace(url)
    }
  }

  readDeviceOrientation = () => {
    let { orientation } = this.state
    // if (window.orientation) {
    //   console.log('window.orientation', window.orientation)
    //   if (Math.abs(window.orientation) === 90) {
    //     orientation = 'landscape'
    //   } else {
    //     orientation = 'portrait'
    //   }
    // } else {
    const vw = window.innerWidth
    const vh = window.innerHeight
    // console.log('window dimensions', vw, vh)
    if (vw > vh) {
      orientation = 'landscape'
    } else {
      orientation = 'portrait'
    }

    // }
    this.setState({ orientation }, this.forceUpdate)
    // console.log('orientation now', this.state.orientation)
  }

  updateDeviceOrientation = debounce(this.readDeviceOrientation, 100)

  componentDidMount (): void {
    this.readDeviceOrientation()
    window.addEventListener('resize', this.updateDeviceOrientation)
    window.addEventListener('onorientationchange', this.updateDeviceOrientation)
    window.addEventListener('ondeviceorientation', this.updateDeviceOrientation)
  }

  componentWillUnmount (): void {
    window.removeEventListener('resize', this.updateDeviceOrientation)
    window.removeEventListener('onorientationchange', this.updateDeviceOrientation)
    window.removeEventListener('ondeviceorientation', this.updateDeviceOrientation)
  }

  // doUpdateHeight = () => {
  //   const windowH = window.innerHeight
  //   // const fullPageRect = document.getElementById('root').getBoundingClientRect()
  //   const headerRect = document.querySelector('.app > header').getBoundingClientRect()
  //   const headerH = headerRect.height
  //   const footerRect = document.querySelector('.app > footer').getBoundingClientRect()
  //   let footerH = footerRect.height
  //   // if (footerH > 110) { // magic number
  //   //   footerH = footerH + 100
  //   // }
  //   const staticH = headerH + footerH
  //   const pageRect = document.getElementById('page').getBoundingClientRect()
  //   const containerH = pageRect.height
  //   // const minH = Math.max(containerH, windowH - staticH)
  //   const minH = windowH - staticH
  //   // console.log('heights', { windowH, minH, containerH, staticH, headerH, footerH })
  //   if (minH !== this.state.minContainerHeight && minH < containerH) {
  //     this.setState({ minContainerHeight: minH })
  //   }
  // }
  //
  // updateHeight = debounce(this.doUpdateHeight, 150)

  // componentDidMount (): void {
  //   this.updateHeight()
  //   window.addEventListener('resize', this.updateHeight)
  // }
  //
  // componentWillUnmount (): void {
  //   window.removeEventListener('resize', this.updateHeight)
  // }

  // componentDidUpdate (prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
  //   this.updateHeight()
  // }

  render () {
    // const { minContainerHeight } = this.state
    // console.log('minContainerHeight', minContainerHeight)
    const path = get(this.props, 'location.pathname')
    const isMainScreen = ['/meal-plan', '/meal-prep', '/grocery-list'].includes(path)
    // console.log('path', path)

    // const linkState = get(this.props, 'location.state', {})
    const reloadUser = get(this.props, 'location.state.reloadUser', false)
    // console.log('app router path', path, reloadUser, this.props)
    const skip = ['/logout'].indexOf(path) !== -1
    // const cachePolicy = ['/asdf', '/login'].indexOf(path) !== -1 ? 'cache-and-network' : 'cache-first'
    // console.log('cachePolicy', cachePolicy)
    const fetchPolicy = reloadUser ? 'network-only' : 'cache-first'
    // console.log('fetchPolicy', fetchPolicy)
    return (
      <Query query={GET_CURRENT_USER} fetchPolicy={fetchPolicy} skip={skip}>
        {({ data, loading, error, refetch, ...rest }) => {
          // console.log('AppRouter render prop', data, loading, error, this.state, this.props, rest)
          const user = get(data, 'currentUser')
          const loggedIn = get(user, 'loggedIn')
          const hasPlanAccess = get(user, 'hasPlanAccess')
          const onboarded = get(user, 'onboarded')

          currentUserStatus.status = get(user, 'status')
          currentUserStatus.authenticated = loggedIn
          currentUserStatus.paid = hasPlanAccess // active or trial
          currentUserStatus.onboarded = onboarded

          const doRefetch = () => {
            // console.log('current user refetch called()')
            const now = new Date()
            const gap = this.state.lastFetchedAt > 0 ? now - this.state.lastFetchedAt : 1000
            // console.log('gap', gap, this.state.lastFetchedAt)
            if (refetch && gap > 500) {
              // console.log('running doRefetch')
              this.setState({ lastFetchedAt: now }, refetch)
              // refetch()
            }
          }

          if (reloadUser) {
            const now = new Date()
            const gap = this.state.lastFetchedAt > 0 ? now - this.state.lastFetchedAt : 1000
            // console.log('gap', gap, this.state.lastFetchedAt)
            if (refetch && gap > 500) {
              // console.log('reloading user ....')
              refetch()
            }
          }

          // console.log('AppRouter.user', get(user, 'email'))
          const useNotifications = loggedIn && !!window.cordova
          // console.log('AppRouter.init', loggedIn, path)
          const userStart = (loggedIn && !user.profile) ? '/profile/demo' : '/meal-plan'
          const appClass = window.cordova ? 'app--cordova' : 'app--web'
          const showHelp = loggedIn && isMainScreen
          return (
            <NotificationMonitor key={`notification-monitor-${useNotifications}`} enabled={useNotifications}
                                 goto={this.goto}>
              <CurrentUserProvider value={{
                state: user || {},
                refetch: doRefetch,
                updateHeight: this.updateHeight,
              }}>
                <div className={`app ${appClass} is-${this.state.orientation}`} id="app"
                     data-app-orientation={this.state.orientation}>
                  <Header currentPath={this.props.location.pathname} loggedIn={loggedIn} user={user}
                          onLogin={this.login}
                          showHelp={showHelp}
                          orientation={this.state.orientation}
                          onLogout={this.logout} />

                  {loading || <OnboardingContainer currentUser={user} onboarded={currentUserStatus.onboarded} />}

                  <div id="app__page">

                    <Route path='/' component={Analytics} />

                    <ErrorBoundary>
                      {loading && (
                        <div className="">
                          <div>Loading ...</div>
                        </div>
                      )}

                      {error && (
                        <div className="">
                          <div>ERROR: {error.message}</div>
                        </div>
                      )}

                      {!loading && !error && (
                        <Switch>
                          <Route path='/account/login' component={SignInContainer} />
                          <Route path='/login' component={SignInContainer} />
                          <Route path='/logout' component={SignOutContainer} />
                          <Route path='/signup' component={SignUpContainer} />
                          <Route path='/account/help' component={SignInHelpContainer} />
                          <Route path='/account/password/edit' component={ResetPasswordContainer} />
                          <Route path='/account/reset/edit' component={ResetPasswordContainer} />
                          <Route path='/account/verification' component={ConfirmAccountContainer} />
                          <Route path='/pages/:page' component={PageContainer} />
                          <Route exact path="/" render={() => (
                            loggedIn ? (
                              <Redirect to={{ pathname: userStart, state: { reloadUser: true } }} />
                            ) : (
                              <Redirect to={{ pathname: '/login', state: { reloadUser: reloadUser } }} />
                            )
                          )} />
                          <Route exact path='/signup-complete' render={() => (
                            <Redirect to={{ pathname: '/', state: { reloadUser: true } }} />
                          )} />
                          <PaidRoute path='/meal-plan' component={MealPlanContainer} />
                          <PaidRoute path='/meal-prep' component={MealPrepContainer} />
                          <PaidRoute exact path='/recipes/new' component={RecipeEditContainer} />
                          <PaidRoute exact path='/recipes/:recipeId' component={RecipeDetailContainer} />
                          <PaidRoute path='/recipes/:recipeId/edit' component={RecipeEditContainer} />
                          <PaidRoute exact path='/recipes' component={RecipeListContainer} />
                          <PaidRoute path='/grocery-list' component={GroceryListContainer} />
                          <PaidRoute path='/dashboard' component={DashboardContainer} />
                          <AuthenticatedRoute path='/inactive' component={InactiveContainer} />
                          <AuthenticatedRoute path='/profile/results' component={ProfileResultsContainer} />
                          <AuthenticatedRoute path='/profile/targets' component={ProfileResultsContainer} />
                          <AuthenticatedRoute exact path='/profile/payment' component={PaymentDetailContainer} />
                          <AuthenticatedRoute path='/profile/payment/edit' component={PaymentEditContainer} />
                          <AuthenticatedRoute path='/profile/meals' component={ProfileMealsContainer} />
                          <AuthenticatedRoute path='/profile/:step' component={ProfileEditContainer} />
                          <AuthenticatedRoute path='/help' component={HelpContainer} />
                          }
                          <Route component={NoMatch} />
                        </Switch>
                      )}
                    </ErrorBoundary>
                  </div>
                  <Footer />
                </div>
              </CurrentUserProvider>
            </NotificationMonitor>
          )
        }}
      </Query>
    )
  }
}

export default withRouter(AppRouter)
