import React, { Component, Fragment } from 'react'
import { Query } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'

import { CurrentUserConsumer } from '@balance/lib/contexts'
import { GET_RECIPES } from '@balance/lib/api/recipes'

import { RecipeList } from '../../components/Recipe'
// import { FullPageSpinner } from '../../components/Global'

class RecipeListContainer extends Component {
  state = {
    error: '',
    page: null,
    perPage: null,
    filterStatus: [],
    filterList: [],
    searchText: '',
  }

  componentDidMount (): void {
    const page = sessionStorage.getItem('recipe/page')
    const perPage = sessionStorage.getItem('recipe/perPage')
    const filterStateRaw = sessionStorage.getItem('recipe/filterState')
    const filterState = filterStateRaw !== null ? JSON.parse(filterStateRaw) : {}
    // console.log('cDM', page, perPage, filterState)
    let params = { page: 0, perPage: 10, filterType: [], filterStatus: [] } // some defaults
    // TODO this doesn't seem to work since MUI_DT immediately resets to 0
    if (page !== null) { params.page = parseInt(page, 10) }
    if (perPage !== null) { params.perPage = parseInt(perPage, 10) }
    if (filterState !== null) {
      params = {
        ...params,
        ...filterState,
      }
    }
    // console.log('rehydrated', params)
    this.setState(params)
  }

  viewRecipe = (recipeId) => {
    const { history } = this.props
    history.push(`/recipes/${recipeId}`)
  }

  onFilterChange = (changedColumn: String, filterList: Array) => {
    // console.log('onFilterChange', changedColumn, filterList)
    const filterState = {
      filterStatus: filterList[1],
      filterType: filterList[2],
    }
    sessionStorage.setItem('recipe/filterState', JSON.stringify(filterState))
    this.setState(filterState)
  }

  onSearchChange = (searchText) => {
    // console.log('onSearchChange', searchText)
    this.setState({
      searchText,
    })
  }

  onChangePage = (page) => {
    // console.log('onChangePage', page)
    if (page !== this.state.page) {
      this.setState({ page })
      sessionStorage.setItem('recipe/page', page)
    }
  }

  onChangeRowsPerPage = (perPage) => {
    // console.log('onChangeRowsPerPage', perPage)
    if (perPage !== this.state.perPage) {
      this.setState({ perPage })
      sessionStorage.setItem('recipe/perPage', perPage)
    }
  }

  render () {
    // console.log('recipe list container.render', this.state)
    const { page, perPage, searchText, filterType, filterStatus } = this.state

    const notReady = page === null || perPage === null

    return (
      <CurrentUserConsumer>
        {({ state }) => {
          // console.log('cu', state)
          return (
            <Query query={GET_RECIPES}
                   variables={{
                     page,
                     perPage,
                     filter: {
                       q: searchText,
                       status: filterStatus,
                       type: filterType,
                     },
                   }}
                   notifyOnNetworkStatusChange
                   skip={notReady}
            >
              {({ data, loading, props }) => {
                // console.log('recipe list container props', page, perPage, loading, data, props)

                const recipes = get(data, 'items', [])
                const totalResultCount = get(data, 'total.count', 0)

                if (notReady) {
                  return null
                }

                return (
                  <Fragment>
                    <RecipeList recipes={recipes}
                                totalResultCount={totalResultCount}
                                loading={loading}
                                user={state}
                                page={page}
                                perPage={perPage}
                                searchText={this.state.searchText}
                                filterStatus={filterStatus}
                                filterType={filterType}
                                viewRecipe={this.viewRecipe}
                                onSearchChange={this.onSearchChange}
                                onFilterChange={this.onFilterChange}
                                onChangePage={this.onChangePage}
                                onChangeRowsPerPage={this.onChangeRowsPerPage}
                    />
                    {/*<FullPageSpinner open={loading} hideBackdrop />*/}
                  </Fragment>
                )
              }}
            </Query>
          )
        }}
      </CurrentUserConsumer>
    )
  }
}

export default withRouter(RecipeListContainer)
