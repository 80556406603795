// @flow
import React, { Component } from 'react'

type Props = {
  message?: string,
}

type State = {
  hasError: boolean,
}

export class ErrorBoundary extends Component<Props, State> {
  static defaultProps = {
    message: 'Something went wrong.',
  }

  constructor (props) {
    super(props)
    this.state = {hasError: false}
  }

  componentDidCatch (error, info) {
    // Display fallback UI
//console.log('componentDidCatch', error, info)
    this.setState({hasError: true})
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info)
  }

  render () {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>{this.props.message}</h1>
    }
    return this.props.children
  }
}

export default ErrorBoundary
