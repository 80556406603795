// @flow
import get from 'lodash/get'

export const MONTHLY_FEE_PRODUCT_ID = 'com.balanceondemand.app.monthly_fee'

export function isIos (platform: ?String): boolean {
  if (!window.cordova) { return false }
  const platformTocheck = platform || get(window, 'device.platform')
  console.log('isIos?', platformTocheck)
  return ['iOS', 'iPadOS'].includes(platformTocheck)
}

export function isAndroid (platform: ?String): boolean {
  if (!window.cordova) { return false }
  const platformTocheck = platform || get(window, 'device.platform')
  console.log('isAndroid?', platformTocheck)
  return ['amazon-fireos', 'Android'].includes(platformTocheck)
}

export function isAvailable (): boolean {
  if (!window.cordova) { return false }
  return isIos() || isAndroid()
}

export function nativePaymentLabel (platform: ?String): string {
  if (isIos(platform)) {
    return 'iOS App Store'
  } else if (isAndroid(platform)) {
    return 'Google Play Store'
  }
  return 'Mobile Payment'
}

export function getProducts () {
  return new Promise((resolve, reject) => {
    // console.log('fetching products from store')
    // try {
    window.inAppPurchase
      .getProducts([MONTHLY_FEE_PRODUCT_ID])
      .then(function (products) {
        // console.log(products)
        console.table(products)
        resolve(products)
      })
      .catch(function (err) {
        console.log('Error getting products', err)
        // console.log(err)
        reject(err)
      })
    // } catch (err) {
    //   console.log("Error getting products", err)
    //   reject(err)
    // }
  })
}

export function getMainProduct () {
  return new Promise((resolve, reject) => {
    // console.log('fetching main product from store')
    window.inAppPurchase
      .getProducts([MONTHLY_FEE_PRODUCT_ID])
      .then(function (products) {
        // console.log(products)
        console.table(products)
        resolve(products.find(p => p.productId === MONTHLY_FEE_PRODUCT_ID))
      })
      .catch(function (err) {
        console.log('Error getting main product', err)
        reject(err)
      })
  })
}

export function subscribe (productId = MONTHLY_FEE_PRODUCT_ID) {
  return new Promise((resolve, reject) => {
    // console.log('subscribing to product', productId)
    window.inAppPurchase
      .subscribe(productId)
      .then(function (data) {
        // console.log(data)
        resolve(data)
      })
      .catch(function (err) {
        console.log(err)
        reject(err)
      })
  })
}

export function restorePurchases () {
  const productId = MONTHLY_FEE_PRODUCT_ID
  return new Promise((resolve, reject) => {
    // console.log('restoring purchases')
    window.inAppPurchase
      .restorePurchases()
      .then(function (data) {
        console.log(data)
        for (let i = 0; i < data.length; ++i) {
          // TODO: check data[i].state for cancelled or refunded
          if (data[i].productId === productId) {
            // console.log('Purchase found! Do something...', data)
            resolve(data)
            return
          }
        }
        resolve(null)
      })
      .catch(function (err) {
        console.log(err)
        reject(err)
      })
  })
}

export function getReceipt () {
  return new Promise((resolve, reject) => {
    // console.log('getting app store subscription receipt')
    window.inAppPurchase
      .getReceipt()
      .then(function (receipt) {
        // console.log('encoded receipt', receipt)
        return receipt
      })
      .then(function (receiptData) {
        // console.log('receipt data', receiptData)
        resolve(receiptData)
      })
      .catch(function (err) {
        console.log(err)
        reject(err)
      })
  })
}

export function validateReceipt (receiptData) {

  return new Promise((resolve, reject) => {

  })
}
