import { colors } from '@balance/lib/styles'
import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    background: {
      main: '#fff',
    },
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    error: {
      main: colors.danger,
    },
    warning: {
      main: colors.warning,
    }
  },
  colors: colors,
  content: {
    padding: 12,
    color: '#444',
  },
  typography: {
    fontFamily: [
      '"Open Sans"',
      // '"Helvetica Neue"',
      'Helvetica',
      // 'Arial',
      'sans-serif',
    ],
    useNextVariants: true,
  },
})

export default theme
