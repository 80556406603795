// @flow
import React, { PureComponent } from 'react'
import { Mutation } from 'react-apollo'
import get from 'lodash/get'
import { SAVE_DEVICE } from '@balance/lib/api/user'

import { NotificationProvider } from './NotificationContext'
import { getMessaging, getToken, init, requestPermission } from '../../lib/notifications'

import { info } from '../../lib/device'

type Props = {}
type State = {
  enabled: boolean, // user has granted permission
  message: object,
  fcmToken: ?string,
  notification: object,
  hasPermission: boolean,
  saveDevice: Function,
  goto: Function,
}

const ENABLED = true

class NotificationMonitor extends PureComponent<Props, State> {
  static defaultProps = {}

  constructor (props: Props) {
    super(props)

    this.seen = {}

    console.log('DataMessageMonitor()')
    this.state = {
      enabled: false,
      message: {},
      notification: {},
    }
  }

  async componentDidMount () {
    // console.log('DataMessageMonitor.cDM')
    if (!ENABLED) { return }
    // console.log('DataMessageMonitor.cDM setting up')

    init()

    let token = await requestPermission()
    if (token) {
      // console.log('user has a token!', token)
      await this.saveToken(token, false)
      this.setupListeners()
      this.setState({ fcmToken: token, hasPermission: true })
    } else {
      console.log('user does not yet have a token or has not provided permission!')
      // user doesn't have a device token yet
    }

    getMessaging().setBadge(0) // always set to 0 when app is opened
  }

  componentWillUnmount () {
    this.messageListener && this.messageListener() // yes, this is weird but it's an unsubscribe function
    this.notificationDisplayedListener && this.notificationDisplayedListener()
    this.notificationListener && this.notificationListener()
    this.notificationOpenedListener && this.notificationOpenedListener()
    this.onTokenRefreshListener && this.onTokenRefreshListener()
  }

  process = (message) => {
    // console.log('received message', JSON.stringify(message))
    const mid = message.messageId || message.notificationId
    if (mid && !this.seen[mid]) {
      this.seen[mid] = true

      // console.log('processing message', message)

      // const action = get(message, 'data.action')
      // if (action) {
      //   console.log('got an action!', action)
      //   this.setState({ message })
      // }
      // const body = message.message || ''
      // const notificationOut = new firebase.notifications.Notification().setNotificationId(mid).
      //   setTitle('Notice!').
      //   setBody(body).
      //   android.setChannelId(CHANNEL).
      //   android.setSmallIcon('queful').
      //   setData(message.data)
      // console.log('message out', notificationOut)
      // if (body) {
      //   firebase.notifications().displayNotification(notificationOut)
      // }
    }
  }

  show = (notification) => {
    const mid = notification.messageId || notification.notificationId
    if (mid && !this.seen[mid]) {
      this.seen[mid] = true

      // console.log('showing notification', notification)
      //
      // const notificationOut = new firebase.notifications.Notification().setNotificationId(mid).
      //   setTitle('Alert!').
      //   setBody('My notification body').
      //   setData(notification.data)
      // console.log('notificationOut', notificationOut)
      // firebase.notifications().displayNotification(notificationOut)
    }
  }

  saveToken = async (token, replace: boolean = false) => {
    // console.log('saving token to server', token)
    if (token) {
      const currentToken = this.state.fcmToken
      await this.props.saveDevice({ variables: { attributes: { ...info(), token, currentToken, replace } } })
    }
  }

  setupListeners = async () => {
    const messaging = getMessaging()
    if (!messaging) { return }

    messaging.onMessage((payload) => {
      // console.log('New foreground FCM message: ', payload, JSON.stringify(payload))

      let notification = {
        ...payload,
        messageId: get(payload, 'gcm.message_id'),
      }
      messaging.setBadge(0)
      this.process(notification)
    })

    messaging.onBackgroundMessage((payload) => {
      // console.log('New background FCM message: ', payload, JSON.stringify(payload))

      let notification = {
        ...payload,
        messageId: get(payload, 'gcm.message_id'),
      }
      messaging.setBadge(1)
      this.process(notification)

      if (notification.goto) {
        this.props.goto(notification.goto)
      }
    })

    messaging.onTokenRefresh(() => {
      // console.log('Device token updated')
      let token = getToken()
      this.saveToken(token, true)
    })

//     const initialOpenNotification: initialOpenNotification = await firebase.notifications().getInitialNotification()
//     if (initialOpenNotification) {
//       console.log('app was opened via notification from firebase!', initialOpenNotification)
//       // App was opened by a notification
//       // Get the action triggered by the notification being opened
//       const action = initialOpenNotification.action
//       // Get information about the notification that was opened
//       const notification: Notification = initialOpenNotification.notification
//       this.process(notification)
//     }
//
//     this.messageListener = firebase.messaging().onMessage((message: RemoteMessage) => {
//       // Process your message as required
//       console.log('got a message from firebase!', message)
//       this.process(message)
//     })
//
//     this.notificationDisplayedListener = firebase.notifications().
//       onNotificationDisplayed((notification: Notification) => {
//         // Process your notification as required
//         // ANDROID: Remote notifications do not contain the channel ID. You will have to specify this manually if you'd like to re-display the notification.
//         console.log('got a notificationDisplayed from firebase!', notification)
//         this.process(notification)
//       })
//
//     this.notificationListener = firebase.notifications().onNotification((notification: Notification) => {
//       // Process your notification as required
//       console.log('got a notification from firebase!', notification)
//       this.process(notification)
// //      this.show(notification)
//     })
//
//     this.notificationOpenedListener = firebase.notifications().
//       onNotificationOpened((notificationOpen: NotificationOpen) => {
//         console.log('notification opened from firebase!', notificationOpen)
//         // Get the action triggered by the notification being opened
//         const action = notificationOpen.action
//         // Get information about the notification that was opened
//         const notification: Notification = notificationOpen.notification
//         this.process(notification)
//       })
//
//     this.onTokenRefreshListener = firebase.messaging().onTokenRefresh(fcmToken => {
//       // Process your token as required
//       console.log('got a new token?', fcmToken)
//       this.saveToken(fcmToken, true)
//     })

  }

  render () {
    // console.log('NotificationMonitor.render', this.state)
    return (
      <NotificationProvider value={this.state}>
        {this.props.children}
      </NotificationProvider>
    )
  }
}

const DisabledNotificationMonitor = ({ children }) => (children)

const WrappedNotificationMonitor = ({ enabled, children, ...rest }) => {
  // console.log('WrappedNotificationMonitor', enabled)
  if (!enabled) {
    // console.log('WrappedNotificationMonitor skipping', enabled)
    return <DisabledNotificationMonitor children={children} />
  }

  // console.log('WrappedNotificationMonitor enabling....', enabled)
  return (
    <Mutation mutation={SAVE_DEVICE}>
      {(saveDevice, { data: saveDeviceData, loading, error }) => {
        // console.log('wrapped notification props', { enabled, saveDevice, saveDeviceData })
        return (
          <NotificationMonitor {...rest} saveDevice={saveDevice} children={children} />
        )
      }}
    </Mutation>
  )
}

export default ENABLED ? WrappedNotificationMonitor : DisabledNotificationMonitor

