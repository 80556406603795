import React, { Component } from 'react'
import { Query, Mutation } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'

import { CurrentUserConsumer } from '@balance/lib/contexts'
import { GET_RECIPE, DELETE_RECIPE } from '@balance/lib/api/recipes'

import { RecipeDetail } from '../../components/Recipe'

class RecipeDetailContainer extends Component {

  constructor (props, ctx) {
    super(props, ctx)

    this.state = {
      error: '',
    }
  }

  render () {
    // console.log('recipe detail container render', this.props)
    const recipeId = get(this.props, 'match.params.recipeId')
    return (
      <CurrentUserConsumer>
        {({state}) => {
          // console.log('cu', state)
          return (
            <Query query={GET_RECIPE} variables={{id: recipeId}} skip={!recipeId}>
              {({data, ...props}) => {
                // console.log('recipe detail container props', data, props)

                const recipe = get(data, 'data', {})

                return (
                  <Mutation mutation={DELETE_RECIPE}>
                    {(deleteRecipe) => {

                      const doDelete = async () => {
                        const result = await deleteRecipe({variables: {id: recipe.id}})
                        // console.log('result', result)
                        const idConfirm = get(result, 'data.data.id')
                        // console.log('idConfirm', idConfirm)
                        if (idConfirm) {
                          this.props.history.replace('/recipes')
                        }
                      }
                      return (
                        <RecipeDetail recipe={recipe} user={state} deleteRecipe={doDelete} />
                      )
                    }}
                  </Mutation>
                )
              }}
            </Query>
          )
        }}
      </CurrentUserConsumer>
    )
  }
}

export default withRouter(RecipeDetailContainer)
