import { colors } from '.'

import printStyles from './print'

export const globalStyles = theme => ({
  ...printStyles(theme),
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    padding: 20,
    margin: '0 auto',
    maxWidth: 1280,
    position: 'relative',
  },
  wrapper: {
    padding: 20,
    margin: '0 auto',
    maxWidth: 1280,
    position: 'relative',
  },
  error: {
    color: colors.danger,
  },
  section: {
    padding: 20,
  },
  icon: {
    // border: '1px solid red',
  },
})

export default globalStyles
